import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    userId : null
}

const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    SET_ID_USER : (state,action) =>{

        // console.log(action.payload)

        const { userId} = action.payload
        
        state.userId = userId
    },
   
  }
});

export const {SET_ID_USER} = authSlice.actions
export const selectUserID =  (state) =>state.user.userId

export default authSlice.reducer