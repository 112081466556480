import { createSlice } from "@reduxjs/toolkit";
import { deleteCookie } from "../../helpers";

const userSlice = createSlice({
    name: 'user',
    initialState: {
        info: {}
    },
    reducers: {
        setUser: (state, action) => {
            state.info = action.payload
        },
        logOut: (state, action) => {
            state.info = {}
            deleteCookie('userInfo')
        }
    }
})

export const { setUser, logOut } = userSlice.actions

export const getUserInfo = state => state.user.info

export default userSlice.reducer