import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';


export default function TableTypeOR(props) {
    const [products, setProducts] = useState([
        {
        code : '1',
        cat : 'vin'
    },
        {
        code : '2',
        cat : 'whisky'
    },
        {
        code : '3',
        cat : 'eau'
    }
]);
    const [globalFilter, setGlobalFilter] = useState('');
    const dt = useRef(null);

    const cols = [
        { field: 'id_type_ob', header: ' N° de suivi', sortable: true },
        { field: 'lib_type_ob', header: 'Types', sortable: true },
        { field: 'actions', header: 'Actions', sortable: false } // Nouvelle colonne pour les actions
    ];

    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

    useEffect(() => {
        // const updatedProducts = props.typeObligations.map((product) => ({
        //     ...product,
        //     actions: true // Mettez ici la logique pour les actions
        // }));
        // props.typeObligations = updatedProducts
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

    const exportPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);

                doc.autoTable(exportColumns, props.typeObligations);
                doc.save('type_obligation.pdf');
            });
        });
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(props.typeObligations);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'type_obligation');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const header = (
        <div className="container_header_data_table">
            <div className="flex align-items-center">
                
                <div className="p-input-icon-left ml-3">
                    <i className="pi pi-search" />
                    <InputText value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Search" />
                </div>
            </div>
            <div className='container_export'>
                <Button type="button" icon="pi pi-file-pdf" rounded onClick={exportPdf} className="" data-pr-tooltip="PDF" style={{borderRadius : 30}} severity="secondary" size="small"/>
                <Button type="button" icon="pi pi-file-excel" className=""severity="success" rounded onClick={exportExcel} data-pr-tooltip="Excel" style={{borderRadius : 30}} size="small"/>
                <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} className="" data-pr-tooltip="CSV" style={{borderRadius : 30}} size="small"/>
            </div>
        </div>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center">
                <a onClick={() => handleEdit(rowData)} class="badge bg-success p-2 mr-1" style={{borderRadius : 30}}><i class="ri-pencil-line  ri-2x"></i><span></span></a> 

<a onClick={() => handleDelete(rowData)} class="badge bg-danger p-2" style={{borderRadius : 30}}><i class="ri-delete-bin-6-line  ri-2x"></i><span></span></a> 
               
            </div>
        );
    };

    const handleEdit = (rowData) => {
      
        props.setForm({
            id_type_ob :rowData.id_type_ob,
            lib_type_ob: rowData.lib_type_ob,
        })
    };

    const handleDelete = (rowData) => {
        
        
        props.deleteTypeObligation(
             rowData.id_type_ob,
           
        )
    };

    const rowClassName = (rowData, index) => {
        return index !== props.typeObligations.length - 1 ? 'table-row' : ''; // Applique la classe 'table-row' à toutes les lignes sauf la dernière
    };

    return (
        <div className="card" style={{width: '100%'}}>
            <Tooltip target=".export-buttons>button" position="bottom" />
            <style>{`
            .table-row td {
                border-bottom: 1px solid #dee2e6; // Bordure inférieure pour les cellules de la ligne
            }
        `}</style>

            <DataTable
                ref={dt}
                value={props.typeObligations}
                header={header}
                tableStyle={{ minWidth: '100%', fontSize : 12 }}
                globalFilter={globalFilter}
                emptyMessage="Pas de résultat."
                paginator
                rows={10}
                rowClassName={rowClassName} 
            >
                {cols.map((col, index) => (
                    <Column key={index} field={col.field} header={col.header} sortable={col.sortable}
                        body={col.field === 'actions' ? actionBodyTemplate : null} />
                ))}
            </DataTable>

        </div>
    );
}
