import React,  { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import Spinner from "react-bootstrap/Spinner";
import { Button } from 'primereact/button';
import { Button as Btn , Form, Table, Modal , Badge } from "react-bootstrap";
import '../styleObligation.css'
import { useCreateOblgOperateurMutation } from '../../../redux/slice/oblgOperateurApiSlice';
import Swal from 'sweetalert2';
import { format } from 'date-fns';
import 'jspdf-autotable';



export default function TableAffectation(props) {

  const { obligations, typeOperateur, operateur } = props
  const [createData, { isLoading: loadCreate }] = useCreateOblgOperateurMutation()

  const [globalFilter, setGlobalFilter] = useState([]);
  const [pageObligation, setPageObligation] = useState([]);
  const [globaleObligation, setGlobaleObligation] = useState([]);
  const [idOperateur, setIdOperateur] = useState();
  const [globalFilterOB, setGlobalFilterOB] = useState('');
  const [pageOperateur, setPageOperateur] = useState('');

  // console.log('pageObligation', pageObligation)
  const save = async (data) => {
console.log("data", data)
    try {


      await createData(data).unwrap()
      Swal.fire({
        title: "SUCCES!",
        text: "Action réussie",
        icon: "success"
      });

      setPageObligation(globaleObligation)
      setGlobalFilterOB("")
      setIdOperateur("")
      props.refetchTypeOp()
      props.refetchOp()

    } catch (error) {

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "une erreur s'est produite veuillez réessayer",
        // text: error.data.errors[0].message,
      });
    }
  }



  const handleEdit = (id, value) => {

    const result = globalFilterOB.map((item) => {
      if (item.id_obligation_reg == id) {
        return { ...item, dat_vig: value }
      } else {
        return item
      }
    })

    setPageObligation(result)
    setGlobalFilterOB(result)
  };



  const chargeTypeCat = (id) => {

if (id != "") {

  const obligationChoisi = globaleObligation.filter((item) => (item.id_type_op == id))
    // setGlobalFilterOB(obligationChoisi)
    setPageObligation(obligationChoisi)

    const operateurChoisi = operateur.filter((item) => (item.id_type_op == id))

    setPageOperateur(operateurChoisi)
} else {
  
  setPageObligation(globalFilterOB)
  setPageOperateur([])
}
    

  }

  const checkOperateur = (idOp) => {

    setIdOperateur(idOp)

  }

  const valideAction = (data, id) => {

    const controle = data.filter((item) => (item.dat_vig != ""))

    if (controle.length == 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Choisissez une date de démarrage",

      });
      return
    }
    if (id == undefined) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Choisissez un opérateur",

      });
      return
    }



    const objetFil = data.filter((item) => (item.dat_vig != ""))

    const objetSend = objetFil.map((item) => {
      return {
        date_vigueur: item.dat_vig,
        id_obligation_reg: item.id_obligation_reg,
      }
    })

    const finalSend = {
      list_obligation: objetSend,
      id_operateur: id
    }


    save(finalSend)

  }


  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    return format(dateObject, 'yyyy-MM-dd');
  };


  useEffect(() => {

    const objetObjet = obligations.map((item) => {

      return {
        id_obligation_reg: item?.id_obligation_reg,
        lib_obligation_reg: item?.lib_obligation_reg,
        id_type_op: item?.id_type_op,
        dat_vig: "",
      }
    })

    setPageObligation(objetObjet)
    setGlobaleObligation(objetObjet)
    setGlobalFilterOB(objetObjet)

  }, [obligations]);

    const dt = useRef(null);
 
    const cols = [
        { field: 'id_obligation_reg', header: ' N° de suivi', sortable: true },

        { field: 'lib_obligation_reg', header: "Libellé de l'obligation", sortable: true },
        { field: 'actions', header: "Date d'entrée en vigeur", sortable: false },
    ];
    const colsExpo = [
        { field: 'id_obligation_reg', header: ' N° de suivi', sortable: true },

        { field: 'lib_obligation_reg', header: "Libellé de l'obligation", sortable: true },
    ];

    const exportColumns = colsExpo.map((col) => ({ title: col.header, dataKey: col.field }));

   
    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

    const exportPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);

                doc.autoTable(exportColumns, pageObligation);
                doc.save('Affectation OB.pdf');
            });
        });
    };

    

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(pageObligation);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'Affectation OB');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const header = (
        <div className="container_header_data_table">
            <div className="flex align-items-center">
                
                <div className="p-input-icon-left ml-3">
                    <i className="pi pi-search" />
                    <InputText value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher" />
                </div>
            </div>
            <div className='container_export'>
                <Button type="button" icon="pi pi-file-pdf" rounded onClick={exportPdf} className="" data-pr-tooltip="PDF" style={{borderRadius : 30}} severity="secondary" size="small"/>
                <Button type="button" icon="pi pi-file-excel" className=""severity="success" rounded onClick={exportExcel} data-pr-tooltip="Excel" style={{borderRadius : 30}} size="small"/>
                <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} className="" data-pr-tooltip="CSV" style={{borderRadius : 30}} size="small"/>
            </div>
        </div>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center">
        <Form.Control
                        type="date"
                        value={rowData.dat_vig !== "" ? formatDate(rowData.dat_vig) : ""}
                        onChange={(v) => handleEdit(rowData.id_obligation_reg, v.target.value)}
                      
                      />
  
               
            </div>
        );
    };

    const rowClassName = (rowData, index) => {
        return index !== pageObligation.length - 1 ? 'table-row' : ''; // Applique la classe 'table-row' à toutes les lignes sauf la dernière
    };
   
   

  return(
        
        <div className="card" style={{width: '100%'}}>
            <Tooltip target=".export-buttons>button" position="bottom" />
            <style>{`
            .table-row td {
                border-bottom: 1px solid #dee2e6; // Bordure inférieure pour les cellules de la ligne
            }
        `}</style>

<div className="container row">
    
           
        <div className="col">
          <Form.Label>Type d'opérateur :</Form.Label>
          <Form.Select aria-label="Default select example"
            onChange={(v) => chargeTypeCat(v.target.value)}
          >
            <option value="">Choisir Type opérateur</option>
            {
              typeOperateur.map((item) => (
                <option value={item.id_type_op}>{item.lib_type_op}</option>
              ))
            }
          </Form.Select>
        </div>
        <div className="col">
          <Form.Label >Opérateur :</Form.Label>


          <Form.Select aria-label="Default select example"
            onChange={(v) => checkOperateur(v.target.value)}

          >
            <option value="">Choisir opérateur</option>
            {pageOperateur.length > 0 &&
              pageOperateur.map((item) => (
                <option value={item.id_operateur
                }>{item.nom_operateur
                  }</option>
              ))
            }

          </Form.Select>
        </div>
        <div className="col">
              


              </div>

      </div>

            <DataTable
                ref={dt}
                value={pageObligation}
                header={header}
                tableStyle={{ minWidth: '100%', fontSize : 12 }}
                globalFilter={globalFilter}
                emptyMessage="Pas de résultat."
                paginator
                rows={10}
                rowClassName={rowClassName} 
            >
                {cols.map((col, index) => (
                    <Column key={index} field={col.field} header={col.header} sortable={col.sortable}
                        body={
                          
                          col.field === 'actions' ? actionBodyTemplate : null
                      
                      } 
                        
                        />
                ))}
            </DataTable>

            <div className="container row">
        <div className="col">
          <Button variant="success" onClick={() => valideAction(pageObligation, idOperateur)}>
            Sauvegarder
          </Button>
        </div>
      </div>
        </div>
    )

    ;
}
