import React, { Fragment, useEffect, useState } from 'react'

import { Button as Btn, Form } from "react-bootstrap";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import Swal from "sweetalert2";
import './styleNumerotation.css'

import Footer from '../../layouts/Footer'
import Header from '../../layouts/Header'
import Sidebar from '../../layouts/Sidebar'
import { useCreateTrancheMutation, useDeleteTrancheMutation, useGetTrancheQuery, useUpdateTrancheMutation } from '../../redux/apiSlice/numerotationApiSlice';


const Tranche = () => {

    const {data, isLoading} = useGetTrancheQuery()
    const [createTranche] = useCreateTrancheMutation()
    const [updateTranche] = useUpdateTrancheMutation()
    const [deleteTranche] = useDeleteTrancheMutation()

    const fileName = 'tranches'
    const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : ''
    const cols = [
        { field: 'num', header: 'N°', sortable: true },
        { field: 'lib_tranche', header: 'Tranche', sortable: true },
        { field: 'desc_tranche', header: 'Commentaire ', sortable: true },
        { field: 'actions', header: 'Actions', sortable: false }
    ];
    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));
    const initForm = {lib_tranche: '', desc_tranche: ''}

    const [skin, setSkin] = useState(currentSkin)
    const [search, setSearch] = useState('')
    const [form, setForm] = useState({...initForm})

    const switchSkin = (skin) => {
        if (skin === 'dark') {
          const btnWhite = document.getElementsByClassName('btn-white');
    
          for (const btn of btnWhite) {
            btn.classList.add('btn-outline-primary');
            btn.classList.remove('btn-white');
          }
        } else {
          const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');
    
          for (const btn of btnOutlinePrimary) {
            btn.classList.remove('btn-outline-primary');
            btn.classList.add('btn-white');
          }
        }
    }

    switchSkin(skin);

    useEffect(() => {
        switchSkin(skin);
    }, [skin]);

    const disabled = form.lib_tranche === ''

    const reset = () => setForm({...initForm})
 
    const save = async () => {
        Swal.fire({
            title: "Gestion des tranches",
            text: "Voulez-vous continuer ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Oui",
            cancelButtonText: "Non"
        }).then(async (result) => {
      
            if (parseInt(form.lib_tranche) < 0   ) {
             
                Swal.fire({
                    title: "Attention!",
                    text: `La tranche doit est positif`,
                    icon: "error"
                });
            } else if(parseInt(form.lib_tranche) >= 10) {
                
                Swal.fire({
                    title: "Attention!",
                    text: `La tranche doit être et inférieur à 10`,
                    icon: "error"
                }); 
                    
            }else{
                try {
                    if (result.isConfirmed === true) {
                        if (form?.id_tranche) {
                            await updateTranche(form).unwrap()
                        } else {
                            await createTranche(form).unwrap()
                        }
            
                        Swal.fire({
                            title: "Félicitations!",
                            text: `Tranche ${form?.id_tranche ? 'modifiée': 'crée'}`,
                            icon: "success"
                        });
                
                        reset()
                    }
                } catch (error) {
                  
                    console.log('error', error.data.name)
                  if(error.data.name =="SequelizeUniqueConstraintError"){
    Swal.fire({
                        title: "Attention!",
                        text: `Cette tranche existe déjà`,
                        icon: "warning"
                    });
                }else{
                    Swal.fire({
                        title: "Attention!",
                        text: `Une erreur s'est produite ! Veuillez réessayer`,
                        icon: "error"
                    });
                }
                  }
            }

           
           
        });
    }

    const deleteData = value => {
        Swal.fire({
            title: "Gestion des tranches",
            text: "Voulez-vous vraiment supprimer la tranche ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Oui",
            cancelButtonText: "Non"
        }).then(async (result) => {
            if (result.isConfirmed === true) {
                await deleteTranche(value.id_tranche).unwrap()
    
                Swal.fire({
                    title: "Félicitations!",
                    text: `Tranche supprimée`,
                    icon: "success"
                });
        
                reset()
            }
        });
    }

    const exportCSV = (selectionOnly) => {
        // dt.current.exportCSV({ selectionOnly });
    };

    const exportPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);

                doc.autoTable(exportColumns, data);
                doc.save(fileName+'.pdf');
            });
        });
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(data);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, fileName);
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const rowClassName = (rowData, index) => {
        return index !== data.length - 1 ? 'table-row' : ''; // Applique la classe 'table-row' à toutes les lignes sauf la dernière
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center">
                <Btn onClick={() => setForm(rowData)} className="badge bg-success p-2 mr-1" style={{ borderRadius: 30 }}><i className="ri-pencil-line  ri-2x"></i><span></span></Btn>

                <Btn onClick={() => deleteData(rowData)} className="badge bg-danger p-2" style={{ borderRadius: 30 }}><i className="ri-delete-bin-6-line  ri-2x"></i><span></span></Btn>
            </div>
        );
    };

    const Head = () => (
        <div className="container_header_data_table">
            <div className="flex align-items-center">

                <div className="p-input-icon-left ml-3">
                    <i className="pi pi-search" />
                    <InputText value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Recherche ..." />
                </div>
            </div>
            <div className='container_export'>
                <Button type="button" icon="pi pi-file-pdf" rounded onClick={exportPdf} className="" data-pr-tooltip="PDF" style={{ borderRadius: 30 }} severity="secondary" size="small" />
                <Button type="button" icon="pi pi-file-excel" className="" severity="success" rounded onClick={exportExcel} data-pr-tooltip="Excel" style={{ borderRadius: 30 }} size="small" />
                <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} className="" data-pr-tooltip="CSV" style={{ borderRadius: 30 }} size="small" />
            </div>
        </div>
    );

    return (
        <Fragment>
            <Sidebar />
            <Header onSkin={setSkin} />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <h4 className="main-title mb-0"> Gestion des tranches de numérotation </h4>
                    </div>
                </div>

                <div className="container_liste_form">
                    <div className="first_container">
                        <span className="titre_cat">Liste des tranches</span>
                        <hr style={{ width: '100%' }} />

                        <div className="card" style={{ width: '100%' }}>
                            <Tooltip target=".export-buttons>button" position="bottom" />
                            <style>{`.table-row td {border-bottom: 1px solid #dee2e6;}`}</style>

                            <DataTable
                                value={data}
                                header={<Head />}
                                tableStyle={{ minWidth: '100%', fontSize: 12 }}
                                globalFilter={search}
                                globalFilterFields={['lib_tranche', 'desc_tranche']}
                                emptyMessage="Pas de résultat."
                                paginator
                                rows={10}
                                rowClassName={rowClassName}
                                loading={isLoading}
                            >
                                {cols.map((col, index) => (
                                    <Column key={index} field={col.field} header={col.header} sortable={col.sortable} body={col.field === 'actions' ? actionBodyTemplate : (col.field === 'num' ? (data, options) => options.rowIndex + 1 : null)} />
                                ))}
                            </DataTable>

                        </div>
                    </div>

                    <div className="second_container">
                        <span className="titre_cat">Nouvelle tranche</span>
                        <hr style={{ width: '100%' }} />
                        <div className="container_form">
                            <div className="container_border">
                                <div className="sous_container_form mb-3">
                                    <div className="container_label">
                                        <Form.Label htmlFor="lib">Tranche</Form.Label>
                                    </div>
                                    <div style={{ width: '70%' }}>
                                        <Form.Control
                                            type="number"
                                            id="lib"
                                            value={form.lib_tranche}
                                            onChange={e => setForm({...form, lib_tranche: e.target.value})}
                                        />
                                    </div>
                                </div>

                                <div className="sous_container_form">
                                    <div className="container_label">
                                        <Form.Label htmlFor="desc">Commentaire </Form.Label>
                                    </div>
                                    <div style={{ width: '70%' }}>
                                        <Form.Control
                                            as="textarea" 
                                            rows={3}
                                            id="desc"
                                            value={form.desc_tranche}
                                            onChange={e => setForm({...form, desc_tranche: e.target.value})}
                                        />
                                    </div>
                                </div>

                                <div className="container_btn">
                                    <Btn variant="primary" onClick={() => reset()}>Annuler</Btn>
                                    <Btn variant="danger" disabled={disabled} onClick={() => save()}>Enregistrer</Btn>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

                <Footer />
            </div>
        </Fragment>
    )
}

export default Tranche
