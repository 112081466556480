import React, {useEffect, useState} from "react"
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import userAvatar from "../assets/img/img1.jpg";
import Notification from "../data/Notification";
import { useGetNotifyPlainteQuery, useUpdateNotifyPlainteMutation } from "../redux/apiSlice/plainteApiSlice";
import Swal from "sweetalert2";

export default function Header({ onSkin }) {

  const [id_stat_plt, setId_stat_plt] = useState(0);
  const [id_stat_plt_recp, setId_stat_plt_recp] = useState(0);
  const [id_stat_plt_instr, setId_stat_plt_instr] = useState(0);
  const [plt_recep, setPlt_recep] = useState({});
  const [plt_instr, setPlt_instr] = useState({});

  // console.log("plt_recep", plt_recep?.nb_plt)
  // console.log("plt_instr", plt_instr?.nb_plt)
  const {
    data: notiRecep =[] ,
  } = useGetNotifyPlainteQuery(id_stat_plt_recp);
// console.log('notiRecep', notiRecep)
  const {
    data: notInstr ,
  } = useGetNotifyPlainteQuery(id_stat_plt_instr);
  // console.log("notiRecep", notiRecep)
  // console.log("notInstr", notInstr)
  const [updateCatFaq ] = useUpdateNotifyPlainteMutation();

  const upNoti = async () => {
    try {
 
        await updateCatFaq({
       id_stat_plt
        }).unwrap();
   
    } catch (error) {
      console.log("error", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Erreur s'est produite!",
        
      });
    }
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
    >
      {children}
    </Link>
  ));

  const toggleSidebar = (e) => {
    e.preventDefault();
    let isOffset = document.body.classList.contains("sidebar-offset");
    if (isOffset) {
      document.body.classList.toggle("sidebar-show");
    } else {
      if (window.matchMedia("(max-width: 991px)").matches) {
        document.body.classList.toggle("sidebar-show");
      } else {
        document.body.classList.toggle("sidebar-hide");
      }
    }
  }

  // function NotificationList() {
  //   const notiList = notification.map((item, key) => {
  //     return (
  //       <div style={{

  //         display : 'flex',
  //         justifyContent : 'center',
  //         gap : 30
          

  //       }} key={key}>
  //         <div >{item.avatar}</div>
  //         <div className="list-group-body">
  //           <p>{item.text}</p>
            
  //         </div>
  //       </div>
  //     )
  //   });

  //   return (
  //     <ul className="list-group">
  //       {notiList}
  //     </ul>
  //   );
  // }

  const skinMode = (e) => {
    e.preventDefault();
    e.target.classList.add("active");

    let node = e.target.parentNode.firstChild;
    while (node) {
      if (node !== e.target && node.nodeType === Node.ELEMENT_NODE)
        node.classList.remove("active");
      node = node.nextElementSibling || node.nextSibling;
    }

    let skin = e.target.textContent.toLowerCase();
    let HTMLTag = document.querySelector("html");

    if (skin === "dark") {
      HTMLTag.setAttribute("data-skin", skin);
      localStorage.setItem('skin-mode', skin);

      onSkin(skin);

    } else {
      HTMLTag.removeAttribute("data-skin");
      localStorage.removeItem('skin-mode');

      onSkin('');
    }

  };

  const sidebarSkin = (e) => {
    e.preventDefault();
    e.target.classList.add("active");

    let node = e.target.parentNode.firstChild;
    while (node) {
      if (node !== e.target && node.nodeType === Node.ELEMENT_NODE)
        node.classList.remove("active");
      node = node.nextElementSibling || node.nextSibling;
    }

    let skin = e.target.textContent.toLowerCase();
    let HTMLTag = document.querySelector("html");

    HTMLTag.removeAttribute("data-sidebar");

    if (skin !== "default") {
      HTMLTag.setAttribute("data-sidebar", skin);
      localStorage.setItem("sidebar-skin", skin);
    } else {
      localStorage.removeItem("sidebar-skin", skin);
    }
  };

  const somNotify= (recep, inst)=>{

if (recep == undefined && inst != undefined) {

  return parseInt(inst)
} 

if(inst == undefined  && recep != undefined){

  return parseInt(recep)
}

if(recep == undefined && inst == undefined){

  return 0

}

if(recep != undefined && inst != undefined){

  return parseInt(recep) + parseInt(inst)
}

  }
  const inf = somNotify(plt_recep?.nb_plt, plt_instr?.nb_plt)
  // console.log("inf", inf)
  useEffect(() => {

   setId_stat_plt_recp(2)
   setId_stat_plt_instr(5)

   if (notiRecep) {
    setPlt_recep(notiRecep)
  }

    if (notInstr) {
      setPlt_instr(notInstr)
    }
 
    // console.log("plt_recep", plt_recep)
    // console.log("plt_instr", plt_instr)

  }, [notiRecep, notInstr]);
  return (
    <div className="header-main px-3 px-lg-4">
      <Link onClick={toggleSidebar} className="menu-link me-3 me-lg-4"><i className="ri-menu-2-fill"></i></Link>

      {/* <div className="form-search me-auto">
        <input type="text" className="form-control" placeholder="Search" />
        <i className="ri-search-line"></i>
      </div>      */}

      <div style={{width : '100%'}}></div>

      <Dropdown className="dropdown-skin" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <i className="ri-settings-3-line"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f">
          <label>Choix de thème</label>
          <nav className="nav nav-skin">
            <Link onClick={skinMode} className={localStorage.getItem("skin-mode") ? "nav-link" : "nav-link active"}>Light</Link>
            <Link onClick={skinMode} className={localStorage.getItem("skin-mode") ? "nav-link active" : "nav-link"}>Dark</Link>
          </nav>
          <hr />
          <label>  thème Sidebar </label>
          <nav id="sidebarSkin" className="nav nav-skin">
            <Link onClick={sidebarSkin} className={!(localStorage.getItem("sidebar-skin")) ? "nav-link active" : "nav-link"}>Default</Link>
            <Link onClick={sidebarSkin} className={(localStorage.getItem("sidebar-skin") === "prime") ? "nav-link active" : "nav-link"}>Prime</Link>
            <Link onClick={sidebarSkin} className={(localStorage.getItem("sidebar-skin") === "dark") ? "nav-link active" : "nav-link"}>Dark</Link>
          </nav>
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown className="dropdown-notification ms-3 ms-xl-4" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <small>{
          somNotify(plt_recep?.nb_plt, plt_instr?.nb_plt)}</small><i className="ri-notification-3-line"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f me--10-f">
          <div className="dropdown-menu-header">
            <h6 className="dropdown-menu-title">Notifications</h6>
          </div>
        
         < Notification
         plt={somNotify(plt_recep?.nb_plt, plt_instr?.nb_plt)}
          recep={plt_recep}
          instru={plt_instr}
         />
          
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown className="dropdown-profile ms-3 ms-xl-4" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <div className="avatar online">
            <img src={userAvatar} alt="" />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f">
          <div className="dropdown-menu-body">
            <div className="avatar avatar-xl online mb-3"><img src={userAvatar} alt="" /></div>
            <h5 className="mb-1 text-dark fw-semibold">Admin</h5>
            <p className="fs-sm text-secondary">Arcep</p>

            {/* <nav className="nav">
              <Link to=""><i className="ri-edit-2-line"></i> Edit Profile</Link>
              <Link to=""><i className="ri-profile-line"></i> View Profile</Link>
            </nav> */}
            <hr />
            <nav className="nav">
              {/* <Link to=""><i className="ri-question-line"></i> Help Center</Link>
              <Link to=""><i className="ri-lock-line"></i> Privacy Settings</Link>
              <Link to=""><i className="ri-user-settings-line"></i> Account Settings</Link> */}
              <Link to="/pages/signin"><i className="ri-logout-box-r-line"></i> Déconnecter</Link>
            </nav>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}