import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";
import Spinner from "react-bootstrap/Spinner";
import { Button } from "primereact/button";
import { Button as Btn, Form, Table, Modal, Badge } from "react-bootstrap";
import "../styleNumerotation.css";
import Select from "react-select";
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import Swal from "sweetalert2";
import { format } from "date-fns";
import "jspdf-autotable";

import {
  useGetUssdQuery,
  useUpdateUssdMutation,
  useCreateAttrUssdMutation,
  useUpdateAttrUssdMutation,
  useDeleteAttrUssdMutation,
  useGetAttrUssdByUSSDQuery,
  useGetAttrUssdQuery,
  useGetClientsQuery,
  useGetStatutNumQuery,
  useGetUssdBytypeQuery,
  useGetServiceTrancheQuery
} from "../../../redux/apiSlice/numerotationApiSlice";

import { useGetOperateursQuery } from "../../../redux/apiSlice/operateurApiSlice";
import AjoutClientModal from "../AjoutClientModal";

export default function TableEtatUssd(props) {
  const [id_ussd_attr, setId_ussd_attr] = useState(0);
  const [listeUssd, setListeUssd] = useState([]);
  const [type, setType] = useState(1);

  const { data, isLoading } = useGetUssdQuery();
  const [updateUssd] = useUpdateUssdMutation();

  const [createAttrUss] = useCreateAttrUssdMutation();
  const [updateAttrUss] = useUpdateAttrUssdMutation();
  const [deleteAttrUss] = useDeleteAttrUssdMutation();
  const { data: oneAttrUSSd = [] } = useGetAttrUssdByUSSDQuery(id_ussd_attr);
  const { data: listeByTypeUssd = [] } = useGetUssdBytypeQuery(type);
  const { data: attrUSSd = [] } = useGetAttrUssdQuery();

  const { data: statutNum = [], isLoading: loadStatutNum } =
    useGetStatutNumQuery();
  const { data: operateur = [] } = useGetOperateursQuery();
  const { data: clients = [] } = useGetClientsQuery();
  const { data: services = [] } = useGetServiceTrancheQuery();
  const [globalFilter, setGlobalFilter] = useState([]);
  const [ctrl, setCtrl] = useState(0);
  const [expState, setExpState] = useState([]);

  const [typeUssd, setTypeUssd] = useState([
    { type: 1 },
    { type: 2 },
    { type: 3 },
    { type: 4 },
    { type: 5 },
    { type: 6 },
    { type: 7 },
    { type: 8 },
    { type: 9 },
  ]);

  const init = {
    id_occupant: 0,
    id_ussd: 0,
    num_decision: "",
    service_impl: "",
    commentaire: "",
    date_attr: "",
    type_occupant: 0,
  };

  const [form, setForm] = useState({ ...init });
  const [statut_num, setStatut_num] = useState(0);
  const [objetForm, setObjetForm] = useState({});

  // modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = (rowData) => {
    setCtrl(rowData.id_stat_num);

    if (parseInt( rowData.id_stat_num) == 1 || parseInt( rowData.id_stat_num) == 6) {  
      setObjetForm({})
      setForm({ ...form, id_ussd: rowData.id_ussd });
    } else {

      setForm({ ...form, id_ussd: rowData.id_ussd });
      setId_ussd_attr(rowData.id_ussd);
      
    }

    setShow(true);
  };

  const [ajoutClient, setAjoutClient] = useState(false);
  const handleAjoutClient = () => setAjoutClient(!ajoutClient);

  // modal
  const [showInfOcup, setShowInfOcup] = useState(false);
  const handleInfOcup = () => setShowInfOcup(!showInfOcup);

  function formaterDate(dateStr) {
    const date = new Date(dateStr);
    return format(date, "dd-MM-yyyy");
  }

  const isTimestamp = (value) => {
    return !isNaN(value) && !isNaN(parseFloat(value));
  };
  
  let monTab =[]

  const save = async () => {
   
    handleClose();
    Swal.fire({
      title: "Gestion des  USSD",
      text: "Voulez-vous continuer ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then(async (result) => {
      if (result.isConfirmed === true) {

        if (statut_num == 0) {
          Swal.fire({
            title: "ERREUR",
            text: "Veuillez choisir le statut",
            icon: "error",
          });
        } else {
          if (statut_num == 4 && (form.id_occupant == "" || objetForm == "")) {
            Swal.fire({
              title: "ERREUR",
              text: "Veuillez choisir un opérateur",
              icon: "error",
            });
          } else {
            if (ctrl == 1 || ctrl == 6) {
          
               await createAttrUss(form)
                 .unwrap()
                 .then((payload) => {
                   Swal.fire({
                     title: "SUCCES",
                     text: "réussie",
                     icon: "success",
                   });
     
                   window.location.reload();
                 })
                 .catch((error) => {
                   console.log('error1', error)
                   Swal.fire({
                     title: "ERREUR",
                     text: "Une erreur s'est produite , veuillez réessayer",
                     icon: "error",
                   });
                 });
             } else if (statut_num == 1 ) {
          
               deleteAttrUss(objetForm.id_attr_ussd)
                 .unwrap()
                 .then((rsul) => {
                   Swal.fire({
                     title: "SUCCES",
                     text: "réussie",
                     icon: "success",
                   });
     
                   window.location.reload();
                 })
                 .catch((error) => {
                   console.log('error2', error)
                   Swal.fire({
                     title: "ERREUR",
                     text: "Une erreur s'est produite , veuillez réessayer",
                     icon: "error",
                   });
                 });
             } else {
          
               await  updateUssd({id_stat_num: statut_num, id_ussd :form.id_ussd })
               .unwrap()
               .then((retour) => {
               
             
               const objt = {...objetForm, 
                 id_stat_num: statut_num,
                  id_occupant : form.id_occupant !="" ? form.id_occupant : objetForm.id_occupant ,
                  type_occupant : form.type_occupant !="" ? form.type_occupant : objetForm.type_occupant ,
                  date_attr : objetForm.date_attr == null 
                   ? null 
                   :
                   isTimestamp(objetForm.date_attr)
                   ?
                   objetForm.date_attr
                   :
                  new Date (objetForm.date_attr ).getTime()
                 }
   

                   updateAttrUss(objt)
                     .unwrap()
                     .then((rsul) => {
                   
                       Swal.fire({
                         title: "SUCCES",
                         text: "réussie",
                         icon: "success",
                       });
     
                       window.location.reload();
                     })
                     .catch((error) => {
                    
                       console.log('error3', error)
                       Swal.fire({
                         title: "ERREUR",
                         text: "Une erreur s'est produite , veuillez réessayer",
                         icon: "error",
                       });
                     });
                   })
                   .catch((error) => {
                    console.log('error2.5', error)
                 
                   });
             }
          }
        }
        
       
      }
    });
  };

  const handleChangeAtt = (value) => {
    setForm({ ...form, id_occupant: parseInt(value.value) });
  };

  const infOccupant = (rowData) => {
    setId_ussd_attr(rowData.id_ussd);
    handleInfOcup();
  };

  const giveInfOp = (data, operateur, clients) => {
    if (parseInt(data?.type_occupant) === 0) {
      const result = operateur.find((item) => item.id_operateur === data?.id_occupant);
      return result?.nom_operateur;
    } else {
      const result = clients.find((item) => item.id_clt === data?.id_occupant);
      return result?.nom_clt;
    }
  };
  

  const expoData = (data) => {
   
    let cmpt = 0
    monTab=[]

     data.map((item) => {
      cmpt++
      const rsul = attrUSSd.find((oc) => oc.id_ussd === item.id_ussd);
  
      if (rsul) {
        monTab.push( {
          ussd: item.Ussd_list?.libelle,
          etat: stat(parseInt(item.id_stat_num)),
          operateur: giveInfOp(rsul, operateur, clients),
          date_attr: rsul.date_attr == null || parseInt(rsul.date_attr) === 0 ? "-" : formatDate(rsul.date_attr),
          commentaire: rsul.commentaire || "-"
        });
      } else {
        monTab.push( {
          ussd: item.Ussd_list?.libelle,
          etat: stat(parseInt(item.id_stat_num)),
          operateur: "-",
          date_attr: "-",
          commentaire: "-"
        });
      }
   
      if (cmpt == data.length) {
        console.log('monTab', monTab)
        setExpState(monTab)
        
      }
    });
  
    
  };

  useEffect(() => {
    setObjetForm(oneAttrUSSd[0]);
    setListeUssd(listeByTypeUssd)
    expoData(listeByTypeUssd)
    
  }, [oneAttrUSSd, listeByTypeUssd]);

  const formatDate = (data) => {
    const timestamp = typeof data === "string" ? parseInt(data, 10) : data;
    const date = new Date(timestamp);
    if (isNaN(date.getTime())) {
      return "Date invalide";
    }

    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();

    return formattedDate;
  };

  const formatVu = (timestamp) => {
    // Convertir le timestamp en objet Date

    const time = parseInt(timestamp);
    const date = new Date(time);

    // Formater la date au format ISO 8601 (YYYY-MM-DD)
    const dateString = date.toISOString().split("T")[0];

    return dateString;
  };

  const dt = useRef(null);

  const cols = [
    { field: "num", header: "N°", sortable: true },
    { field: "Ussd_list.libelle", header: "Ussd", sortable: true },
    { field: "com", header: "Service ", sortable: true },
    // { field: "Service_tran.lib_service", header: "Service ", sortable: true },
    { field: "statut", header: "Statut ", sortable: true },
    { field: "actions", header: "Actions", sortable: false },
  ];

  const colsExpo = [
    { field: "ussd", header: "Ussd", sortable: true },
    { field: "etat", header: "Etat ", sortable: true },
    { field: "operateur", header: "Operateur", sortable: true },
    { field: "date_attr", header: "Date d'attribution", sortable: true },
    { field: "commentaire", header: "Commentaire", sortable: true },
  ];

  const exportColumns = colsExpo.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  

  const badge = (value) => {
    switch (value) {
      case 1:
        return "dark";
      case 2:
        return "secondary";
      case 3:
        return "warning";
      case 4:
        return "success";
      case 5:
        return "danger";
      case 6:
        return "danger";
      default:
        break;
    }
  };
  const stat = (value) => {
    switch (value) {
      case 1:
        return "Libre";
      case 2:
        return "En instance";
      case 3:
        return "Réservée";
      case 4:
        return "Attribuée";
      case 5:
        return "Bloquée";
      case 6:
        return "Inutilisable";
      default:
        break;
    }
  };

  const handleChange = (lib, value) => {

    switch (lib) {

      case 'type':
        setType(value)
        break;
      case 'service':
        const list = data.filter((item)=>(item.id_service == parseInt(value)))  
        setListeUssd(list)
        expoData(list)
        break;
      case 'statut':
        const statuts = data.filter((item)=>(item.id_stat_num == parseInt(value)))  
        setListeUssd(statuts)
        expoData(statuts)
        break;

      default:
        break;
    }
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);

        doc.autoTable(exportColumns, expState);
        doc.save("Ressources ussd.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(expState);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // saveAsExcelFile(excelBuffer, "Ressources");
    });
  };

  // const saveAsExcelFile = (buffer, fileName) => {
  //   import("file-saver").then((module) => {
  //     if (module && module.default) {
  //       let EXCEL_TYPE =
  //         "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  //       let EXCEL_EXTENSION = ".xlsx";
  //       const data = new Blob([buffer], {
  //         type: EXCEL_TYPE,
  //       });

  //       module.default.saveAs(
  //         data,
  //         fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
  //       );
  //     }
  //   });
  // };

  const header = (
    <div className="container_header_data_table">
      <div className="flex align-items-center">
        <div className="p-input-icon-left ml-3">
          <i className="pi pi-search" />
          <InputText
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
            placeholder="Rechercher"
          />
        </div>
      </div>
      <div className="container_export">
        <Button
          type="button"
          icon="pi pi-file-pdf"
          rounded
          onClick={exportPdf}
          className=""
          data-pr-tooltip="PDF"
          style={{ borderRadius: 30 }}
          severity="secondary"
          size="small"
        />
        <Button
          type="button"
          icon="pi pi-file-excel"
          className=""
          severity="success"
          rounded
          onClick={exportExcel}
          data-pr-tooltip="Excel"
          style={{ borderRadius: 30 }}
          size="small"
        />
        {/* <Button
          type="button"
          icon="pi pi-file"
          rounded
          onClick={() => exportCSV(false)}
          className=""
          data-pr-tooltip="CSV"
          style={{ borderRadius: 30 }}
          size="small"
        /> */}
      </div>
    </div>
  );

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center">
        {rowData.id_stat_num == 2 && (
          <a
            onClick={() => infOccupant(rowData)}
            className="btn btn-success p-2 mr-1"
            style={{ cursor: "pointer" }}
          >
            <i className="ri-eye-line ri-2x" style={{ fontSize: 13 }}></i>
            <span></span>
          </a>
        )}
        {rowData.id_stat_num == 3 && (
          <a
            onClick={() => infOccupant(rowData)}
            className="btn btn-success p-2 mr-1"
            style={{ cursor: "pointer" }}
          >
            <i className="ri-eye-line ri-2x" style={{ fontSize: 13 }}></i>
            <span></span>
          </a>
        )}
        {rowData.id_stat_num == 4 && (
          <a
            onClick={() => infOccupant(rowData)}
            className="btn btn-success p-2 mr-1"
            style={{ cursor: "pointer" }}
          >
            <i className="ri-eye-line ri-2x" style={{ fontSize: 13 }}></i>
            <span></span>
          </a>
        )}
        {
          <a
            onClick={() => handleShow(rowData)}
            className="btn btn-success p-2 mr-1"
            style={{ cursor: "pointer" }}
          >
            {/* <i className="ri-eye-line ri-2x" style={{fontSize :12}}></i> */}
            Actions
            <span></span>
          </a>
        }
      </div>
    );
  };

  const etatComp = (rowData) => {
    return (
      <div className="flex align-items-center">
        <Badge bg={badge(parseInt(rowData.id_stat_num))}>
          {stat(parseInt(rowData.id_stat_num))}
        </Badge>
      </div>
    );
  };
  const serviceImpl = (rowData) => {
let sev 


const rsul = attrUSSd.find((item)=>(parseInt(item.id_ussd) === parseInt(rowData.id_ussd)))
if (rsul) {
  if (rsul?.service_implemente !="") {
    sev = rsul?.service_implemente
  } else {
    sev = "-"
  }
}
    return (
      <div className="flex align-items-center">
  {sev}
         
      </div>
  );
  };

  const rowClassName = (rowData, index) => {
    return index !== listeUssd.length - 1 ? "table-row" : "";
  };

  return (
    <div className="card" style={{ width: "100%" }}>
      <Tooltip target=".export-buttons>button" position="bottom" />
      <style>{`
            .table-row td {
                border-bottom: 1px solid #dee2e6; // Bordure inférieure pour les cellules de la ligne
            }
        `}</style>
        <div className="container row mb-5">


<div className="col">
<Form.Label>Type USSD</Form.Label>
 
    
                 
                    <Form.Select
                      value={type}
                      onChange={(e) => handleChange("type", e.target.value)}
                    >
                      
                      {typeUssd.map((type, i) => (
                        <option key={i} value={type.type}>
                          {type.type}
                        </option>
                      ))}
                    </Form.Select>
                 
</div>



  <div className="col">
  
    <Form.Label>Service(Groupe)</Form.Label>
    <Form.Select onChange={(e) => handleChange("service", e.target.value)}>
  <option>Choisir</option>
  {services
    .filter((type) => type.id_service === 15 || type.id_service === 16 || type.id_service === 17)
    .map((type, i) => (
      <option key={i} value={type.id_service}>
        {type.lib_service}
      </option>
    ))}
</Form.Select>

    
  </div>
  <div className="col">
  
    <Form.Label>Statut</Form.Label>
    <Form.Select onChange={(e) => handleChange("statut", e.target.value)}>
  <option>Choisir</option>
  {[
                { label: "Libre", value: 1 },
                { label: "En instance", value: 2 },
                { label: "Réservée", value: 3 },  
                { label: "Attribuée", value: 4 },
                { label: "Bloquée", value: 5 },
                { label: "Inutilisable", value: 6 },
              ]
    .map((type, i) => (
      <option key={i} value={type.value}>
        {type.label}
      </option>
    ))}
</Form.Select>

    
  </div>

</div>

      <>
        {isLoading ? (
          <div class="card">
            <Btn variant="primary" disabled style={{ height: 200 }}>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Loading...
            </Btn>
          </div>
        ) : (

          <DataTable
            ref={dt}
            value={listeUssd}
            header={header}
            tableStyle={{ minWidth: "100%", fontSize: 12 }}
            globalFilter={globalFilter}
            emptyMessage="Pas de résultat."
            paginator
            rows={10}
            rowClassName={rowClassName}
          >
            {cols.map((col, index) => (
              <Column
                key={index}
                field={col.field}
                header={col.header}
                sortable={col.sortable}
                body={
                  col.field === "actions"
                    ? actionBodyTemplate
                    : col.field === "statut"
                    ? etatComp
                    : col.field === "com" 
                    ? serviceImpl
                    :
                    col.field === "num"
                    ? (data, options) => options.rowIndex + 1
                    : null
                    
                }
              />
            ))}
          </DataTable>
        )}
      </>

      {/* ----------------Modal commentaire gestion obligations----------- */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Attribution de USSD</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="row container">
        <div className="mt-3">
                <Form.Label>Statut</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => setStatut_num(e.target.value)}
                >
                  {statutNum.map((item, index) => (
                    <option key={index} value={item.id_stat_num}>
                      {item.libelle}
                    </option>
                  ))}
                </Form.Select>
              </div>
              </div>
          
            <div className="container  row">
              <div className="col">
                <Form.Check
                  type="radio"
                  label="Opérateur"
                  value={0}
                  checked={form.type_occupant === 0}
                  onChange={() =>
                    setForm({ ...form, type_occupant: 0, id_operateur: "" })
                  }
                />
              </div>
              <div className="col">
                <Form.Check
                  type="radio"
                  label="Client"
                  value={1}
                  checked={form.type_occupant === 1}
                  onChange={() =>
                    setForm({ ...form, type_occupant: 1, id_operateur: "" })
                  }
                />
              </div>
              {form.type_occupant === 1 && (
                <div className="col">
                  <AddOutlineIcon
                    size={30}
                    style={{ cursor: "pointer", fontSize: "25px" }}
                    onClick={() => {
                      handleAjoutClient();
                    }}
                  />
                </div>
              )}
              <div style={{ width: "100%" }}>
                {form.type_occupant === 0 && (
                  <Select
                    options={operateur.map((item) => {
                      return {
                        label: item.nom_operateur,
                        value: item.id_operateur,
                      };
                    })}
                    isSearchable={true}
                    onChange={handleChangeAtt}
                  />
                )}

                {form.type_occupant === 1 && (
                  <Select
                    options={clients.map((item) => {
                      return {
                        label: item.nom_clt,
                        value: item.id_clt,
                      };
                    })}
                    isSearchable={true}
                    onChange={handleChangeAtt}
                  />
                )}
              </div>
            </div>
          <div className="row container">
         
          
         
            <div className="mt-3">
              <Form.Label>Date d'attribution</Form.Label>
              <Form.Control
                type="date"
                // value={
                //   ctrl !== 1 &&

                //   moment(oneAttrUSSd[0]?.date_attr).format('DD-MM-YYYY')
                // }
                onChange={(e) =>{
                ctrl == 1 || ctrl == 6 ?
                  setForm({ ...form, date_attr: e.target.value })
                  :
                  setObjetForm({ ...objetForm, date_attr: e.target.value })
                  }
                }
              />
            </div>
            <div className="mt-3">
              <Form.Label>No de décision</Form.Label>
              <Form.Control
                type="text"
                placeholder="numéro de décision"
                value={ctrl == 1 || ctrl == 6 ? form.num_decision : objetForm?.num_decision}
                onChange={(e) =>
                 ctrl == 1 || ctrl == 6
                    ? setForm({ ...form, num_decision: e.target.value })
                    : setObjetForm({
                        ...objetForm,
                        num_decision: e.target.value,
                      })
                }
              />
            </div>
            <div className="mt-3">
              <Form.Label>Services implémentés</Form.Label>
              <Form.Control
                type="text"
                placeholder="service implémenté"
                value={
                 ctrl == 1 || ctrl == 6 ? form.service_impl : objetForm?.service_implemente
                }
                onChange={(e) =>
                 ctrl == 1 || ctrl == 6
                    ? setForm({ ...form, service_impl: e.target.value })
                    : setObjetForm({
                        ...objetForm,
                        service_implemente: e.target.value,
                      })
                }
              />
            </div>
            <div className="mt-3">
              <Form.Label>Commentaire</Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                placeholder="commentaire..."
                onChange={(e) =>
                  ctrl == 1 || ctrl == 6
                    ? setForm({ ...form, commentaire: e.target.value })
                    : setObjetForm({
                        ...objetForm,
                        commentaire: e.target.value,
                      })
                }
                value={ctrl == 1 || ctrl == 6 ? form.commentaire : objetForm?.commentaire}
              ></Form.Control>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Btn variant="primary" onClick={handleClose}>
            Annuler
          </Btn>
          <Btn variant="success" onClick={save}>
            Enregistrer
          </Btn>
        </Modal.Footer>
      </Modal>

      {/* ----------------Modal ajout client ----------- */}
      <Modal
        show={ajoutClient}
        onHide={handleAjoutClient}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Ajout Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AjoutClientModal handleAjoutClient={handleAjoutClient} />
        </Modal.Body>
      </Modal>

      {/* ----------------Modal infos occupant----------- */}
      <Modal
        show={showInfOcup}
        onHide={() => handleInfOcup()}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Détails</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="fluid-container">
            

              {oneAttrUSSd.length > 0 &&
                oneAttrUSSd.map((item) => {
                  let occ;
                  if (item.type_occupant == 0) {
                    const rsul = operateur.find(
                      (op) => op.id_operateur == item.id_occupant
                    );
                    occ = rsul?.nom_operateur;
                  } else {
                    const rsul = clients.find(
                      (op) => op.id_clt == item.id_occupant
                    );
                    occ = rsul?.nom_clt;
                  }

                  return (
                    <table class="table table-bordered border-primary">
                    <tr>
                    <th class="border col-6">Numéro de decision</th>
                      <td class="border col-6">{item.num_decision}</td>
                      </tr>

                       <tr>
                       <th class="border col-6">Service implementé</th>
                      <td class="border col-6">{item.service_implemente}</td>
                      </tr>

                       <tr>
                       <th class="border col-6">Commentaire</th>
                      <td class="border col-6">{item.commentaire}</td>
                      </tr>

                      <tr>
                      <th class="border col-6">Date</th>
                      <td class="border col-6">{item.date_attr == null ?  "" : parseInt(item.date_attr) == 0 ? "" : formatDate(item.date_attr)}</td>
                      </tr>

                      <tr>
                      <th class="border col-6">Occupant</th>
                      <td class="border col-6">{occ}</td>
                    </tr>
                    </table>
                  );
                })}
     
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleInfOcup}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
