import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Form, Modal } from "react-bootstrap";
// import { Card, Col, Row } from "react-bootstrap";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Sidebar from "../layouts/Sidebar";
import { NavLink } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

export default function SoumissionPlainte() {
  const navigate = useNavigate()

  const [etat, setEtat] = useState(false);
  // modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    if (id == 0) {
      setShow(true)
    }
   
  };

  // const [show1, setShow1] = useState(false);
  // const handleClose1 = () => setShow1(false);
  // const handleShow1 = () => setShow1(true);

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  const urlParams = new URLSearchParams(window.location.search);
  const registerParam = urlParams.get('register');
  useEffect(() => {
    if (registerParam === 'true') {
      toast.success("Plainte ajoutée avec succès.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      
    } 
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <NavLink to="/dashboard">Dashboard</NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Soumission de plaintes
              </li>
            </ol>
            <h4 className="main-title mb-0">Dépot de plaintes</h4>
          </div>
        </div>

        <Row className="g-3">
          {[
            {
              bg: "primary",
              icon: "ri-survey-fill",
              value: "Nouvelle plainte",
              label: "Formulaire d'ajout d'une nouvelle plainte",
              link: "/plainte",
            },
            {
              bg: "primary",
              icon: "ri-wallet-line",
              value: "Ajout des plaintes",
              label: "Charger plusieurs plaintes",
              
            },
            {
              bg: "primary",
              icon: "ri-file-text-line",
              value: "Importer",
              label: "Template Excel de chargement",
          
            },
          ].map((item, index) => (
            <Col sm="4" key={index}>
              <NavLink to={item.link}>
                <Card className="card-one">
                  <Card.Body className="p-3">
                    <div className="d-flex d-sm-block d-xl-flex align-items-center">
                      <div className={"helpdesk-icon text-white bg-" + item.bg}>
                        <i className={item.icon}></i>
                      </div>
                      <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                        <h2 className="card-value d-flex align-items-baseline mb-0">
                          {item.value}{" "}
                        </h2>
                        <label className="card-label fs-sm fw-medium mb-1">
                          {item.label}
                        </label>
                        <div className="mutual-badge"></div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </NavLink>
            </Col>
          ))}
        </Row>
        <Row className="mt-5">
          
        
        </Row>
        <Footer />

      </div>
    </React.Fragment>
  );
}
