import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";

const Step1 = (props) => {

    const { plainteData, setPlainteData, enableOrDisable } = props;
    const [emailError, setEmailError] = useState();
    const [telError, setTelError] = useState();
    const [nomError, setNomError] = useState();
    const [raisonError, setRaisonError] = useState();
    const [sexeError, setSexeError] = useState();
    const [profeError, setProfeError] = useState();
    const [nationaliteError, setNationaliteError] = useState();
    const [prenomError, setPrenomError] = useState();
    const [adresseError, setAdresseError] = useState();
    const changePlainteData = (field, value) => {
        setPlainteData({ ...plainteData, [field]: value });
    };
    const [nationalite, setNationalite] = useState([]);
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePhoneNumber = (phoneNumber) => {
        const phoneRegex = /^\d{10}$/;
        return phoneRegex.test(phoneNumber);
    };

    const validateNonEmpty = (value, setValidationError) => {
        const isValid = value.trim() !== "";
        setValidationError(isValid ? null : 'Champ requis');
        // isValid ? enableOrDisable(false) : enableOrDisable(true);
        return isValid;
    };

    const handleTelChange = (e) => {
        const newValue = e.target.value;
;
        if (!isNaN(newValue) && newValue >= 0) {
            changePlainteData('tel_plt', newValue);
            // enableOrDisable(false);
        } else {
           
        }
       
    };

    const handleEmailChange = (e) => {
        const emailValue = e.target.value;
        if (validateEmail(emailValue)) {
            setEmailError(null);
            enableOrDisable(false);
        } else {
            setEmailError('Données invalide');
            enableOrDisable(true);
        }
        changePlainteData('email_plt', emailValue);
    };

    const handleRaisonChange = (e) => {
        const raisonValue = e.target.value;
        validateNonEmpty(raisonValue, setRaisonError);
        changePlainteData('raison', raisonValue);
    };

    const handleNomChange = (e) => {
        const nomValue = e.target.value;
        validateNonEmpty(nomValue, setNomError);
        changePlainteData('nom_plt', nomValue);
    };

    const handlePrenomChange = (e) => {
        const prenomValue = e.target.value;
        validateNonEmpty(prenomValue, setPrenomError);
        changePlainteData('prenom_plt', prenomValue);
    };

    const handleSexeChange = (e) => {
        const sexeValue = e.target.value;
        validateNonEmpty(sexeValue, setSexeError);
        changePlainteData('sexe', sexeValue);
    };
    const handleProfeChange = (e) => {
       
        const profeValue = e.target.value;
        console.log("profeValue", profeValue)
        validateNonEmpty(profeValue, setProfeError);
        changePlainteData('profession', profeValue);
    };
    const handleNationaliteChange = (value) => {
 
        const nationaliteValue = value.value;
        console.log("nationaliteValue", nationaliteValue)
        validateNonEmpty(nationaliteValue, setNationaliteError);
        changePlainteData('nationalite', nationaliteValue);
    };

    const handleAdresseChange = (e) => {
        const adresseValue = e.target.value;
        validateNonEmpty(adresseValue, setAdresseError);
        changePlainteData('adresse_plt', adresseValue);
    };

    const handleChangeNation = (value) => {

       
      }
    

    useEffect(() => {
       
        fetch('https://restcountries.com/v3.1/all')
        .then(response => response.json())
        .then(data => {
          // `data` contient la liste des pays avec leurs informations
        //   console.log(data);
          setNationalite(data)
        })
        .catch(error => {
          console.error('Erreur lors de la récupération des données:', error);
        });
    }, []);
    
    return (
        <div className="container  p-4">
            <h6 className="mb-3">Identification du plaignant</h6>

            <div className="row mt-4">
                <div className="col-6">
                    <h6>Nature du plaignant *  :</h6>

                    <Form.Check
                        type="radio"
                        label="Personne physique"
                        value="0"

                        checked={plainteData.type_personne === "0"}
                        onChange={(e) => changePlainteData("type_personne", e.target.value)}
                    />
                    <Form.Check
                        type="radio"
                        value="1"

                        label="Personne Morale"
                        checked={plainteData.type_personne === "1"}
                        onChange={(e) => changePlainteData("type_personne", e.target.value)}
                    />
                </div>

                <div className="col-6">

                    {
                        plainteData.type_personne === "1" && (
                            <div>   <h6>Nom de l'entreprise *  :</h6>


                                <Form.Control
                                    onChange={handleRaisonChange}
                                    type="text"
                                    value={plainteData.raison}
                                    id="exampleFormControlInput1"
                                    placeholder="Nom de l'entreprise"
                                /> </div>
                        )
                    }
                </div>


            </div>
            <div className="row mt-4">
                <div className="col-5">
                    <Form.Label>Nom du plaignant *</Form.Label>
                    <Form.Control
                        onChange={handleNomChange}
                        type="text"
                        value={plainteData.nom_plt}
                        id="exampleFormControlInput1"
                        placeholder=" Nom "
                    />
                    {nomError && (
                        <span style={{ color: 'red', fontSize: '15px' }}>{nomError}</span>
                    )}
                </div>
                <div className="col-5">
                    <Form.Label>Prénoms du plaignant *</Form.Label>
                    <Form.Control
                        onChange={handlePrenomChange}
                        type="text"
                        value={plainteData.prenom_plt}
                        id="exampleFormControlInput1"
                        placeholder="prénoms"
                    />
                    {prenomError && (
                        <span style={{ color: 'red', fontSize: '15px' }}>{prenomError}</span>
                    )}
                </div>

                
                <div className="col-2">
                    <Form.Label>Sexe *</Form.Label>
                    <Form.Select value={plainteData.sexe} onChange={handleSexeChange}>
                        <option>Choisir</option>
                        <option value="F">Féminin</option>
                        <option value="M">Masculin</option>
                    </Form.Select>
                    {sexeError && (
                        <span style={{ color: 'red', fontSize: '15px' }}>{sexeError}</span>
                    )}
                </div>
            </div>


            <div className="row pt-4">
                <div className="col">
                    <Form.Label>
                        Téléphone *
                    </Form.Label>
                    <Form.Control
                        onChange={handleTelChange}
                        type="number"
                        value={plainteData.tel_plt}
                        id="exampleFormControlInput1"
                        placeholder="Téléphone"
                        required
                        pattern="[0-9]*"
                    />
                    {
                        telError && (
                            <span style={{ color: 'red', fontSize: "15px" }}> {telError}</span>
                        )
                    }
                </div>
                <div className="col">
                    <Form.Label>Email *</Form.Label>
                    <Form.Control
                        onChange={handleEmailChange}
                        type="email"
                        value={plainteData.email_plt}
                        id="exampleFormControlInput1"
                        placeholder="Email"
                        required
                    />
                    {
                        emailError && (
                            <span style={{ color: 'red', fontSize: "15px" }}> {emailError}</span>
                        )
                    }
                </div>
                <div className="col">
                    <Form.Label>Adresse de résidence *</Form.Label>
                    <Form.Control
                        onChange={handleAdresseChange}
                        type="text"
                        value={plainteData.adresse_plt}
                        id="exampleFormControlInput1"
                        placeholder="Adresse de résidence"
                    />
                    {adresseError && (
                        <span style={{ color: 'red', fontSize: '15px' }}>{adresseError}</span>
                    )}
                </div>
            </div>
            <div className="row pt-4">
                <div className="col">
                <Form.Label>Profession *</Form.Label>
                <Form.Control
                        onChange={handleProfeChange}
                        type="text"
                        value={plainteData.profession}
                        id="exampleFormControlInput1"
                        placeholder=" Nom "
                    />
                    {profeError && (
                        <span style={{ color: 'red', fontSize: '15px' }}>{profeError}</span>
                    )}
                </div>
                <div className="col">
                <Form.Label>Nationalité *</Form.Label>
                <Select 
              options={nationalite.map((item)=>{
                return{
                  value : item.demonyms?.fra?.f,
                  label : item.demonyms?.fra?.f

                }
              })} 
              isSearchable={true} 
              onChange={handleNationaliteChange}
              />
                    {nationaliteError && (
                        <span style={{ color: 'red', fontSize: '15px' }}>{nationaliteError}</span>
                    )}
                </div>
                
            </div>
        </div >
    );
};

export default Step1;
