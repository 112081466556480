import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  getStatutPlaintes,
  getTypePlaintes,
} from "../../redux/slice/plainteSlice";
import { getOperateurs } from "../../redux/slice/operateurSlice";

import {
  useGetServicesQuery,
} from "../../redux/apiSlice/plainteApiSlice.js";
const Step0 = (props) => {
  const { dummyDatas, setDummyData, displayme, plainteData, setPlainteData, enableOrDisable } = props;
  const typePlaintes = useSelector(getTypePlaintes);
  const operateurs = useSelector(getOperateurs);
  const [errors, setErrors] = useState({});
  const [duree, setDuree] = useState(2);
  // console.log("duree", duree)
  const {
    data: services,
    refetch: refetchServices,
    isLoading: loadServices,
    isSuccess: isSuccessServices
  } = useGetServicesQuery();

  const [refPlainteError, setRefPlainteError] = useState();
  const [motifPlainteError, setMotifPlainteError] = useState();
  const [catError, setCatError] = useState();
  const [typeError, setTypeError] = useState();
  const [serviceError, setServiceError] = useState();


  // Validation functions
  const validateNonEmpty = (value, setValidationError) => {
    const isValid = value.trim() !== "";
    setValidationError(isValid ? null : 'Champ requis');
    //isValid ? enableOrDisable(false) : enableOrDisable(true);
    return isValid;
  };

  const handleRefPlainteChange = (e) => {
    const ref = e.target.value;
    validateNonEmpty(ref, setRefPlainteError);
    changePlainteData('ref_plt', ref);
  };

  const handleMotifPlainteChange = (e) => {
    const ref = e.target.value;
    validateNonEmpty(ref, setMotifPlainteError);
    changePlainteData('motif_plt', ref);
  };

  const validateSelect = (e, setValidationError, key) => {

    const isValid = e.target.value.trim() !== "";
    setValidationError(isValid ? null : 'Champ requis');
    // isValid ? enableOrDisable(false) : enableOrDisable(true);
    changePlainteData(key, parseInt(e.target.value))
  }




  const statutPlaintes = useSelector(getStatutPlaintes);

  //console.log("operateurs" + typePlaintes)

  const changePlainteData = (field, value) =>
    setPlainteData({ ...plainteData, [field]: value });
  const changeDummyData = (field, value) =>
    setDummyData({ ...dummyDatas, [field]: value });


  useEffect(() => {
    if (isSuccessServices) {
      // Data is available, and you can proceed to use it
      console.log(services);
      const selectedItems = services
      // Additional logic or state updates
      //setAccuse({ liste: selectedItems })
      //localStorage.setItem('plaintes', JSON.stringify({ liste: selectedItems }))

    }
  }, [isSuccessServices, services]);

  return (
    <div className="container p-4">
      <h6 className="p-2">Identification de la plainte</h6>

      <div className="row">
        <div className="col">
          <h6>
            Avez-vous fait un recours à l'opérateur au sujet de votre plainte
            ? *
          </h6>
          <Form.Check
            type="radio"
            label="Oui"
            value="1"
            checked={plainteData.recours === "1"}
            onChange={(e) => changePlainteData("recours", e.target.value)}
          />
          {/* <Form.Check
            type="radio"
            label="Oui (moins d'un mois)"
            value="1"
            checked={plainteData.recours === "1"}
            onChange={(e) => changePlainteData("recours", e.target.value)}
          /> */}
          <Form.Check
            type="radio"
            label="Non"
            value="0"
            checked={plainteData.recours === "0"}
            onChange={(e) => changePlainteData("recours", e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col"  style={{ display: ['0'].includes(plainteData.recours) ? 'none' : 'block' }}>
          <h6>
          la durée fait déjà au moins un mois ?*
          </h6>
          <Form.Check
            type="radio"
            label="Oui"
            value="1"
            checked={plainteData.delai === "1"}
            onChange={(e) => changePlainteData("delai", e.target.value)}
          />
          {/* <Form.Check
            type="radio"
            label="Oui (moins d'un mois)"
            value="1"
            checked={plainteData.recours === "1"}
            onChange={(e) => changePlainteData("recours", e.target.value)}
          /> */}
          <Form.Check
            type="radio"
            label="Non"
            value="0"
            checked={plainteData.delai === "0"}
            onChange={(e) => changePlainteData("delai", e.target.value)}
          />
        </div>
        
          <div className="col" style={{ display: displayme, flexDirection:"column" }}>
          <h6>
            Avez-vous obtenu une réponse de la part de votre opérateur ? *
          </h6>
          <>
          <Form.Check
            type="radio"
            label="Oui mais pas satisfait(e)"
            value="1"
            checked={plainteData.repondu === "1"}
            onChange={(e) => changePlainteData("repondu", e.target.value)}
          />
          <Form.Check
            type="radio"
            label="Non"
            value="0"
            checked={plainteData.repondu === "0"}
            onChange={(e) => changePlainteData("repondu", e.target.value)}
          />
          </>
        </div>
       
      </div>
     
     
      <div style={{ display: displayme }} className="row mt-4">
        <div className="col-4">
          <div className="mb-3">
            <Form.Label htmlFor="exampleFormControlInput1">
              Référence de la plainte chez l'opérateur :
            </Form.Label>
            <Form.Control
              type="text"
              id="exampleFormControlInput1"
              placeholder="Référence"
              value={plainteData.ref_plt}
              onChange={handleRefPlainteChange}
            />
            {/* {refPlainteError && (
              <span style={{ color: 'red', fontSize: '15px' }}>{refPlainteError}</span>
            )} */}
          </div>
        </div>

        {/* <div className="col-4">
          <Form.Label htmlFor="exampleFormControlInput1">
            Catégorie de la plainte *:
          </Form.Label>
          <Form.Select
            aria-label="Default select example"
            onChange={(e) => {
              validateSelect(e, setCatError, "id_type_plt");
              const selectedTypePlainte = typePlaintes.find(
                (operator) => operator.id_type_plt === parseInt(e.target.value)
              );


              changeDummyData("type_plainte", selectedTypePlainte.lib_type_plt);
            }
            }
          >
            <option>Choisir</option>
            {typePlaintes.map((item, key) => {
              let opt = <></>;
              if (plainteData.id_type_plt === item.id_type_plt) {
                opt = (
                  <option selected key={key} value={item.id_type_plt}>
                    {item.lib_type_plt}
                  </option>
                );
              } else {
                opt = (
                  <option key={key} value={item.id_type_plt}>
                    {item.lib_type_plt}
                  </option>
                );
              }
              return opt;
            })}
          </Form.Select>
          {catError && (
            <span style={{ color: 'red', fontSize: '15px' }}>{catError}</span>
          )}
        </div> */}

        <div className="col-4">
          <Form.Label htmlFor="exampleFormControlInput1">
            Opérateur concerné *:
          </Form.Label>
          <Form.Select
            onChange={(e) => {
              validateSelect(e, setTypeError, "id_operateur");
              const selectedOperator = operateurs.find(
                (operator) => operator.id_operateur === parseInt(e.target.value)
              );


              changeDummyData("nom_operateur", selectedOperator.nom_operateur);
            }
            }
          >
            <option>Choisir</option>
            {operateurs.map((item, key) => {
              let opt = <></>;
              if (plainteData.id_operateur === item.id_operateur) {
                opt = (
                  <option selected key={key} value={item.id_operateur}>
                    {item.nom_operateur}
                  </option>
                );
              } else {
                opt = (
                  <option key={key} value={item.id_operateur}>
                    {item.nom_operateur}
                  </option>
                );
              }
              return opt;
            })}
          </Form.Select>
          {typeError && (
            <span style={{ color: 'red', fontSize: '15px' }}>{typeError}</span>
          )}
        </div>
        <div className="col-4">
          <Form.Label htmlFor="exampleFormControlInput1">
            Problématique *:
          </Form.Label>
          <Form.Control
            type="text"
            value={plainteData.motif_plt}
            onChange={handleMotifPlainteChange}
            id="exampleFormControlInput1"
            placeholder="Motif"
          />
          {motifPlainteError && (
            <span style={{ color: 'red', fontSize: '15px' }}>{motifPlainteError}</span>
          )}
        </div>
      </div>
      <div style={{ display: displayme }} className="row">
        
        {/* <div className="col-5">
          <Form.Label htmlFor="exampleFormControlInput1">
            Service concerné *:
          </Form.Label>
         

          <Form.Select
            onChange={(e) => {
              validateSelect(e, setServiceError, "id_service");
              console.log(plainteData);
              const selectedService = services.find(
                (service) => service.id_service === parseInt(e.target.value)
              );
              changeDummyData("lib_service", selectedService.id_service);
            
            }}
          >
            <option>Choisir</option>
            {services?.map((item, key) => {
              let opt = <></>;
              if (plainteData.id_service == item.id_service) {
                opt = (
                  <option selected key={key} value={item.id_service}>
                    {item.lib_service}
                  </option>
                );
              } else {
                opt = (
                  <option key={key} value={item.id_service}>
                    {item.lib_service}
                  </option>
                );
              }
              return opt;
            })}
          </Form.Select>
          {serviceError && (
            <span style={{ color: 'red', fontSize: '15px' }}>{serviceError}</span>
          )}
        </div> */}
      </div>
     
    </div>
  );
};

export default Step0;
