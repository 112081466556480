import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Sidebar from "../../layouts/Sidebar";
import Stepper from "react-stepper-horizontal";
import { NavLink } from "react-router-dom";
import Step0 from "./Step0";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Spinner from "react-bootstrap/Spinner";
import LookAtPlaint from "./LookAtPlaint";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useSelector } from "react-redux";
import {
  useCreateTypePlainteMutation,
  useGetPlaintesQuery,
  useGetStatutPlaintesQuery,
  useGetTypePlaintesQuery,
  useCreatePlainteMutation,
  useUpdatePlainteMutation,
} from "../../redux/apiSlice/plainteApiSlice.js";
import VisualisationPlainte from "./VisualisationPlainte.js";
import { useGetOperateursQuery } from "../../redux/apiSlice/operateurApiSlice.js";
import { getUserInfo } from "../../redux/slice/userSlice";

export default function DepotPLaintes() {
  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);

  const [currentStep, setCurrentStep] = useState(0);
  //   const [formData, setFormData] = useState({

  //    step1: '',
  //    step2: '',
  //    step3: '',
  //    step4: '',

  //  });

  const user = useSelector(getUserInfo);

  const [createPlainte, { isLoading: loadCreatePlainte }] =
    useCreatePlainteMutation();
  const [updatePlainte, { isLoading: loadUpdatePlainte }] =
    useUpdatePlainteMutation();

  const {
    data: plaintes,
    refetch: refetchPlaintes,
    isLoading: loadPlaintes,
  } = useGetPlaintesQuery();
  const { data: typePlaintes = [], isLoading: loadTypePlaintes } =
    useGetTypePlaintesQuery();
  const { data: statutPlaintes = [], isLoading: loadStatutPlainte } =
    useGetStatutPlaintesQuery();
  const { data: operateurs = [], isLoading: loadOperateur } =
    useGetOperateursQuery();
  const [createTypePlainte, { isLoading: loadCreateTypePlainte }] =
    useCreateTypePlainteMutation();

  const [response, setResponse] = useState();

  const [dummyDatas, setDummyData] = useState({
    type_plainte: "",
    nom_operateur: "",

  })
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const initPlainte = {
    raison: "",
    nom_plt: "",
    prenom_plt: "",
    sexe: "",
    tel_plt: "",
    email_plt: "",
    adresse_plt: "",
    ref_plt: "",
    motif_plt: "",
    desc_plt: "",
    // id_type_plt: "",
    id_operateur: NaN,
    id_user: localStorage.getItem('user_id'),
    id_stat_plt: 1,
    // id_service: "",
    type_personne: "0",
    recours: "0",
    delai: "0",
    repondu: "",
    profession: "",
    nationalite: "",
    date_constat: null,
    canal_retour: "email",
    reponse: "-",
    images: [],
    currentStep: currentStep,
  };
  const [displayMe, setDisplayMe] = useState("flex");

  const [plainteData, setPlainteData] = useState({ ...initPlainte });

  let [disabled, setDisabled] = useState(true);
  const steps = [
    { title: "Identification de la plainte" },
    { title: "Identification du plaignant" },
    { title: "Information sur la plainte" },
    { title: "Prévisualiser la plainte" },
  ];

  useEffect(() => {
    //console.log(localStorage.getItem("token"));
    if (localStorage.getItem("token") === "" || !localStorage.getItem("token")) {
      window.location.href = "/";
    }
    if (plainteData.recours === "0" || plainteData.delai === "0" ) {
      //setDisabled(true);
      // setCurrentStep("disapear");
      setDisplayMe("none");
      //console.log("swich case1");
    } else if (plainteData.recours === "1" && plainteData.delai === "1") {
      //setDisabled(true);
      // setCurrentStep("disapear");
      setDisplayMe("flex");
      //console.log("swich case1");
    } else if (plainteData.recours === "2") {
      //console.log(currentStep);
      setDisplayMe("flex");
    } else {
      //console.log("swich case2");
      // setDisabled(true);
    }
  }, [plainteData, currentStep]);

  const checkDisable = () => {
    let result
    switch (currentStep) {
      case 0:
        if (
          
          !isNaN(plainteData.id_operateur) &&
          plainteData.recours !== "" &&
          plainteData.delai !== "" &&
          plainteData.motif_plt !== "" &&
          plainteData.repondu !== ""
        ) {
          result = false
        } else if (
         
          isNaN(plainteData.id_operateur) ||
          plainteData.ref_plt === "" ||
          plainteData.delai === "" ||
          plainteData.recours === "" ||
          plainteData.motif_plt === "" ||
          plainteData.repondu === ""
        ) {
          result = true

        }
        break;
      case 1:
        if (
          plainteData.type_personne !== "" &&
          plainteData.nom_plt !== "" &&
          plainteData.prenom_plt !== "" &&
          plainteData.sexe !== "" &&
          plainteData.tel_plt !== "" &&
          plainteData.nationalite !== "" &&
          plainteData.profession !== "" 
          
        ) {
          result = false
        } else if (
          plainteData.type_personne === "" ||
          plainteData.nom_plt === "" ||
          plainteData.prenom_plt === "" ||
          plainteData.sexe === "" ||
          plainteData.nationalite === "" ||
          plainteData.profession === "" ||
          plainteData.tel_plt === "" 
        ) {
          result = true
        }

        break;
      case 2:
        let imageslenght = plainteData.images.length;
        if (
          plainteData.desc_plt !== "" &&
          plainteData.canal_retour !== "" 
        
        ) {
          result = false
        } else if (
          plainteData.desc_plt === "" ||
          plainteData.canal_retour === "" 
         
        ) {
          result = true
        }
        break;
      default:
        result = true
        break;
    }

    return result
  }

  const handleNext = () => {
    //setDisabled(true);

    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const changePlainteData = (field, value) =>
    setPlainteData({ ...plainteData, [field]: value });

  const handlePrev = () => {
    changePlainteData('images', [])
    switch (currentStep) {
      case 0:
        setDisabled(false);
        break;
      case 1:
        setDisabled(false);
        break;
      case 2:
        setDisabled(false);
        break;
      case 3:
        setDisabled(true);
        break;
      default:
        setDisabled(true);
        break;
    }
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  async function showToast() {
    return new Promise((resolve) => {
      setTimeout(() => {
        toast.success("Plainte ajoutée avec succès.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        resolve();
      }, 0); // Délai minimal
    });
  }

  const crudPlainte = async () => {
    switch (currentStep) {
      case 3:
        const data = {
          ...plainteData,
          //images: plainteData.images.map((img) => img.value),
        }
        try {
          const formData = new FormData();
          Object.keys(plainteData).forEach((key) => {
            if (key !== "images") {
              formData.append(`${key}`, plainteData[key]);
            }
          });
          for (let i = 0; i < plainteData.images.length; i++) {
            formData.append("images", plainteData.images[i]);
          }

          // console.log("plainteData", plainteData)
          // console.log("formData", formData)

          //formData.append("type_personne", JSON.stringify(plainteData));
          const retour_plainte = await createPlainte(formData).unwrap();
          // console.log("retour_plainte_response", retour_plainte.response);
          refetchPlaintes();

          localStorage.setItem('plaintes', JSON.stringify({ liste: plaintes }))

          if (retour_plainte.data) {
          
              // window.location.href = "/depot-plainte";
              window.location.href = "/depot-plainte?register=true" ;

          }

          // if (retour_plainte.data) {
          //   toast.error("Erreur système. Veuillez réessayer.", {
          //     position: toast.POSITION.BOTTOM_RIGHT,
          //   });
            // window.location.href = "/depot-plainte"
          // }
        } catch (error) {

          // console.log("error", error)
  
          window.location.href = "/depot-plainte?register=true" ;
      
          // toast.error("Erreur système. Veuillez réessayer.", {
          //   position: toast.POSITION.BOTTOM_RIGHT,
          // });
          // console.log(error, 'ererer')
          // if (error.status) {
          //   console.error('Erreur HTTP :', error.status);
          // } else {
          //   console.error('Erreur lors de la requête :', error.message);
          // }
        }
        break;

      default:
        setCurrentStep(0);
        break;
    }
  };
  //   const handleChange = (e) => {
  //    const { name, value } = e.target;
  //    setFormData({ ...formData, [name]: value });
  //  };

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <NavLink to="/depot-plainte">Soummission de plainte</NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Plaintes
              </li>
            </ol>
            <h4 className="main-title mb-0">Dépot de plaintes</h4>
          </div>
        </div>

        <div className="container ">
          <Stepper steps={steps} activeStep={currentStep} />

          <div>
            {/* {currentStep === "disapear" && <div></div>} */}
            {currentStep === 0 && (
              <Step0
                dummyDatas={dummyDatas}
                setDummyData={setDummyData}
                displayme={displayMe}
                plainteData={plainteData}
                setPlainteData={setPlainteData}
                enableOrDisable={
                  setDisabled
                }
              />
            )}
            {currentStep === 1 && (
              <Step1
                plainteData={plainteData}
                setPlainteData={setPlainteData}
                enableOrDisable={
                  setDisabled
                }
              />
            )}
            {currentStep === 2 && (
              <Step2
                plainteData={plainteData}
                setPlainteData={setPlainteData}
                enableOrDisable={
                  setDisabled
                }
              />
            )}
            {currentStep === 3 && (
              /*  */
              <>
              <VisualisationPlainte
                dummyDatas={dummyDatas}
                plainteData={plainteData}
               
              />
              <LookAtPlaint
                dummyDatas={dummyDatas}
                plainteData={plainteData}
                enableOrDisable={
                  setDisabled
                }
              />
              </>

            )}
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col d-flex justify-content-center align-items-center">
              {currentStep > 0 && (
                <Button onClick={handlePrev} variant="primary">
                  Précédent
                </Button>
              )}
            </div>
            <div className="col"></div>

            <div className="col d-flex justify-content-center align-items-center">
              {currentStep < steps.length - 1 && (
                <div style={{ display: displayMe }}>
                  <Button
                  onClick={handleNext}
                  variant="success"
                  disabled={checkDisable()}
                >
                  Suivant
                </Button></div>
              )}
              {currentStep === 3 && (
                <Button variant="success" onClick={() => crudPlainte()}>
                  {loadCreatePlainte || loadUpdatePlainte ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    <span>Valider la plainte</span>
                  )}
                </Button>
              )}
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </React.Fragment>
  );
}
