import { createSlice } from "@reduxjs/toolkit";
import { operateurApiSlice } from "../apiSlice/operateurApiSlice";

const operateurSlice = createSlice({
    name: 'operateur',
    initialState: {
        liste: []
    },
    extraReducers:  builder => {
        builder.addMatcher(operateurApiSlice.endpoints.getOperateurs.matchFulfilled, (state, { payload }) => {
            state.liste = payload

        })
    }
})


export default operateurSlice.reducer

export const getOperateurs = state => state.operateur.liste