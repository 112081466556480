import { apiSlice } from "../api/apiSlice"

export const obligationApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getObligationObservation: builder.query({
            query: id => ({
                url: `/obligation/get_observation_obligation/${id}`,
                method: "GET",
            }),
            providesTags: (result) => {
                return result ? [
                    { type: 'ObligationObservation', id: result.id_obs },
                ] : ['ObligationObservation']
            }
        }),

        getObligations: builder.query({
            query: () => ({
                url: '/obligation',
                method: "GET",
            }),
            providesTags: (result) => {
                return result ? [
                    { type: 'Obligations', id: 'LIST' },
                    ...result.map(item => ({ type: 'Obligations', id: item.id_obligation_reg }))
                ] : ['Obligations']
            }
        }),

        
        getByPkObligations: builder.query({
            query: (id) => ({
                url: `/obligation/get_by_pk/${id}`,
                method: "GET",
            }),
          providesTags: (result) => {
    return result ? [
        { type: 'Obligations', id: 'LIST' },
        { type: 'Obligations', id: result.id_obligation_reg }
    ] : ['Obligations'];
}

        }),

        createObligation: builder.mutation({
            query: body => ({
                url: '/obligation/create',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Obligations']
        }),

        updateObligation: builder.mutation({
            query: body => ({
                url: `/obligation/update/${body.id_obligation_reg}`,
                method: 'PUT',
                body
            }),
            invalidatesTags: ['Obligations']
        }),

        // deleteObligation: builder.mutation({
        //     query: body => ({
        //         url: `/obligation/update/${body.id_obligation_reg}`,
        //         method: 'PUT',
        //         body
        //     }),
        //     invalidatesTags: ['Obligations']
        // }),

      
    })
})

export const {
    useGetObligationsQuery,
    useGetByPkObligationsQuery,
    useCreateObligationMutation,
    useUpdateObligationMutation,
    useGetObligationObservationQuery
} = obligationApiSlice