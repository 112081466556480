import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
console.log("REACT_API:", process.env.REACT_APP_API);
export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({
    // baseUrl: process.env.REACT_APP_API,
    // baseUrl: "http://localhost:3200/pigru/",
    baseUrl: "https://apipigru.leavesmartconsulting.com/pigru/",
    prepareHeaders: (headers, { getState }) => {
      const user = getState().user.info;
      //console.log(user);
      if (user?.token) {
        localStorage.setItem("token", user?.token);
      }
      headers.set("authorization", `Bearer ${localStorage.getItem("token")}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({}),
  tagTypes: [
    "Plaintes",
    "TypePlaintes",
    "StatutPlaintes",
    "Operateurs",
    "Direction",
    "Periodicities",
    "TypeObligations",
    "TypeOperateurs",
    "CategorieObligations",
    "Echeances",
    "Obligations",
    "ObligationObservation",
    "ObservationPlainte",
    "ResObservation",
    "RapportPlainte",
    "Notifications",
    "Services",
    "ObligationsOperateur",
    "ObligationsElementCtrl",
    "Plateforme",
    "Prestation",
    "CategoriePrestation",
    "SuiviObligations",
    "CategorieFaq",
    "Faq",
    "Modules",
    "fonctionnalites",
    "User",
    "Tranches",
    "SousTranches",
    "ServiceTranches",
    "AttribuerServiceTranches",
    "RessourcesDispoTranche",
    "Clients",
    "Verdicte",
    "Ressources",
    "RessourcesDispoSousTranche",
    'RessourcesDispoService',
    "Occupants",
    "sercices-plaintes",
    "directions-plaintes",
    "Profil",
    "Notify_plainte",
    "Avis_plainte",
    "TypeRequete",
    "avisConseil",
    "SecteurObligations",
    "Ussd",
    "UssdListe",
    "Statut_num",
    "AttrUssd",
    "Occuper",
    "AttrMnc",
    "AttrIspc",
    "Mnc",
    "Ispc",
    "Stat_sen",
    "CompoLot",
    "AttrCompoLot"
  ],
});
