import React, { useEffect, useRef, useState } from "react";
import { Button, FormControl, Form, Modal } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";

import {
    useUpdatePlainteStatutMutation,
} from "../../redux/apiSlice/plainteApiSlice.js";
export default function SoumettreRapport({
    display4,
    handleClose4,
    id_plt,
}) {
    const [editorContent, setEditorContent] = useState("");
    const [updatePlainteStatut, { isLoading: loadUpdateState }] =
        useUpdatePlainteStatutMutation();
    const [files, setFiles] = useState([]);
    const formData = new FormData();
    const handleFileChange = async (e) => {
        const selectedFiles = e.target.files;

        console.log(selectedFiles);
        setFiles(selectedFiles);
        // changePlainteData("images", selectedFiles);
    };
    const handleChange = (event, editor) => {
        const content = editor.getData();
        setEditorContent(content);
        console.log(content);
    };

    const editorRef = useRef();

    useEffect(() => {
        if (editorRef.current) {
            const editor = editorRef.current.editor;

            // Access the CKEditor instance
            console.log("Editor is ready to use!", editor);

            return () => {
                editor.destroy();
            };
        }
    }, []);
    let [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if (files.length === 0) {
            setDisabled(true);
        } else if (files.length > 0) {
            setDisabled(false);
        }
    }, [files, editorContent]);

    const upload = () => {
        for (let i = 0; i < files.length; i++) {
            formData.append("images", files[i]);
        }
        formData.append("id_plt", id_plt);

        try {
            Swal.fire({
                title: "Action irréversible !",
                text: "Cette action est irréversible !",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui", cancelButtonText: "Non",
            }).then(async (result) => {
                if (result.isConfirmed === true) {

                    const retour_plainte2 = await updatePlainteStatut({
                        id_stat_plt: 10,
                        id_plt: id_plt,
                    }).unwrap();

                    if (retour_plainte2) {
                        window.location.href = "/toutes_les_plaintes";
                    }

                }
            });

            // refetchPlaintes();
        } catch (error) {
            console.error("Error creating obs state:", error);
        }
    };

    return (
        <div class="container">
            <Modal
                show={display4}
                onHide={handleClose4}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Rapport de plainte</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="mb-2">

                                    <Form.Group controlId="formBasicText">
                                        <Form.Label>Fichier(s)</Form.Label>
                                        <Form.Control
                                            onChange={handleFileChange}
                                            type="file"
                                            id="exampleFormControlInput1"
                                            placeholder=""
                                            name="images"
                                            multiple
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={disabled} onClick={upload} variant="success">
                        {" "}
                        {loadUpdateState ? (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        ) : (
                            <span>Valider </span>
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
