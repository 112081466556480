import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import {  Tooltip as Tol, OverlayTrigger } from "react-bootstrap";

export default function TableListePlaintes(props) {
  const [plaintesFront, setPlaintFront] = useState([]);
  const { plaintes, setPlainteData } = props;


  const [products, setProducts] = useState([

  ]);
  const formatDate = (timestamp) => {

    const date = new Date(timestamp);

    if (isNaN(date.getTime())) {
        return "Date invalide";
    }

    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();


    return formattedDate;
};




  useEffect(() => {

    const tab = plaintes?.liste.filter((item)=>(item.id_type_plt != null && item.id_service != null))

    const updatedPlaintesFront = tab.map((plainte) => {
      
      const timestamp = typeof plainte.date_plt === 'string' ? parseInt(plainte.date_plt, 10) : plainte.date_plt;
  
      // Utilisez le nom correct pour le timestamp
      const dat = formatDate(timestamp);
  
      return {
          code: plainte?.num_suivi_plt,
          cat: plainte?.Type_plainte?.lib_type_plt,
          op: plainte?.Operateur?.nom_operateur,
          dt: dat,
          // dt: plainte.date_constat,
          pl: plainte?.prenom_plt,
          ref: plainte?.ref_plt,
          st: plainte?.Statut_plainte?.libelle,
          cd: plainte?.Statut_plainte?.id_stat_plt,
          actions: true,
      };
  });
  
    setProducts(updatedPlaintesFront);

   
  }, [plaintes?.liste]);
  const [globalFilter, setGlobalFilter] = useState("");
  const dt = useRef(null);

  const cols = [
    { field: "code", header: "Code", sortable: true },
    { field: "cat", header: "Catégorie", sortable: true },
    { field: "op", header: "Opérateur", sortable: true },
    { field: "dt", header: "Date de soumission", sortable: true },
    { field: "pl", header: "identité du plaignant", sortable: true },
    { field: "ref", header: "Réf. opérateur", sortable: true },
    { field: "st", header: "statut", sortable: true },
    { field: "actions", header: "Actions", sortable: false }, // Nouvelle colonne pour les actions
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));


  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);

        doc.autoTable(exportColumns, products);
        doc.save("plainte.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(products);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "plainte");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const getStatusClassName = (status) => {
    switch (status) {
      case 1:
        return "bg-danger";
      case 2:
        return "bg-warning";
      case 3:
        return "bg-primary";
      case 4:
        return "bg-secondary";
      case 5:
        return "bg-info";
      case 6:
        return "bg-dark";
      case 7:
        return "bg-success";
      case 8:
        return "bg-secondary";
      case 9:
        return "bg-dark";
      case 10:
        return "bg-info";
      case 11:
        return "bg-primary";
      default:
        return "bg-success";
    }
  };


  const getLabel = (status) => {
    switch (status) {
      case 1:
        return "Plainte Soumise";
      case 2:
        return "Plainte Réceptionnée";
      case 3:
        return "Plainte Classée";
      case 4:
        return "En Complétude D'information";
      case 5:
        return "Plainte Instruite";
      case 6:
        return "Plainte En Investigation";
      case 7:
        return "Plainte En Résultat D'investigation";
      case 8:
        return "Plainte En Rapport D'investigation";
      case 9:
        return "Plainte En Avis de Règlement";
      case 10:
        return "Plainte Traitée";
      case 11:
        return "Plainte Traitée";
      default:
        return "Plainte Classée";
    }
  };

  const statusBodyTemplate = (rowData) => {
    const statusClassName = getStatusClassName(rowData.cd);
    const label = getLabel(rowData.cd);

    return <span className={`badge ${statusClassName}`}>{label}</span>;
  };

  const header = (
    <div className="container_header_data_table">
      <div className="flex align-items-center">
        <div className="p-input-icon-left ml-3">
          <i className="pi pi-search" />
          <InputText
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search"
          />
        </div>
      </div>
      <div className="container_export">
        <Button
          type="button"
          icon="pi pi-file-pdf"
          rounded
          onClick={exportPdf}
          className=""
          data-pr-tooltip="PDF"
          style={{ borderRadius: 30 }}
          severity="secondary"
        />
        <Button
          type="button"
          icon="pi pi-file-excel"
          className=""
          severity="success"
          rounded
          onClick={exportExcel}
          data-pr-tooltip="Excel"
          style={{ borderRadius: 30 }}
        />
        <Button
          type="button"
          icon="pi pi-file"
          rounded
          onClick={() => exportCSV(false)}
          className=""
          data-pr-tooltip="CSV"
          style={{ borderRadius: 30 }}
        />
      </div>
    </div>
  );

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center">

<OverlayTrigger
                  
                  placement={"top"}
                  overlay={
                    <Tol>
                      Détails plainte.
                    </Tol>
                  }
                >
  
    <a onClick={() => handleEdit(rowData)} class="badge bg-success p-2 mr-1" style={{ borderRadius: 30, cursor: "pointer" }}>
      <i class="ri-eye-line  ri-2x"></i><span>
        </span>
        </a>
        </OverlayTrigger>
        
<OverlayTrigger
                  
                  placement={"top"}
                  overlay={
                    <Tol>
                      Archiver
                    </Tol>
                  }
                >
        <a onClick={() => handleDelete(rowData)} class="badge bg-danger p-2 " style={{ borderRadius: 30 , cursor: "pointer"}}><i class="ri-archive-line  ri-2x"></i><span></span></a>
        </OverlayTrigger>
    
      </div>
    );
  };

  const handleEdit = (rowData) => {
    const clickedCode = rowData.code;
    const selectedComplaint = plaintes.liste.find(
      (complaint) => complaint.num_suivi_plt === clickedCode
    );

    if (selectedComplaint) {
      props.handleshow(selectedComplaint)

    }

  };

  const handleDelete = (rowData) => {
    props.handleShow1()
  };

  const rowClassName = (rowData, index) => {
    return index !== products.length - 1 ? "table-row" : ""; // Applique la classe 'table-row' à toutes les lignes sauf la dernière
  };

  return (
    <div className="card" style={{ width: "100%" }}>
      <Tooltip target=".export-buttons>button" position="bottom" />

      <style>{`
            .table-row td {
                border-bottom: 1px solid #dee2e6; // Bordure inférieure pour les cellules de la ligne
            }
        `}</style>

      <DataTable
        ref={dt}
        value={products}
        header={header}
        tableStyle={{ minWidth: "100%", fontSize: 12 }}
        globalFilter={globalFilter}
        emptyMessage="Pas de résultat."
        paginator
        rows={7}
        rowClassName={rowClassName} // Applique la classe spécifiée aux lignes
      >
        {cols.map((col, index) => {
          if (col.field === "st") {
            return (
              <Column
                key={index}
                field={col.field}
                header={col.header}
                sortable={col.sortable}
                body={statusBodyTemplate}
              />
            );
          } else if (col.field === "actions") {
            return (
              <Column
                key={index}
                field={col.field}
                header={col.header}
                sortable={col.sortable}
                body={actionBodyTemplate}
              />
            );
          } else {
            return (
              <Column
                key={index}
                field={col.field}
                header={col.header}
                sortable={col.sortable}
              />
            );
          }
        })}
      </DataTable>
    </div>
  );
}
