import React, { useState } from "react";
import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import {
  useUpdatePlainteStatutMutation,
  useCreateNotificationStepChangingMutation,
  useGetDecisionPlainteQuery,
  useCreateVerdicteMutation,
  useUpdateVerdicteMutation,
  useGetVerdicteParPlainteQuery,
  useGetResultatObsQuery,
  useCreateAvisMutation,
  useUpdatePlainteMutation
} from "../../redux/apiSlice/plainteApiSlice.js";
import Swal from "sweetalert2";

import { useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import {
  getStatutPlaintes,
  getTypePlaintes,
} from "../../redux/slice/plainteSlice";
import { divIcon } from "leaflet";
import { isEmpty } from "lodash";

export default function StepsOfComplain({
  motifDeRefus,
  instruirePlainte,
  soumettreRapport,
  Genererrapport,
  setTheshouldIDisplay,
  complainStatus,
  complainId,
  resultatDesInvestigations,
  
}) {
  const statutPlaintes = useSelector(getStatutPlaintes);
  const [button1, setButton1] = useState(false);
  const [button2, setButton2] = useState(false);
  const [button3, setButton3] = useState(false);

  const [updatePlainteStatut, { isLoading: loadUpdateState }] = useUpdatePlainteStatutMutation();

  const [sendNotification, { isLoading: loadNotif }] =
    useCreateNotificationStepChangingMutation();

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const [updatePlainte, { isLoading: loadUpdatePlainte }] =
  useUpdatePlainteMutation();
  const [updateVerdicte] = useUpdateVerdicteMutation();
  const [createAvis] = useCreateAvisMutation();
  const { data: lesDecision } = useGetDecisionPlainteQuery();
  const { data: repererVerdictePlainte, } = useGetVerdicteParPlainteQuery({id_plt:complainId});
  const {data: resultatInvestigation = {}} = useGetResultatObsQuery(complainId, {skip: !complainId})
  
  const [uneAction, setUneAction] = useState(0);
  const [commentaire, setCommentaire] = useState({});
  const [avis, setAvis] = useState({});
  const handleClose = () => {setShow(false);};
  const handleShow = () => {setShow(true)};
  const handleCloseCloture2 = () => {setShow2(false);};
  const handleShowCloture2 = () => {setShow2(true)};



  const checkAvis = () => {
    
    try {
      Swal.fire({
        title: "Action irréversible !",
        text: "Cette action est irréversible !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui", cancelButtonText: "Non",
      }).then(async (result) => {
        if (result.isConfirmed === true) {

          if (avis?.text) {

            const data = {
              avis : avis?.text ,
              id_plt: complainId,
            }
            // console.log("datadata", data)
  
           const retour = await updatePlainte(data).unwrap()
  
            if (retour) {
              window.location.href = "/toutes_les_plaintes";
            }

          } else {
          

            handleCloseCloture2()
            Swal.fire({
        title: "Attention!!!",
        text: "Commentaire vide non autorisé",
        icon: "error",
       
      })


          }

         
        }
      });

      // refetchPlaintes();
    } catch (error) {
      console.error("Error avis:", error);
    }
  };

  const coment =(data)=>{
    handleClose()
    try {
   
      Swal.fire({
        title: "Action irréversible !",
        text: "Cette action est irréversible !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui", cancelButtonText: "Non",
      }).then(async (result) => {
        if (result.isConfirmed === true) {
// console.log("data", data)
          const retour_plainte = await updatePlainteStatut({
            id_stat_plt: data?.complainStatusId,
            id_plt: data?.mycomplainId,
          }).unwrap();
        
          if (retour_plainte) {

            const retour_avis = await createAvis({
              content: commentaire?.text,
              id_plt: data?.mycomplainId,
            }).unwrap();

if (retour_avis) {
  sendNotification({
    nouveau_status: statutPlaintes.find(
      (etape) => etape.id_stat_plt === data?.complainStatusId
    ),
  });
  window.location.href = "/toutes_les_plaintes";
}

            
          }
        }
      });

      // refetchPlaintes();
    } catch (error) {
      console.error("Error updating complaint state:", error);
    }
  }

  const updateComplainState = async (complainStatusId, mycomplainId) => {
   if (complainStatusId === 10) {

    setCommentaire({...commentaire, complainStatusId, mycomplainId})
    handleShow()
   } else {
    try {

      Swal.fire({
        title: "Action irréversible !",
        text: "Cette action est irréversible !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui", cancelButtonText: "Non",
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          const retour_plainte = await updatePlainteStatut({
            id_stat_plt: complainStatusId,
            id_plt: mycomplainId,
          }).unwrap();
        
          if (retour_plainte) {
            sendNotification({
              nouveau_status: statutPlaintes.find(
                (etape) => etape.id_stat_plt === complainStatusId
              ),
            });
            window.location.href = "/toutes_les_plaintes";
          }
        }
      });

      // refetchPlaintes();
    } catch (error) {
      console.error("Error updating complaint state:", error);
    }
   }
  };

  const soustitreStyle = {
    fontSize: "1.7em", // Taille de la police
    fontWeight: "bolder", // Gras
    // Couleur du texte (par exemple, bleu Bootstrap)
    // Ajoutez d'autres propriétés de style au besoin
  };

  return (
    <div className="container">
      <div className="row">
        {statutPlaintes.map((item, key) => {
          let buttonState = <></>;
          let selectTedPlainte;
          let selectTedPlainte2;
          if (complainStatus === item.id_stat_plt) {
            if (complainStatus === 1) {
              buttonState = (
                <Button
                  onClick={() => {
                    updateComplainState(2, complainId);
                  }}
                  variant="success"
                >
                  {loadUpdateState ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    <span>Accuser réception de la plainte</span>
                  )}
                </Button>
              );
            } else if (complainStatus === 2) {
              buttonState = (
                <div class="row">
                  <div className="col-4">
                    {" "}
                    <Button
                      onClick={() => {
                        setButton1(true);
                        setButton2(false);
                        setButton3(false);

                        updateComplainState(3, complainId);
                      }}
                      variant="danger"
                    >
                      {loadUpdateState && button1 ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <span>Classer la plainte</span>
                      )}
                    </Button>
                  </div>
                  <div className="col-4 text-center">
                    {" "}
                    <Button
                      onClick={() => {
                        setButton1(false);
                        setButton2(true);
                        setButton3(false);

                        setTheshouldIDisplay();
                        // updateComplainState(complainStatus + 2, complainId)
                      }}
                      variant="success"
                    >
                      {loadUpdateState && button2 ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <span>Compléter des informations</span>
                      )}
                    </Button>
                  </div>
                  <div className="col-4 text-right">
                    {" "}
                    <Button
                      onClick={() => {
                        setButton1(false);
                        setButton2(false);
                        setButton3(true);

                        instruirePlainte();
                      }}
                    >
                      {loadUpdateState && button3 ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <span>Instruire la plainte</span>
                      )}
                    </Button>
                  </div>
                </div>
              );
            } else if (complainStatus === 3) {
              buttonState = (
                <h1 style={{ color: "red", fontSize: "14px" }}>
                  Cette plainte a déjà été classée
                </h1>
              );
            } else if (complainStatus === 4) {
              buttonState = (
                <div className="row">
                  <div className="col-6">
                    <Button
                      onClick={() => {
                        setButton1(true);
                        setButton2(false);
                        setTheshouldIDisplay();
                      }}
                      variant="success"
                    >
                      {loadUpdateState && button1 ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <span>Compléter des informations</span>
                      )}
                    </Button>
                  </div>

                  <div className="col-6 text-right" >
                    <Button
                      onClick={() => {
                        setButton1(true);
                        setButton2(false);
                        instruirePlainte();
                      }}
                      variant="success"
                    >
                      {loadUpdateState && button2 ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <span> Instruire la plainte </span>
                      )}
                    </Button>
                  </div>
                </div>
              );
            } else if (complainStatus === 5) {
              buttonState = (
                <Button
                  onClick={() => {
                    setButton1(true);
                    setButton2(false);
                    updateComplainState(6, complainId);
                  }}
                  variant="success"
                >
                  {loadUpdateState ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    <span> Démarrer l'investigation </span>
                  )}
                </Button>
              );
            } else if (complainStatus === 6) {
              buttonState = (
                <div className="row">
                  <div className="col-6">
                    <Button
                      onClick={() => {
                        setButton1(true);
                        setButton2(false);
                        resultatDesInvestigations();
                      }}
                      variant="success"
                    >
                      {loadUpdateState ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <span> Entrer le résultat d'investigation </span>
                      )}
                    </Button>
                  </div>

                  {!isEmpty(resultatInvestigation) && 
                    <div className="col-6 text-right">
                      <Button
                        onClick={() => {
                          setButton1(false);
                          setButton2(true);
                          updateComplainState(7, complainId);
                        }}
                        variant="success"
                      >
                        {loadUpdateState && button2 ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          <span> Valider le résultat d'investigation </span>
                        )}
                      </Button>
                    </div>
                  }

                </div>
              );
            } else if (complainStatus === 7) {
              buttonState = (
                <div className="row">
                  <div className="col-6">
                    <Button
                      onClick={() => {
                        setButton1(true);
                        setButton2(true);
                        Genererrapport();
                      }}
                      variant="success"
                    >
                      {loadUpdateState && button1 ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <span> Proposer une action</span>
                      )}
                    </Button>
                  </div>

                  <div className="col-6 text-right">
                    <Button
                      onClick={() => {
                        setButton1(false);
                        setButton2(true);
                        motifDeRefus();
                      }}
                      variant="success"
                    >
                      {loadUpdateState && button2 ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <span> Repasser en étape d'investigation</span>
                      )}
                    </Button>
                  </div>
                </div>
              );
            } else if (complainStatus === 8) {
              buttonState = (
                <Button
                  onClick={() => {
                    updateComplainState(9, complainId);
                  }}
                  variant="success"
                >
                  {loadUpdateState ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    <span> Passer en étape de règlement </span>
                  )}
                </Button>
              );
            } else if (complainStatus === 9) {
              buttonState = (
                <div className="d-flex flex-row justify-content-around">
                  <Button
                  onClick={()=> Genererrapport()}
                  >
                    Modifier Action
                  </Button>
                  <Button
                    onClick={() => {
                      setButton1(false);
                      setButton2(true);
                      updateComplainState(10, complainId);
                    }}
                    variant="success"
                  >
                    {loadUpdateState ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      <span> Clôturer la plainte </span>
                    )}
                  </Button>
                </div>
              );
            } else if (complainStatus === 10) {
              buttonState = <Button onClick={()=>handleShowCloture2()} class="text-center " variant="secondary"  style={soustitreStyle}>Avis de satisfaction</Button>;
            } else {
              buttonState = <Button onClick={()=>handleShowCloture2()} class="text-center" variant="secondary" style={soustitreStyle}>Avis de satisfaction</Button>;
            }
          }

          return buttonState;
        })}
      </div>

      <Modal show={show} onHide={handleClose} size="lg" className="bg-secondary" >
        <Modal.Header closeButton>
          <Modal.Title>Commentaires</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="g-2">
            <Col >
              {/* <Form.Label htmlFor="uneTranche">Action*:</Form.Label> */}

              <Form.Control
            onChange={(v)=>setCommentaire({...commentaire, text :v.target.value })}
            as="textarea"
            value={commentaire.text}
            id="cmt"
            rows="5"
            placeholder="Entrer votre commentaire..."
          ></Form.Control>
            </Col>
          
          </Row>
          <Row>
          <Col className="text-end">
              <Button variant="success" onClick={()=>coment(commentaire)}>
                Valider
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      {/* ---------------------Avis----------------------------- */}
      <Modal show={show2} onHide={handleCloseCloture2} size="lg" className="bg-secondary" >
        <Modal.Header closeButton>
          <Modal.Title>Avis de satisfaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="g-2">
            <Col >
              {/* <Form.Label htmlFor="uneTranche">Action*:</Form.Label> */}

              <Form.Control
            onChange={(v)=>setAvis({...avis, text :v.target.value })}
            as="textarea"
            value={avis.text}
            id="cmt"
            rows="5"
            placeholder="Entrer votre avis..."
          ></Form.Control>
            </Col>
          
          </Row>
          <Row>
          <Col className="text-end">
              <Button variant="success" onClick={()=>checkAvis()}>
                Valider
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
}
