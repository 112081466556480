import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Sidebar from "../../layouts/Sidebar";
import { NavLink } from "react-router-dom";
import TableQualification from "./dataTables/TableQualification.js";
import { useSelector } from "react-redux";
import StepsOfComplain from "./StepsOfComplain";
import { getUserInfo } from "../../redux/slice/userSlice";
import Spinner from "react-bootstrap/Spinner";
import ComplementInformation from "./ComplementInformation";
import ResultatDesInvestigations from "./ResultatDesInvestigations";
import SoumettreRapport from "./SoumettreRapport";
import InstruirePlainte from "./InstruirePlainte";
import MotifDeRupture from "./MotifDeRefus";
import TableDetailsQualification from "./TableDetailsQualification.js";
import Swal from "sweetalert2";
import RapportViewer from "./RapportViewer";
import Verdict from "./Verdicts/Verdicts";

import {
  useGetServicesQuery,
  useCreateTypePlainteMutation,
  useGetPlaintesQuery,
  useGetStatutPlaintesQuery,
  useGetTypePlaintesQuery,
  useUpdatePlainteMutation,
  useGetTypeRequeteQuery,
  useGetCanauxReceptionQuery
 
} from "../../redux/apiSlice/plainteApiSlice.js";
import { useGetOperateursQuery } from "../../redux/apiSlice/operateurApiSlice.js";
import Historique from "./Historique/Historique";
import Observation from "./Observation/Observation";

export default function Qualification() {

  const [updatePlainte, { isLoading: loadUpdatePlainte }] =
    useUpdatePlainteMutation();

   

  const [resultatDesInvestigations, setresultatDesInvestigations] =
    useState(false);
  const {
    data: plaintes,
    refetch: refetchPlaintes,
    isLoading: loadPlaintes,
    isSuccess: isSuccessPlaintes,
  } = useGetPlaintesQuery();

  const { data: typePlaintes = [], isLoading: loadTypePlaintes } =
    useGetTypePlaintesQuery();

  const { data: typeRequete = [], isLoading: loadTypeRequete } =
    useGetTypeRequeteQuery();
  //  console.log("typeRequete", typeRequete)

  const { data: statutPlaintes, isLoading: loadStatutPlainte } =
    useGetStatutPlaintesQuery();
  const { data: operateurs = [], isLoading: loadOperateur } =
    useGetOperateursQuery();
  const [createTypePlainte, { isLoading: loadCreateTypePlainte }] =
    useCreateTypePlainteMutation();

    const {
      data: services,
      refetch: refetchServices,
      isLoading: loadServices,
      isSuccess: isSuccessServices
    } = useGetServicesQuery();
 
    const {
      data: canaux,
      refetch: refetchCanaux,
      isLoading: loadCanaux,
      isSuccess: isSuccessCanaux
    } = useGetCanauxReceptionQuery();
//  console.log("canaux", canaux)
  // modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  
  const [mycomplaint, setComplaint] = useState();

  const [etatReq, setEtatReq] = useState(false);
  const [btnD, setBtnD] = useState(true);
  
    const init = {
      id_type_req : "",
      id_type_plt : "",
      id_service : "",
      id_canal : "",
    }
    const handleShow = (complaint) => {
      setComplaint(complaint);
      setShow(true);
    };
  
  const [qualif, setQualif] = useState({...init});

// console.log("qualif", qualif)
  const changeQualif =(field, value)=>{

    if (field == "id_type_req" && value == 2 ) {
      setEtatReq(true)
      setQualif({...qualif, [field] : value})

    } else if (field == "id_type_plt" || field == "id_service" || field == "id_canal") {
      setEtatReq(true)
      setQualif({...qualif, [field] : value})
    } else {
      setEtatReq(false)
      setQualif({...qualif, [field] : value})
    }

  }
  




 
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const user = useSelector(getUserInfo);

  

const save = async () => {
  Swal.fire({
      title: "Qualification",
      text: "Voulez-vous continuer ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Non"
  }).then(async (result) => {
 
      try {
          if (result.isConfirmed === true) {
            //  console.log("qualif2", qualif)

             let data 

             if (etatReq == true) {
              data ={
                id_plt : mycomplaint?.id_plt,
                id_type_req : parseInt(qualif.id_type_req),
                id_type_plt : parseInt(qualif.id_type_plt),
                id_service : parseInt(qualif.id_service),
                id_canal : parseInt(qualif.id_canal),
                
              
                             }
             } else {
              data ={
                id_plt : mycomplaint?.id_plt,
                id_type_req : parseInt(qualif.id_type_req),
                             }
             }

            //  console.log("data", data)
                  await updatePlainte(data).unwrap()
    
  
              Swal.fire({
                  title: "Succes !!!",
                  text: `Qualification réussie!`,
                  icon: "success"
              });
              setQualif({...init})
              window.location.href = "/qualification"
          
          }
      } catch (error) {
          // console.log('error', error)
          Swal.fire({
            title: "Attention!",
            text: `Une erreur s'est produite ! Veuillez réessayer`,
            icon: "error"
        });
       
        }
          
     
  });
}
// console.log("etatReq", etatReq)
const checkDisable = () => {
 let result 
  switch (etatReq) {
    case true:
     
    if (
      qualif.id_type_req !== "" &&
      qualif.id_type_plt !== "" &&
      qualif.id_service !== "" &&
      qualif.id_canal !== "" 
    ) {
      result = false
    } else if (
     
      qualif.id_type_req == "" ||
      qualif.id_type_plt == "" ||
      qualif.id_service == "" ||
      qualif.id_canal == "" 
    ) {
      result = true

    }
      break;
    case false:

    if (
      qualif.id_type_req !== "" 
    ) {
    
      result = false
    } else if (
      qualif.id_type_req == "" 
    ) {
     
      result = true

    }

      break;
   
    default:
     
      break;
  }

  return result
}

// console.log("checkDisable", checkDisable())

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  useEffect(() => {
    refetchPlaintes();
    // console.log(plaintes);
    // console.log(statutPlaintes);
    // console.log(localStorage.getItem("token"));
    if (localStorage.getItem("token") === "") {
      window.location.href = "/";
    }
    // console.log(retour_plainte);
  }, []);


  useEffect(() => {
    // console.log("isSuccessPlaintes", isSuccessPlaintes)
    if (isSuccessPlaintes) {
      // console.log("plaintes", plaintes)
      // Data is available, and you can proceed to use it
      const selectedItems = plaintes;
      // Additional logic or state updates
      //setAccuse({ liste: selectedItems })
      localStorage.setItem(
        "plaintes",
        JSON.stringify({ liste: selectedItems })
      );
    }
  }, [isSuccessPlaintes, plaintes]);

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  if (loadPlaintes) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // 100% of the viewport height
        }}
      >
        <Spinner
          as="span"
          animation="border"
          size="md"
          role="status"
          aria-hidden="true"
        />
      </div>
    );
  }

  if (!plaintes) {
    return <div>Error fetching data</div>;
  }

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <NavLink to="/dashboard">Dashboard</NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Liste des requêtes
              </li>
            </ol>
            <h4 className="main-title mb-0">Liste des requêtes</h4>
          </div>
        </div>

        <div>
          <TableQualification
            handleclose={handleClose}
            handleshow={handleShow}
            plaintes={plaintes}
          />
        </div>
        {/* ----------------Modal de détails----------- */}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title className="w-100 text-center" style={{fontSize : 30, color: "#000",}}>Détails requête</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              
            </div>
            <TableDetailsQualification mycomplaint={mycomplaint} />
           
          </Modal.Body>
          <Modal.Footer>
          <div className="container row ">
            <h2>Qualification</h2>
            </div>
            <div className="container row">
<div className="col">
<Form.Label htmlFor="exampleFormControlInput1">
            Catégorie requête:
          </Form.Label>
         

          <Form.Select
         onChange={(e)=>changeQualif("id_type_req", e.target.value)}
          >
            <option>Choisir</option>
            {typeRequete?.map((item, key) => {
 
             return    ( <option key={key} value={item.id_type_req}>
                    {item.lib_type_req}
                  </option>)
                
            })}
          </Form.Select>
          
</div>
{
  etatReq &&
  <>
  <div className="col">
<Form.Label htmlFor="exampleFormControlInput1">
            Catégorie plainte:
          </Form.Label>
         

          <Form.Select
         onChange={(e)=>changeQualif("id_type_plt", e.target.value)}
          >
            <option>Choisir</option>
            {typePlaintes?.map((item, key) => {
 
             return    ( <option key={key} value={item.id_type_plt}>
                    {item.lib_type_plt}
                  </option>)
                
            })}
          </Form.Select>
          
</div>
<div className="col">
<Form.Label htmlFor="exampleFormControlInput1">
            Service:
          </Form.Label>
         

          <Form.Select
         onChange={(e)=>changeQualif("id_service", e.target.value)}
          >
            <option>Choisir</option>
            {services?.map((item, key) => {
 
 return     ( <option key={key} value={item.id_service}>
                    {item.lib_service}
                  </option>)
                
            })}
          </Form.Select>
          
</div>
<div className="col">
<Form.Label htmlFor="exampleFormControlInput1">
            Canal de réception:
          </Form.Label>
         

          <Form.Select
         onChange={(e)=>changeQualif("id_canal", e.target.value)}
          >
            <option>Choisir</option>
            {canaux?.map((item, key) => {
 
 return     ( <option key={key} value={item.id_canal}>
                    {item.lib_canal}
                  </option>)
                
            })}
          </Form.Select>
          
</div>

</>}

            </div>
            <div className="container row ">
            <Button
         disabled={checkDisable()}
            variant="success"
            onClick={()=>save()}
          >
            Valider
          </Button>
            </div>
          </Modal.Footer>
        </Modal>

        <Footer />
      </div>
    </React.Fragment>
  );
}
