import React, { useState } from "react";
import moment from "moment";
import { Button, Card, Col, Row, Form, Modal } from "react-bootstrap";

const Step2 = (props) => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true)

   



  const { plainteData, setPlainteData } = props;
  const changePlainteData = (field, value) =>  setPlainteData({ ...plainteData, [field]: value });
  const [errorResume, setResumeError] = useState()

  const today = new Date()

  const handleFileChange = async (e) => {
    const selectedFiles = e.target.files;

    console.log(selectedFiles)
    changePlainteData("images", selectedFiles);
  };
  const validateNonEmpty = (value, setValidationError) => {
    const isValid = value.trim() !== "";
    setValidationError(isValid ? null : 'Champ requis');
    // isValid ? enableOrDisable(false) : enableOrDisable(true);
    return isValid;
  };

  const handleResumeChange = (e) => {
    const resume = e.target.value;
    validateNonEmpty(resume, setResumeError);
    changePlainteData('desc_plt', resume);
  };


  /*  const handleFileChange = async (e) => {
  const selectedFiles = e.target.files;

  const filesArray = [];
 
  for (const file of selectedFiles) {
    const base64Data = await convertFileToBase64(file);
    filesArray.push(base64Data);
  }
 
  changePlainteData("images", filesArray);
};



const convertFileToBase64 = async(file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
 
    reader.onload = () => {
      const base64Data = reader.result.split(',')[1];
      resolve(base64Data);
    };
 
    reader.onerror = (error) => {
      reject(error);
    };
  });
}; */


  return (
    <div className="container  p-4">
      <h6 className="mb-3">Informations sur la plainte</h6>

      <div className="row">
        <div className="col">
          <Form.Label htmlFor="exampleFormControlTextarea1">
            Résumé de la plainte *
          </Form.Label>
          <Form.Control
            onChange={handleResumeChange}
            as="textarea"
            value={plainteData.desc_plt}
            id="exampleFormControlTextarea1"
            rows="3"
            placeholder="Entrer votre détails..."
          ></Form.Control>

          {errorResume && (
            <span style={{ color: 'red', fontSize: '15px' }}>{errorResume}</span>
          )}
        </div>

        <div className="col">
          <Form.Label htmlFor="exampleFormControlTextarea1">
            Canal de retour souhaité *
          </Form.Label>
          <Form.Check
            value="0"
            checked={plainteData.canal_retour === "email"}
            onChange={(e) => changePlainteData("canal_retour", "email")}
            type="radio"
            label="Email"
          />
          <Form.Check
        
            checked={
              plainteData.canal_retour === "SMS" || 
              plainteData.canal_retour === "Appel" || 
              plainteData.canal_retour === "WhatsApp"  
              }
            onChange={() => handleShow()}
            // onChange={(e) => changePlainteData("canal_retour", "telephone")}
            type="radio"
            label="Téléphone"
          />
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-6">
          <Form.Label htmlFor="exampleFormControlTextarea1">
            Date du premier constat *
          </Form.Label>
          <Form.Control
            value={plainteData.date_constat}
            onChange={(e) => changePlainteData("date_constat", e.target.value)}
            type="date"
            max={moment(today).format('YYYY-MM-DD')}
            id="exampleFormControlInput1"
            placeholder=""
          />
        </div>
        <div className="col-6">
          <Form.Label htmlFor="exampleFormControlTextarea1">
            Pièces jointes
          </Form.Label>
          <Form.Control
            onChange={handleFileChange}
            type="file"
            id="exampleFormControlInput1"
            placeholder=""
            name="images"
            multiple
          />
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
  <Modal.Header closeButton>
    <Modal.Title>Type de requête</Modal.Title>
  </Modal.Header>
  <Modal.Body>
  <Row as="fieldset">
    <Col sm="10">
      <Form.Check type="radio"  label="SMS" 
      checked={
        plainteData.canal_retour === "SMS"  
        } 
      onChange={(e) => changePlainteData("canal_retour", "SMS")}  />

      <Form.Check type="radio"  label="Appel"  
      checked={
      
        plainteData.canal_retour === "Appel"   
        }
      onChange={(e) => changePlainteData("canal_retour", "Appel")}  />

      <Form.Check type="radio"  label="WhatsApp"  
      checked={ 
        plainteData.canal_retour === "WhatsApp"  
        }
      onChange={(e) => changePlainteData("canal_retour", "WhatsApp")}  />
      
     
    </Col>
  </Row>
  </Modal.Body>
  <Modal.Footer>
    {/* <Button variant="secondary" onClick={handleClose}>
      Annuler
    </Button> */}
    <Button variant="success" onClick={handleClose}>
      Valider
    </Button>
  </Modal.Footer>
</Modal>
    </div>
  );
};

export default Step2;
