import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";

export default function TableObligation(props) {
  const {
    ob
  } = props;

  const [globalFilter, setGlobalFilter] = useState("");
  const dt = useRef(null);
  
  const cols = [
    { field: "id_obligation_reg", header: "N°", sortable: true },
    { field: "lib_obligation_reg", header: "Libellé", sortable: true }, 
  ];

  const exportColumns = cols.map((col) => ({
    //FFFF
    title: col.header,
    dataKey: col.field,
  }));

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);

        doc.autoTable(exportColumns, ob); //FFFF
        doc.save("ob.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(ob);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "ob");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const header = (
    <div className="container_header_data_table">
      <div className="flex align-items-center">
        <div className="p-input-icon-left ml-3">
          <i className="pi pi-search" />
          <InputText
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search"
          />
        </div>
      </div>
      <div className="container_export">
        <Button
          type="button"
          icon="pi pi-file-pdf"
          rounded
          onClick={exportPdf}
          className=""
          data-pr-tooltip="PDF"
          style={{ borderRadius: 30 }}
          severity="secondary"
          size="small"
        />
        <Button
          type="button"
          icon="pi pi-file-excel"
          className=""
          severity="success"
          rounded
          onClick={exportExcel}
          data-pr-tooltip="Excel"
          style={{ borderRadius: 30 }}
          size="small"
        />
        <Button
          type="button"
          icon="pi pi-file"
          rounded
          onClick={() => exportCSV(false)}
          className=""
          data-pr-tooltip="CSV"
          style={{ borderRadius: 30 }}
          size="small"
        />
      </div>
    </div>
  );


  const rowClassName = (rowData, index) => {
    return index !== ob.length - 1 ? "table-row" : ""; // Applique la classe 'table-row' à toutes les lignes sauf la dernière
  };

  return (
    <div className="card" style={{ width: "100%" }}>
      <Tooltip target=".export-buttons>button" position="bottom" />
      <style>{`
            .table-row td {
                border-bottom: 1px solid #dee2e6; // Bordure inférieure pour les cellules de la ligne
            }
        `}</style>

      <DataTable
        ref={dt}
        value={ob}
        header={header}
        tableStyle={{ minWidth: "100%", fontSize: 12 }}
        globalFilter={globalFilter}
        emptyMessage="Pas de résultat."
        paginator
        rows={10}
        rowClassName={rowClassName}
      >
        {cols.map((col, index) => (
          <Column
            key={index}
            field={col.field}
            header={col.header}
            sortable={col.sortable}
            body={null}
          />
        ))}
      </DataTable>
    </div>
  );
}
