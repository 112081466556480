import React, { useEffect, useRef, useState } from "react";
import { Button, FormControl, Form, Modal } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";
import { useSelector } from "react-redux";

import {
    getStatutPlaintes,
    getTypePlaintes,
} from "../../redux/slice/plainteSlice";
import { getOperateurs } from "../../redux/slice/operateurSlice";

import {
    useGetServicesQuery,
    useGetDirectionsQuery,
    useCreateTypePlainteMutation,
    useGetPlaintesQuery,
    useGetStatutPlaintesQuery,
    useGetTypePlaintesQuery,
    useUpdatePlainteStatutMutation,
    useCreateObsPlainteMutation,
    useUpdatePlainteMutation,
    useCreateNotificationStepChangingMutation,
    useGetObsPlaintesQuery,
    useGetResultatObsQuery
} from "../../redux/apiSlice/plainteApiSlice.js";


export default function InstruirePlainte({
    display5,
    handleClose5,
    id_plt,
}) {
    const [editorContent, setEditorContent] = useState("");
    const [endingContact, setEndingContact] = useState("")
    const [serviceError, setServiceError] = useState();
    const [sendNotification, { isLoading: loadNotif }] = useCreateNotificationStepChangingMutation();

    const [destname, setDestName] = useState('');
    const [destid, setDestId] = useState(0);

    const [createObs, { isLoading: loadObsPlainte }] =
        useCreateObsPlainteMutation();
    const [updatePlainteStatut, { isLoading: loadUpdateState }] =
        useUpdatePlainteStatutMutation();
    const [step, setSteps] = useState(1);
    const [typeError, setTypeError] = useState();

    const operateurs = useSelector(getOperateurs);
    const [errors, setErrors] = useState({});
    let [disabled, setDisabled] = useState(true);

    const {
        data: services,
        refetch: refetchServices,
        isLoading: loadServices,
        isSuccess: isSuccessServices
    } = useGetServicesQuery();

    const {
        data: directions,
        refetch: refetchDirections,
        isLoading: loadDirections,
        isSuccess: isSuccessDirections
    } = useGetDirectionsQuery();

    const statutPlaintes = useSelector(getStatutPlaintes);

    const validateInsruction = async (complainStatusId, mycomplainId) => {
        try {
            Swal.fire({
                title: "Action irréversible !",
                text: "Cette action est irréversible !",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui", cancelButtonText: "Non",
            }).then(async (result) => {
                if (result.isConfirmed === true) {
                    const retour_plainte = await updatePlainteStatut({
                        id_stat_plt: 5,
                        id_plt: id_plt,
                    }).unwrap();
                    console.log(retour_plainte);
                    if (retour_plainte) {
                        sendNotification({
                            "nouveau_status": statutPlaintes.find(
                                (etape) => etape.id_stat_plt === 6
                            )
                        });
                        window.location.href = "/toutes_les_plaintes";
                    }
                }
            });

            // refetchPlaintes();
        } catch (error) {
            console.error("Error updating complaint state:", error);
        }
    };

    const handleNext = () => {
        switch (step) {
            case 1:

                setSteps(2);
                break;
            case 2:

                validateInsruction();
                break;
        }
    }


    useEffect(() => {
        switch (step) {
            case 1:
                if (endingContact !== "") {
                    setDisabled(false)
                } else if (endingContact === "") {
                    setDisabled(true)
                }
                // setSteps(2);
                break;
            case 2:
                if (destname !== "") {
                    setDisabled(false)
                } else if (destname === "") {
                    setDisabled(true)
                }
                // setSteps(2);
                break;

        }
    }, [endingContact, destname, step])

    const validateSelect = (e, setValidationError) => {

        const isValid = e.target.value.trim() !== "";
        setValidationError(isValid ? null : 'Champ requis');
        // isValid ? enableOrDisable(false) : enableOrDisable(true);
        setDestId(e.target.value)
    }

    return (
        <div class="container">
            <Modal
                show={display5}
                onHide={() => { setSteps(1); setEndingContact(''); setDestName(''); setDestId(0); handleClose5(); }}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Instruire Plainte</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="row">

                                {
                                    step == 1 && (
                                        <div className="row">
                                            <h5>Quelle direction souhaitez vous contacter ?</h5>
                                            <Form.Group controlId="formBasicText">
                                                <Form.Check
                                                    type="radio"
                                                    label="Direction"
                                                    value="0"
                                                    checked={endingContact === "0"}
                                                    onChange={(e) => setEndingContact('0')}
                                                />
                                                <Form.Check
                                                    type="radio"
                                                    label="Opérateur"
                                                    value="1"
                                                    checked={endingContact === "1"}
                                                    onChange={(e) => setEndingContact('1')}
                                                />
                                            </Form.Group>
                                        </div>
                                    )
                                }

                                {
                                    step === 2 && (
                                        <div className="row">
                                            {
                                                endingContact === "1" && (
                                                    <div className="row">
                                                        <h5>Veuillez sélectionner un opérateur </h5>
                                                        <Form.Select
                                                            onChange={(e) => {
                                                                validateSelect(e, setTypeError);
                                                                const selectedOperator = operateurs.find(
                                                                    (operator) => operator.id_operateur === parseInt(e.target.value)
                                                                );
                                                                setDestName(selectedOperator?.nom_operateur ? selectedOperator.nom_operateur : "")
                                                            }
                                                            }
                                                        >
                                                            <option>Choisir</option>
                                                            {operateurs.map((item, key) => {
                                                                let opt = <></>;
                                                                if (destid === item.id_operateur) {
                                                                    opt = (
                                                                        <option selected key={key} value={item.id_operateur}>
                                                                            {item.nom_operateur}
                                                                        </option>
                                                                    );
                                                                } else {
                                                                    opt = (
                                                                        <option key={key} value={item.id_operateur}>
                                                                            {item.nom_operateur}
                                                                        </option>
                                                                    );
                                                                }
                                                                return opt;
                                                            })}
                                                        </Form.Select>
                                                        {typeError && (
                                                            <span style={{ color: 'red', fontSize: '15px' }}>{typeError}</span>
                                                        )}
                                                    </div>
                                                )
                                            }
                                            {
                                                endingContact === "0" && (
                                                    <div className="row">
                                                        <h5>Veuillez sélectionner une direction </h5>
                                                        <Form.Select
                                                            onChange={(e) => {
                                                                validateSelect(e, setServiceError);

                                                                const selectedDirection = directions.find(
                                                                    (direction) => direction.id_direction === parseInt(e.target.value)
                                                                );

                                                                setDestName(selectedDirection?.nom_direction ? selectedDirection?.nom_direction : "")



                                                            }}
                                                        >
                                                            <option>Choisir</option>
                                                            {directions?.map((item, key) => {
                                                                // console.log(plainteData.id_service, item.id_service)
                                                                let opt = <></>;
                                                                if (destid.id_direction == item.id_direction) {
                                                                    opt = (
                                                                        <option selected key={key} value={item.id_direction}>
                                                                            {item.nom_direction}
                                                                        </option>
                                                                    );
                                                                } else {
                                                                    opt = (
                                                                        <option key={key} value={item.id_direction}>
                                                                            {item.nom_direction}
                                                                        </option>
                                                                    );
                                                                }
                                                                return opt;
                                                            })}
                                                        </Form.Select>
                                                        {serviceError && (
                                                            <span style={{ color: 'red', fontSize: '15px' }}>{serviceError}</span>
                                                        )}
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className={step === 2 && "col d-flex justify-content-between align-items-center"}>
                    {step === 2 && (
                        <Button onClick={() => setSteps(1)} variant="primary">
                            Précédent
                        </Button>
                    )}
                    <Button disabled={disabled} onClick={handleNext} variant="success">
                        {" "}
                        {
                            step === 1 && (
                                <div>{
                                    loadObsPlainte ? (
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria- hidden="true"
                                        />
                                    ) : (
                                        <span> Suivant </span>
                                    )} </div>
                            )
                        }

                        {
                            step === 2 && (
                                <div>{
                                    loadObsPlainte ? (
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria- hidden="true"
                                        />
                                    ) : (
                                        <span> Valider </span>
                                    )} </div>
                            )
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
