import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Signin from "./pages/Signin";
import NotFound from "./pages/NotFound";
import Dashboard from "./dashboard/Dashboard";
import SoumissionPlainte from "./dashboard/SoumissionPlainte";
import DepotPLaintes from "./pages/plaintes/DepotPLaintes";
import Qualification from "./pages/plaintes/Qualification.js";
import ListeRequetes from "./pages/plaintes/ListeRequetes.js";
import CategoriePlainte from "./pages/plaintes/CategoriePlainte";
import ListePlaintes from "./pages/plaintes/ListePlaintes";
import Comming from "./pages/comming/Comming";
// import DashbordOR from "./pages/ObligationReglmt.js/DashbordOR";
import NouvelleOB from "./pages/ObligationReglmt.js/NouvelleOB";
import SuiviOR from "./pages/ObligationReglmt.js/SuiviOR";
import AjoutOR from "./pages/ObligationReglmt.js/AjoutOR";
import AffectationOR from "./pages/ObligationReglmt.js/AffectationOR";
import CategorieOR from "./pages/ObligationReglmt.js/CategorieOR";
import SecteurOB from "./pages/ObligationReglmt.js/SecteurOB.js";
import TypeOR from "./pages/ObligationReglmt.js/TypeOR";
import TypeOP from "./pages/ObligationReglmt.js/TypeOP";
import VerificationOR from "./pages/ObligationReglmt.js/VerificationOR";
import DirectionOR from "./pages/ObligationReglmt.js/DirectionOR";
import Periodicite from "./pages/ObligationReglmt.js/Periodicite";
import Echeance from "./pages/ObligationReglmt.js/Echeance";
// import Gestion_plateforme from './pages/gestionPlateformes'
import AvisReglement from "./pages/plaintes/status/AvisReglement";
import Instruction from "./pages/plaintes/status/Instruction";
import AccuseReception from "./pages/plaintes/status/AccuseReception";
import AttValidation from "./pages/plaintes/status/AttValidation";
import PlainteClassee from "./pages/plaintes/status/PlainteClassee";
import PlainteReceptionee from "./pages/plaintes/status/PlainteReceptionee";
import CompletudeDinfos from "./pages/plaintes/status/CompletudeDinfos";
import PlainteEnInvestigation from "./pages/plaintes/status/PlainteEnInvestigation.js";
import ActionPlainte from "./pages/plaintes/ActionPlainte.js";
import ServicesPlainte from "./pages/plaintes/ServicesPlainte.js";
import InstructionDePlainte from "./pages/plaintes/status/InstructionDePlainte";
import AvisDeReglement from "./pages/plaintes/status/AvisDeReglement";
import Prestation from "./pages/gestionPrestations";
import CategoriePrestation from "./pages/categoriePrestation/index.js";
import Gestion_plateforme from "./pages/gestionPlateformes";
import CategorieFaq from "./pages/categorieFaq/index.js";
import Faq from "./pages/faq/index.js";
import Operateur from "./pages/ObligationReglmt.js/Operateur.js";
import UpdateOR from "./pages/ObligationReglmt.js/UpdateOR.js";
import Profile from "./pages/administration/Profile.js";
import ListeProfile from "./pages/administration/ListeProfile.js";
import TypeRequete from "./pages/plaintes/TypeRequete.js";
import User from "./pages/user/";
// Gestion des templates emails plaintes
import TempEmailPlainte from "./pages/administration/TempEmailPlainte";

// Gestion des elements de conrole
import ElementControle from "./pages/administration/ElementControle";

// Gestion paramètrage email
import ParamEmail from "./pages/administration/ParamEmail";

// Gestion paramètrage smtp
import ParamSmtp from "./pages/administration/ParamSMTP";

// import css
import "./assets/css/remixicon.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import scss
import "./scss/style.scss";

import Tranche from "./pages/numerotation/Tranche";
import SousTranche from "./pages/numerotation/SousTranche";
import ServiceTranche from "./pages/numerotation/ServiceTranche";
import AttributionTranche from "./pages/numerotation/AttributionTranche";
import EtatPNM from "./pages/numerotation/EtatPNM.js";
import Clients from "./pages/numerotation/Clients.js";
import StructurePNM from "./pages/numerotation/StructurePNM.js";
import Ussd from "./pages/numerotation/Ussd.js";
import EtatUssd from "./pages/numerotation/EtatUssd.js" 
import EtatMnc from "./pages/numerotation/EtatMnc.js" 
import EtatIspc from "./pages/numerotation/EtatIspc.js";

import ForgetPwd from "./pages/ForgetPwd.js";

import StatistiquePlainte from "./pages/statistique/plainte/StatistiquePlainte.js"
import StatistiqueNumerotation from "./pages/statistique/numerotation/StatistiqueNumerotation.js"
import StatistiqueObligation from "./pages/statistique/obligation/StatistiqueObligation.js"
import StatistiqueTypOp from './pages/statistique/obligation/StatistiqueTypOp.js'
import StatistiqueUssd from  "./pages/statistique/numerotation/StatistiqueUssd.js"
import StatistiqueMnc from  "./pages/statistique/numerotation/StatistiqueMnc.js"
import StatistiqueIspc from  "./pages/statistique/numerotation/StatistiqueIspc.js"
import StatistiqueRessourcesAttribuees from  "./pages/statistique/numerotation/StatistiqueRessourcesAttribuees.js"
import StatistiqueInfoAb from  "./pages/statistique/numerotation/StatististiqueInfoAb.js"
import StatistiqueSansAb from  "./pages/statistique/numerotation/StatistiqueSansAb.js"

// set skin on load
window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("skin-mode");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-skin", skinMode);
  }
});

export default function App() {
  return (
    <BrowserRouter>
    <ToastContainer/>
      <Routes>
        <Route path="/" element={<Signin />} />
        <Route path="/" element={<Signin />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/depot-plainte" element={<SoumissionPlainte />} />
        <Route path="/plainte" element={<DepotPLaintes />} />
        <Route path="/qualification" element={<Qualification />} />
        <Route path="/decision-plainte" element={<ActionPlainte />} />
        <Route path="/services-plainte" element={<ServicesPlainte />} />
        <Route path="/categorie-plainte" element={<CategoriePlainte />} />
        <Route path="/toutes_les_plaintes" element={<ListePlaintes />} />
        <Route path="/toutes_les_requetes" element={<ListeRequetes />} />
        <Route path="/comming" element={<Comming />} />
        {/* <Route path="/dashbord-obligation-reglmt" element={<DashbordOR />} /> */}
        <Route path="/nouvelle-obligation-reglmt" element={<NouvelleOB />} />
        <Route path="/ajout-obligation-reglmt" element={<AjoutOR />} />
        <Route
          path="/affectation-obligation-reglmt"
          element={<AffectationOR />}
        />
        <Route path="/suivi-obligation-reglmt" element={<SuiviOR />} />
        <Route path="/categorie-obligation-reglmt" element={<CategorieOR />} />
        <Route path="/secteur-obligation-reglmt" element={<SecteurOB />} />
        <Route path="/type-obligation-reglmt" element={<TypeOR />} />
        <Route path="/type-operateur-reglmt" element={<TypeOP />} />
        <Route
          path="/verification-obligation-reglmt"
          element={<VerificationOR />}
        />
        <Route path="/direction-obligation-reglmt" element={<DirectionOR />} />
        <Route
          path="/periodicite-obligation-reglmt"
          element={<Periodicite />}
        />
        <Route path="/echeance-obligation-reglmt" element={<Echeance />} />
        {/* <Route path="/gestion-plateformes" element={<Gestion_plateforme />} /> */}
        <Route path="/avis-reglement" element={<AvisReglement />} />
        <Route path="/att_instruction" element={<Instruction />} />
        <Route path="/att_validation" element={<AttValidation />} />
        <Route path="/accuser_reception" element={<AccuseReception />} />
        <Route path="/plainte_classée" element={<PlainteClassee />} />
        <Route path="/plainte_receptionee" element={<PlainteReceptionee />} />
        <Route
          path="/completude_dinformations"
          element={<CompletudeDinfos />}
        />
        <Route
          path="/investigation_de_plainte"
          element={<PlainteEnInvestigation />}
        />
        <Route
          path="/instruction_de_plainte"
          element={<InstructionDePlainte />}
        />
        <Route path="/avis_de_reglement" element={<AvisDeReglement />} />
        <Route path="/prestation" element={<Prestation />} />
        <Route path="/categorie-prestation" element={<CategoriePrestation />} />
        <Route path="/gestion-plateformes" element={<Gestion_plateforme />} />
        <Route path="/categorie-faq" element={<CategorieFaq />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/operateur" element={<Operateur />} />
        <Route path="/update-obligation-reglmt/:id_ob" element={<UpdateOR />} />
        <Route path="/creation-profil" element={<Profile />} />
        <Route path="/liste-profil" element={<ListeProfile />} />
        <Route path="/type-requete" element={<TypeRequete />} />
        <Route path="/gestion-utilisateur" element={<User />} />

        <Route path="/tranche" element={<Tranche />} />
        <Route path="/sous-tranche" element={<SousTranche />} />
        <Route path="/service-tranche" element={<ServiceTranche />} />
        <Route path="/liaison" element={<AttributionTranche />} />
        <Route path="/etat-pnm" element={<EtatPNM />} />
        <Route path="/clients-numerotation" element={<Clients />} />
        <Route path="/structure-pnm" element={<StructurePNM />} />
        <Route path="/liaison-ussd" element={<Ussd />} />
        <Route path="/etat-ussd" element={<EtatUssd />} />
        <Route path="/etat-mnc" element={<EtatMnc />} />
        <Route path="/etat-ispc" element={<EtatIspc />} />
        

       
        <Route path="/statistique-plainte" element={<StatistiquePlainte/>} />
<Route path="/statistique-numerotation" element={<StatistiqueNumerotation />} />
<Route path="/statistique-obligation" element={<StatistiqueObligation />} />
<Route path="/classement" element={<StatistiqueTypOp />} />
        <Route path="/statistique-ussd" element={<StatistiqueUssd />} />
        <Route path="/statistique-mnc" element={<StatistiqueMnc />} />
        <Route path="/statistique-ispc" element={<StatistiqueIspc />} />
        <Route path="/ressources-attribuees" element={<StatistiqueRessourcesAttribuees />} />
        <Route path="/infos-ab" element={<StatistiqueInfoAb />} />
        <Route path="/tranche-sans-ab" element={<StatistiqueSansAb />} />

        <Route path="/tpl_email_plainte" element={<TempEmailPlainte />} />

        <Route path="/send_email_recuperation" element={<ForgetPwd />} />

        {/* route des éléments de controle */}
        <Route path="/elt_controle" element={<ElementControle />} />

        {/* route du paramètrage email */}
        <Route path="/param_email" element={<ParamEmail />} />

        {/* route du paramètrage smtp */}
        <Route path="/param_smtp" element={<ParamSmtp />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
