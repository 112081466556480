import { apiSlice } from "../api/apiSlice";

export const logPageApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLogPage: builder.query({
      query: (id) => ({
        url: `/log/get_log_page/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["logPage"],
    }),
    createLogPage: builder.mutation({
      query: (body) => ({
        url: "/log/open_log_page/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["logPage"],
    }),
  }),
});

export const {
  useGetLogPageQuery,
  useCreateLogPageMutation,
} = logPageApiSlice;
