import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
} from "react-bootstrap";

import { Link } from "react-router-dom";
import Footer from "../../../layouts/Footer.js";
import Header from "../../../layouts/Header.js";
import Swal from "sweetalert2";
import Sidebar from "../../../layouts/Sidebar.js";
import { useSelector } from "react-redux";

import { getUserInfo } from "../../../redux/slice/userSlice.js";
import {
  useGetServiceTrancheQuery, //liste des services
  useGetSousTrancheQuery, //liste sous tranche
  useGetTrancheQuery, //liste des tranche
  useGetRessourceListeTrancheQuery, //recuperation de ressources par tranche
  useGetRessourceListeSousTrancheQuery, //recuperation de ressources par sous tranche
  useGetRessourceAllServiceQuery, //recuperation de ressources par service
  useGetAllRessourcesQuery, //liste totale des ressources
} from "../../../redux/apiSlice/numerotationApiSlice";
import { useGetOperateursQuery } from "../../../redux/apiSlice/operateurApiSlice";

// import TableNumerotation from "./dataTables/TableNumerotation.js";
import { G } from "@react-pdf/renderer";

export default function ListePlainte() {
  const initTranche = {
    id_tranche: "",
  };
  const [lesTranche, setLesTranche] = useState({ ...initTranche });

  const initEtatsRessources = {
    libre: [],
    occuper: [],
    reserver: [],
    en_instance: [],
    inutilisable: [],
    bloque: [],
  };

  const [lesEtatsRessources, setLesEtatsRessources] = useState({
    ...initEtatsRessources,
  });

  // ****
  const { data: ressources = [] } = useGetAllRessourcesQuery();
  const { data: tranches = [] } = useGetTrancheQuery();
  const { data: sousTranches = [] } = useGetSousTrancheQuery();
  const { data: services = [] } = useGetServiceTrancheQuery();
  // const { data: operateur = [] } = useGetOperateursQuery();
  // ****

  const recupererTranche = (champ, value) => {
    setLesTranche({ ...lesTranche, [champ]: value });
  };

  // ----****----
  const [stateTranche, setStateTranche] = useState({});
  const [stateSousTranche, setStateSousTranche] = useState({});
  const [stateService, setStateService] = useState({});

  const [etat, setEtat] = useState(0);

  const [id_tranche, setId_tranche] = useState(0);
  const [id_sous_tranche, setId_sous_tranche] = useState(0);
  const [id_service, setId_service] = useState(0);

  var new_sous_tranche = sousTranches.filter(
    (elt) => elt.id_tranche == id_tranche
  );

  const { data: listeTranche = [] } =
    useGetRessourceListeTrancheQuery(id_tranche);

  const { data: listeSousTranche = [] } =
    useGetRessourceListeSousTrancheQuery(id_sous_tranche);

  const { data: parService = [] } = useGetRessourceAllServiceQuery({
    id_service,
  });

  // ---****---
  const handleChange = (lib, value) => {
    switch (lib) {
      case "tranche":
        setEtat(1);
        setId_tranche(value);

        break;
      case "sousTranche":
        setEtat(2);
        setId_sous_tranche(value);

        break;
      case "service":
        setEtat(3);
        setId_service(value);

        break;

      default:
        break;
    }
  };

  useEffect(() => {
    setLesEtatsRessources({
      ...lesEtatsRessources,
      libre: ressources.filter((libr, key) => libr.id_stat_num == 1),
      occuper: ressources.filter((occ, key) => occ.id_stat_num == 4),
      reserver: ressources.filter((reser, key) => reser.id_stat_num == 3),
      en_instance: ressources.filter((inst, key) => inst.id_stat_num == 2),
      inutilisable: ressources.filter((inuti, key) => inuti.id_stat_num == 6),
      bloque: ressources.filter((bloq, key) => bloq.id_stat_num == 5),
    });
    if (listeTranche) {
      const libre = listeTranche.filter((libr, key) => libr.id_stat_num == 1);
      const occuper = listeTranche.filter((occ, key) => occ.id_stat_num == 4);
      const reserver = listeTranche.filter(
        (reser, key) => reser.id_stat_num == 3
      );
      const en_instance = listeTranche.filter(
        (inst, key) => inst.id_stat_num == 2
      );
      const inutilisable = listeTranche.filter(
        (inuti, key) => inuti.id_stat_num == 6
      );
      const bloque = listeTranche.filter((bloq, key) => bloq.id_stat_num == 5);

      setStateTranche({
        ...stateTranche,
        occuper,
        reserver,
        libre,
        en_instance,
        inutilisable,
        bloque,
      });
    }

    if (listeSousTranche) {
      const libre = listeSousTranche.filter(
        (libr, key) => libr.id_stat_num == 1
      );
      const occuper = listeSousTranche.filter(
        (occ, key) => occ.id_stat_num == 4
      );
      const reserver = listeSousTranche.filter(
        (reser, key) => reser.id_stat_num == 3
      );
      const en_instance = listeSousTranche.filter(
        (inst, key) => inst.id_stat_num == 2
      );
      const inutilisable = listeSousTranche.filter(
        (inuti, key) => inuti.id_stat_num == 6
      );
      const bloque = listeSousTranche.filter(
        (bloq, key) => bloq.id_stat_num == 5
      );

      setStateSousTranche({
        ...stateSousTranche,
        occuper,
        reserver,
        libre,
        en_instance,
        inutilisable,
        bloque,
      });
    }

    if (parService) {
      const libre = parService.filter((libr, key) => libr.id_stat_num == 1);
      const occuper = parService.filter((occ, key) => occ.id_stat_num == 4);
      const reserver = parService.filter(
        (reser, key) => reser.id_stat_num == 3
      );
      const en_instance = parService.filter(
        (inst, key) => inst.id_stat_num == 2
      );
      const inutilisable = parService.filter(
        (inuti, key) => inuti.id_stat_num == 6
      );
      const bloque = parService.filter((bloq, key) => bloq.id_stat_num == 5);

      setStateService({
        ...stateService,
        occuper,
        reserver,
        libre,
        en_instance,
        inutilisable,
        bloque,
      });
    }
  }, [listeTranche, listeSousTranche, services, ressources]);

  const user = useSelector(getUserInfo);

  //   useEffect(() => {
  //     if (user?.id) {
  //       localStorage.setItem("user_id", user?.id);
  //     }
  //     if (
  //       localStorage.getItem("user_id") === "" ||
  //       !localStorage.getItem("user_id")
  //     ) {
  //       window.location.href = "/";
  //     }
  //   }, []);

  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Numerotation</Link>
              </li>
              {/* <li className="breadcrumb-item active" aria-current="page">Finance Monitoring</li> */}
            </ol>
            <h4 className="main-title mb-0">Statistique ressource</h4>
          </div>
        </div>

        <Row className="g-3">
          <Col xl="12" sm="12">
            <Row className="g-2">
              <Col sm="12" xl="12">
                <Card className="card-one" style={{ height: "200px" }}>
                  <Card.Body className="overflow-hidden px-0">
                    <div className="finance-info p-3 p-xl-4 pb-4">
                      <Row>
                        {etat == 0 && (
                          <Col sm="12" xl="12">
                            <label className="fs-sm fw-medium mb-2 mb-xl-1">
                              Nombre de ressource
                            </label>

                            <h4 className="finance-value">
                              {lesEtatsRessources.occuper.length +
                                lesEtatsRessources.libre.length +
                                lesEtatsRessources.reserver.length +
                                lesEtatsRessources.en_instance.length +
                                lesEtatsRessources.inutilisable.length +
                                lesEtatsRessources.bloque.length}
                            </h4>

                            <Row>
                              <Col xl="2" sm="12" className="d-flex flex-row ">
                                {" "}
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  Réservée :{" "}
                                </span>
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  {lesEtatsRessources.reserver?.length}
                                </span>
                              </Col>
                              <Col xl="2" sm="12" className="d-flex flex-row ">
                                {" "}
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  Libres :{" "}
                                </span>
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  {lesEtatsRessources.libre.length}
                                </span>
                              </Col>
                              <Col xl="2" sm="12" className="d-flex flex-row ">
                                {" "}
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  Occupées :{" "}
                                </span>
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  {lesEtatsRessources.occuper.length}
                                </span>
                              </Col>
                              <Col xl="2" sm="12" className="d-flex flex-row ">
                                {" "}
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  En instances :{" "}
                                </span>
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  {lesEtatsRessources.en_instance?.length}
                                </span>
                              </Col>
                              <Col xl="2" sm="12" className="d-flex flex-row ">
                                {" "}
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  Inutilisables :{" "}
                                </span>
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  {lesEtatsRessources.inutilisable?.length}
                                </span>
                              </Col>
                              <Col xl="2" sm="12" className="d-flex flex-row ">
                                {" "}
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  Bloqués :{" "}
                                </span>
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  {lesEtatsRessources.bloque?.length}
                                </span>
                              </Col>
                            </Row>
                          </Col>
                        )}
                        {etat == 1 && (
                          <Col sm="12" xl="12">
                            <label className="fs-sm fw-medium mb-2 mb-xl-1">
                              Nombre de ressource
                            </label>

                            <h4 className="finance-value">
                              {stateTranche.occuper.length +
                                stateTranche.libre.length +
                                stateTranche.reserver.length +
                                stateTranche.en_instance.length +
                                stateTranche.inutilisable.length +
                                stateTranche.bloque.length}
                            </h4>

                            <Row>
                              <Col xl="2" sm="12" className="d-flex flex-row ">
                                {" "}
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  Réservée :{" "}
                                </span>
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  {stateTranche.reserver?.length}
                                </span>
                              </Col>
                              <Col xl="2" sm="12" className="d-flex flex-row ">
                                {" "}
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  Libres :{" "}
                                </span>
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  {stateTranche.libre?.length}
                                </span>
                              </Col>
                              <Col xl="2" sm="12" className="d-flex flex-row ">
                                {" "}
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  Occupées :{" "}
                                </span>
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  {stateTranche.occuper?.length}
                                </span>
                              </Col>

                              <Col xl="2" sm="12" className="d-flex flex-row ">
                                {" "}
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  En instances :{" "}
                                </span>
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  {stateTranche.en_instance?.length}
                                </span>
                              </Col>
                              <Col xl="2" sm="12" className="d-flex flex-row ">
                                {" "}
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  Inutilisables :{" "}
                                </span>
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  {stateTranche.inutilisable?.length}
                                </span>
                              </Col>
                              <Col xl="2" sm="12" className="d-flex flex-row ">
                                {" "}
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  Bloqués :{" "}
                                </span>
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  {stateTranche.bloque?.length}
                                </span>
                              </Col>
                            </Row>
                          </Col>
                        )}
                        {etat == 2 && (
                          <Col sm="12" xl="12">
                            <label className="fs-sm fw-medium mb-2 mb-xl-1">
                              Nombre de ressource
                            </label>

                            <h4 className="finance-value">
                              {stateSousTranche.occuper?.length +
                                stateSousTranche.libre?.length +
                                stateSousTranche.reserver?.length +
                                stateSousTranche.en_instance.length +
                                stateSousTranche.inutilisable.length +
                                stateSousTranche.bloque.length}
                            </h4>

                            <Row>
                              <Col xl="2" sm="12" className="d-flex flex-row ">
                                {" "}
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  Réservée :{" "}
                                </span>
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  {stateSousTranche.reserver.length}
                                </span>
                              </Col>
                              <Col xl="2" sm="12" className="d-flex flex-row ">
                                {" "}
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  Libres :{" "}
                                </span>
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  {stateSousTranche.libre.length}
                                </span>
                              </Col>
                              <Col xl="2" sm="12" className="d-flex flex-row ">
                                {" "}
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  Occupées :{" "}
                                </span>
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  {stateSousTranche.occuper.length}
                                </span>
                              </Col>
                              <Col xl="2" sm="12" className="d-flex flex-row ">
                                {" "}
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  En instances :{" "}
                                </span>
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  {stateSousTranche.en_instance?.length}
                                </span>
                              </Col>
                              <Col xl="2" sm="12" className="d-flex flex-row ">
                                {" "}
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  Inutilisables :{" "}
                                </span>
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  {stateSousTranche.inutilisable?.length}
                                </span>
                              </Col>
                              <Col xl="2" sm="12" className="d-flex flex-row ">
                                {" "}
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  Bloqués :{" "}
                                </span>
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  {stateSousTranche.bloque?.length}
                                </span>
                              </Col>
                            </Row>
                          </Col>
                        )}
                        {etat == 3 && (
                          <Col sm="12" xl="12">
                            <label className="fs-lg fw-medium mb-2 mb-xl-1">
                              Nombre de ressource
                            </label>

                            <h3 className="finance-value">
                              {stateService.occuper.length +
                                stateService.libre.length +
                                stateService.reserver.length +
                                stateService.en_instance.length +
                                stateService.inutilisable.length +
                                stateService.bloque.length}
                            </h3>

                            <Row>
                              <Col xl="2" sm="12" className="d-flex flex-row ">
                                {" "}
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  Réservée :{" "}
                                </span>
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  {stateService.reserver.length}
                                </span>
                              </Col>
                              <Col xl="2" sm="12" className="d-flex flex-row ">
                                {" "}
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  Libres :{" "}
                                </span>
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  {stateService.libre.length}
                                </span>
                              </Col>
                              <Col xl="2" sm="12" className="d-flex flex-row ">
                                {" "}
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  Occupées :{" "}
                                </span>
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  {stateService.occuper.length}
                                </span>
                              </Col>
                              <Col xl="2" sm="12" className="d-flex flex-row ">
                                {" "}
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  En instances :{" "}
                                </span>
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  {stateService.en_instance?.length}
                                </span>
                              </Col>
                              <Col xl="2" sm="12" className="d-flex flex-row ">
                                {" "}
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  Inutilisables :{" "}
                                </span>
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  {stateService.inutilisable?.length}
                                </span>
                              </Col>
                              <Col xl="2" sm="12" className="d-flex flex-row ">
                                {" "}
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  Bloqués :{" "}
                                </span>
                                <span
                                  style={{ fontSize: "14px", fontWeight: 600 }}
                                >
                                  {stateService.bloque?.length}
                                </span>
                              </Col>
                            </Row>
                          </Col>
                        )}
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              {/* <Col sm="12" xl="12">
                <p className="w-50 fs-sm mb-2 mb-xl-4 d-none d-sm-block">
                  Liste des ressources
                </p>
                <TableNumerotation tranche={dataTranche} />
              </Col> */}
            </Row>
          </Col>
          <Col xl="12" sm="12" className="d-flex flex-column gap-2">
            <Row className="g-2">
              <Col sm="12" xl="6" className="d-flex flex-column">
                <Form.Label htmlFor="tranche">Tranche:</Form.Label>

                <Form.Select
                  id="tranche"
                  onChange={(e) =>
                    handleChange("tranche", parseInt(e.target.value))
                  }
                >
                  <option value="">Choisir</option>
                  {tranches?.map((tranch, key) => (
                    <option key={key} value={tranch.id_tranche}>
                      {tranch.lib_tranche}
                    </option>
                  ))}
                </Form.Select>
              </Col>

              <Col sm="12" xl="6" className="d-flex flex-column">
                <Form.Label htmlFor="soustranche">Sous tranche:</Form.Label>

                <Form.Select
                  id="soustranche"
                  onChange={(e) =>
                    handleChange("sousTranche", parseInt(e.target.value))
                  }
                >
                  <option value="">Choisir</option>
                  {new_sous_tranche?.map((soustranch, key) => (
                    <option key={key} value={soustranch.id_s_tran}>
                      {soustranch.lib_s_tran}
                    </option>
                  ))}
                </Form.Select>
              </Col>

              {/* <Col sm="12" xl="4" className="d-flex flex-column">
                <Form.Label htmlFor="service">Services:</Form.Label>

                <Form.Select
                  id="service"
                  onChange={(e) =>
                    handleChange("service", parseInt(e.target.value))
                  }
                >
                  <option value="">Choisir</option>
                  {services?.map((serv, key) => (
                    <option key={key} value={serv.id_service}>
                      {serv.lib_service}
                    </option>
                  ))}
                </Form.Select>
              </Col> */}
            </Row>
          </Col>
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  );
}
