import React, { useEffect, useState } from "react";
import { Button, Form, Card, Col, Row } from "react-bootstrap";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import Footer from "../../../layouts/Footer.js";
import Header from "../../../layouts/Header.js";
import Swal from "sweetalert2";
import Sidebar from "../../../layouts/Sidebar.js";
import { useSelector } from "react-redux";

import { getUserInfo } from "../../../redux/slice/userSlice.js";
import {
  useGetPlainteAvecOpDateQuery,
  useGetStatistiquePlainteGlobalQuery,
  useGetAllPlainteQuery,
  useGetAllServiceQuery,
  useGetTypePlainteQuery,
  useGetCanauxReceptionQuery,
} from "../../../redux/apiSlice/statistiquePlainteApiSlice.js";

import {
  useGetDecisionPlainteQuery,
  useGetStatutPlaintesQuery,
  useGetTypePlaintesQuery,
} from "../../../redux/apiSlice/plainteApiSlice.js";

import { useGetOperateursQuery } from "../../../redux/apiSlice/operateurApiSlice.js";

import { getTypePlaintes } from "../../../redux/slice/plainteSlice.js";
import TablePlainte from "./dataTables/TablePlainte.js";
export default function StatistiquePlainte() {
  const { data: opPlainte } = useGetOperateursQuery();
  const { data: allPlainte } = useGetAllPlainteQuery();
  const { data: allDecision } = useGetDecisionPlainteQuery();
  const { data: allService } = useGetAllServiceQuery();
  const { data: statustsPlainte } = useGetStatutPlaintesQuery();
  const { data: typePlaintes } = useGetTypePlaintesQuery();
  const { data: canauxRecept } = useGetCanauxReceptionQuery();

  const initPlainte = {
    lib_sexe: "",
    id_stat_plt: "",
    id_operateur: "",
    id_type_plt: "",
    id_service: "",
    id_dec: "",
    id_canal: "",
    date_debut: "",
    date_fin: "",
  };
  const [lesChamp, setLesChamp] = useState({ ...initPlainte });
  const viderEtRafraichi = () => {
    setLesChamp({ ...initPlainte });
    setDataPlainte(allPlainte)
  };

  const [timestamps, setTimestamps] = useState({
    date_debut: "",
    date_fin: "",
  });
  const [errorMessage, setErrorMessage] = useState({
    msgDebut: "",
    msgFin: "",
  });
  const [dataPlainte, setDataPlainte] = useState([]);
  const [loading, setLoading] = useState(false);
  const [plainteFondeeOuNon, setPlainteFondeeOuNon] = useState("");

  const recuperateurGeneral = (champ, value) => {
    setLesChamp({ ...lesChamp, [champ]: value });
    const timestamp = new Date(value).getTime();

    if (champ === "date_debut") {
      if (timestamps.date_fin && timestamp > timestamps.date_fin) {
        setErrorMessage({
          ...errorMessage,
          msgDebut: "Date de début supérieure à date de fin.",
          msgFin: "",
        });
      } else {
        setErrorMessage({
          ...errorMessage,
          msgDebut: "",
          msgFin: "",
        });
      }
    }

    if (champ === "date_fin") {
      if (timestamps.date_debut && timestamp < timestamps.date_debut) {
        setErrorMessage({
          ...errorMessage,
          msgFin: "Date de fin inférieure à date de début.",
          msgDebut: "",
        });
      } else {
        setErrorMessage({
          ...errorMessage,
          msgFin: "",
          msgDebut: "",
        });
      }
    }

    setTimestamps({
      ...timestamps,
      [champ]: timestamp,
    });
  };
  useEffect(() => {
    if (allPlainte) {
      setDataPlainte(allPlainte);
    }
  }, [allPlainte]);

  const verificate = Object.values(lesChamp).some(
    (value) => value.trim() !== ""
  );

  const recupereDonneePlainte = (typPlainte = "") => {
    if (typPlainte === "") {
      axios
        .get("https://apipigru.leavesmartconsulting.com/pigru/plainte/get_by_grouped", {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            lib_sexe: lesChamp.lib_sexe,
            id_stat_plt: lesChamp.id_stat_plt,
            id_operateur: lesChamp.id_operateur,
            id_type_plt: lesChamp.id_type_plt,
            id_dec: lesChamp.id_dec,
            id_service: lesChamp.id_service,
            id_canal: lesChamp.id_canal,
            date_debut: timestamps.date_debut,
            date_fin: timestamps.date_fin,
          },
        })
        .then((response) => {
          setDataPlainte(response.data);
        })
        .catch((error) => {
          console.log("erreur", error);
        });
    } else {
      axios
        .get("https://apipigru.leavesmartconsulting.com/pigru/plainte/get_by_grouped", {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            lib_sexe: lesChamp.lib_sexe,
            id_stat_plt: lesChamp.id_stat_plt,
            id_operateur: lesChamp.id_operateur,
            id_type_plt: lesChamp.id_type_plt,
            id_dec: lesChamp.id_dec,
            id_service: lesChamp.id_service,
            id_canal: lesChamp.id_canal,
            date_debut: timestamps.date_debut,
            date_fin: timestamps.date_fin,
          },
        })
        .then((response) => {
          switch (typPlainte) {
            case "plainte fondee":
              let lesPlainteFondee = response?.data?.filter(
                (plainte) => plainte.Statut_plainte.id_stat_plt !== 3
              );
              setDataPlainte(lesPlainteFondee);
              break;
            case "plainte non fondee":
              let lesPlainteNonFondee = response?.data?.filter(
                (plainte) => plainte.Statut_plainte.id_stat_plt === 3
              );
              setDataPlainte(lesPlainteNonFondee);
              break;
          }
        })
        .catch((error) => {
          console.log("erreur", error);
        });
    }
  };

  const recupererPlaintesSystem = async () => {
    setLoading(true);
    try {
      if (timestamps?.date_debut > timestamps?.date_fin) {
        Swal.fire({
          title: "Avertissement",
          text: "La date de debut est supérieur à la date de fin",
          icon: "warning",
        });
        // setLoading(false);
      } else if (verificate && plainteFondeeOuNon === "") {
        recupereDonneePlainte();
      } else if (verificate && plainteFondeeOuNon !== "") {
        switch (plainteFondeeOuNon) {
          case "plainte fondee":
            recupereDonneePlainte("plainte fondee");
            break;
          case "plainte non fondee":
            recupereDonneePlainte("plainte non fondee");
            break;
        }
      } else if (!verificate && plainteFondeeOuNon !== "") {
        switch (plainteFondeeOuNon) {
          case "plainte fondee":
            let lesPlainteFondee = allPlainte?.filter(
              (plainte) => plainte.Statut_plainte.id_stat_plt !== 3
            );
            setDataPlainte(lesPlainteFondee);

            break;
          case "plainte non fondee":
            let lesPlainteNonFondee = allPlainte?.filter(
              (plainte) => plainte.Statut_plainte.id_stat_plt === 3
            );
            setDataPlainte(lesPlainteNonFondee);
            break;
        }
      } else {
        Swal.fire({
          title: "Avertissement",
          text: "Veullez selectionner un element ",
          icon: "warning",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Erreur",
        text: "Une erreur est subvenue lors de la recherche",
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const user = useSelector(getUserInfo);

  //   useEffect(() => {
  //     if (user?.id) {
  //       localStorage.setItem("user_id", user?.id);
  //     }
  //     if (
  //       localStorage.getItem("user_id") === "" ||
  //       !localStorage.getItem("user_id")
  //     ) {
  //       window.location.href = "/";
  //     }
  //   }, []);

  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Plaintes</Link>
              </li>
              {/* <li className="breadcrumb-item active" aria-current="page">Finance Monitoring</li> */}
            </ol>
            <h4 className="main-title mb-0">Statistiques des plaintes</h4>
          </div>
        </div>

        <Row className="g-3">
          <Col xl="12">
            <Row className="g-2">
              <Col sm="12" xl="12">
                <Card className="card-one" style={{ height: "100px" }}>
                  <Card.Body className="overflow-hidden px-0">
                    <div className="finance-info p-3 p-xl-4 pb-4">
                      <label className="fs-sm fw-medium mb-2 mb-xl-1">
                        Nombre de plaintes
                      </label>

                      <h1 className="finance-value">
                        {dataPlainte?.length === 0 ? 0 : dataPlainte?.length}
                      </h1>

                      {/* <p className="w-50 fs-sm mb-2 mb-xl-4 d-none d-sm-block">
                        Liste totale des plaintes entre deux dates suivant un
                        opérateur
                      </p> */}
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              <Col xl="12" className="d-flex flex-column gap-2">
                <Row className="g-2">
                  <Col sm="12" xl="6" className="d-flex flex-column">
                    <Form.Label htmlFor="dateDebut">
                      Date de début *:
                    </Form.Label>

                    <Form.Control
                      id="dateDebut"
                      type="date"
                      placeholder="Date de début"
                      value={lesChamp.date_debut}
                      onChange={(e) =>
                        recuperateurGeneral("date_debut", e.target.value)
                      }
                    />
                    {errorMessage.msgDebut && (
                      <span className="text-danger">
                        {errorMessage.msgDebut}
                      </span>
                    )}
                  </Col>

                  <Col sm="12" xl="6" className="d-flex flex-column">
                    <Form.Label htmlFor="dateDebut">Date de fin *:</Form.Label>

                    <Form.Control
                      id="dateDebut"
                      type="date"
                      placeholder="Date de fin"
                      value={lesChamp.date_fin}
                      onChange={(e) =>
                        recuperateurGeneral("date_fin", e.target.value)
                      }
                    />
                    {errorMessage.msgFin && (
                      <span className="text-danger">{errorMessage.msgFin}</span>
                    )}
                  </Col>

                  <Col sm="12" xl="6" className="d-flex flex-column">
                    <Form.Label htmlFor="statut">Statut :</Form.Label>

                    <Form.Select
                      id="statut"
                      value={lesChamp.id_stat_plt}
                      onChange={(e) =>
                        recuperateurGeneral("id_stat_plt", e.target.value)
                      }
                    >
                      <option value="">Choisir</option>

                      {statustsPlainte?.map((statut, key) => {
                        if (
                          statut.id_stat_plt !== 8 &&
                          statut.id_stat_plt !== 9
                        ) {
                          return (
                            <option key={key} value={statut.id_stat_plt}>
                              {statut.libelle}
                            </option>
                          );
                        } else {
                          return null; // Retour par défaut lorsque la condition n'est pas satisfaite
                        }
                      })}
                    </Form.Select>
                  </Col>

                  <Col sm="12" xl="6" className="d-flex flex-column">
                    <Form.Label htmlFor="op">Opérateur :</Form.Label>

                    <Form.Select
                      id="op"
                      value={lesChamp.id_operateur}
                      onChange={(e) =>
                        recuperateurGeneral("id_operateur", e.target.value)
                      }
                    >
                      <option value="">Choisir</option>
                      {opPlainte?.map((ope, key) => (
                        <option key={key} value={ope.id_operateur}>
                          {ope.nom_operateur}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>

                  <Col sm="12" xl="4" className="d-flex flex-column">
                    <Form.Label htmlFor="service">Service :</Form.Label>

                    <Form.Select
                      id="service"
                      value={lesChamp.id_service}
                      onChange={(e) =>
                        recuperateurGeneral("id_service", e.target.value)
                      }
                    >
                      <option value="">Choisir</option>
                      {allService?.map((serv, key) => (
                        <option key={key} value={serv.id_service}>
                          {serv.lib_service}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>

                  <Col sm="12" xl="4" className="d-flex flex-column">
                    <Form.Label htmlFor="categorie">
                      Catégorie de plainte :
                    </Form.Label>

                    <Form.Select
                      id="categorie"
                      value={lesChamp.id_type_plt}
                      onChange={(e) =>
                        recuperateurGeneral("id_type_plt", e.target.value)
                      }
                    >
                      <option value="">Choisir</option>
                      {typePlaintes?.map((typ, key) => (
                        <option key={key} value={typ.id_type_plt}>
                          {typ.lib_type_plt}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col sm="12" xl="4" className="d-flex flex-column">
                    <Form.Label htmlFor="categorie">
                      Source de prevenance :
                    </Form.Label>

                    <Form.Select
                      id="categorie"
                      value={lesChamp.id_canal}
                      onChange={(e) =>
                        recuperateurGeneral("id_canal", e.target.value)
                      }
                    >
                      <option value="">Choisir</option>
                      {canauxRecept?.map((provenance, key) => (
                        <option key={key} value={provenance.id_canal}>
                          {provenance.lib_canal}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>

                  <Col sm="12" xl="4" className="d-flex flex-column">
                    <Form.Label htmlFor="decision">Décision :</Form.Label>

                    <Form.Select
                      id="decision"
                      value={lesChamp.id_dec}
                      onChange={(e) =>
                        recuperateurGeneral("id_dec", e.target.value)
                      }
                    >
                      <option value="">Choisir</option>
                      {allDecision?.map((dec, key) => (
                        <option key={key} value={dec.id_dec}>
                          {dec.lib_dec}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>

                  <Col sm="12" xl="4" className="d-flex flex-column">
                    <Form.Label htmlFor="sex">Sexe :</Form.Label>

                    <Form.Select
                      id="sex"
                      value={lesChamp.lib_sexe}
                      onChange={(e) =>
                        recuperateurGeneral("lib_sexe", e.target.value)
                      }
                    >
                      <option value="">Choisir</option>
                      <option value="M">Masculin</option>
                      <option value="F">Féminin</option>
                    </Form.Select>
                  </Col>
                  <Col sm="12" xl="4" className="d-flex flex-column">
                    <Form.Label htmlFor="fondeOuNon">
                      Plainte fondée ou non :
                    </Form.Label>

                    <Form.Select
                      id="fondeOuNon"
                      value={plainteFondeeOuNon}
                      onChange={(e) => setPlainteFondeeOuNon(e.target.value)}
                    >
                      <option value="">Choisir</option>
                      <option value="plainte fondee">Plainte fondée</option>
                      <option value="plainte non fondee">
                        Plainte non fondée
                      </option>
                    </Form.Select>
                  </Col>

                  <Col className="d-flex flex-row justify-content-center g-2">
                    <Row sm="12" xl="12" className="g-2">
                      <Col>
                        <Button
                          variant="danger"
                          onClick={() => viderEtRafraichi()}
                        >
                          Annuler
                        </Button>
                      </Col>
                      <Col>
                        {" "}
                        <Button
                          variant="success"
                          onClick={() => recupererPlaintesSystem()}
                          disabled={loading}
                        >
                          {loading ? (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          ) : (
                            <span>Envoyer</span>
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col sm="12" xl="12">
                <TablePlainte plaintes={dataPlainte} />
              </Col>
            </Row>
          </Col>
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  );
}
