import React from "react";
import "./stylePlainte.css";
import moment from "moment";
import { useGetServicesQuery } from "../../redux/apiSlice/plainteApiSlice";
import { Stack } from "rsuite";

function TableDetails({ mycomplaint }) {
  // console.log("mycomplaint", mycomplaint)

  const {data: service = [], isLoading: loadservice} = useGetServicesQuery()
  // console.log("service", service)

  const titreStyle = {
    fontSize: "1.7em", // Taille de la police
    fontWeight: "bolder", // Gras
    // color: "#1f5a92", // Couleur du texte (par exemple, bleu Bootstrap)
    // Ajoutez d'autres propriétés de style au besoin
    // fontFamily: "EB Garamond",
  
  };

  const soustitreStyle = {
    fontSize: "1.4em", // Taille de la police
    fontWeight: "bolder", // Gras
    // color: "#1f5a92", // Couleur du texte (par exemple, bleu Bootstrap)
    // Ajoutez d'autres propriétés de style au besoin
    //  fontFamily: "EB Garamond",
  };

  const libelleStyle = {
    fontSize: "0.9em", // Taille de la police
    fontWeight: "bolder", // Gras
    // color: "#1f5a92", // Couleur du texte (par exemple, bleu Bootstrap)
    // Ajoutez d'autres propriétés de style au besoin
    // backgroundColor: 'rgba(31,90,146, 0.4)'
    // fontFamily: "EB Garamond",
  };

  const dataStyle = {
    fontSize: "0.9em", // Taille de la police
    color: "#000", // Couleur du texte (par exemple, bleu Bootstrap)
    // Ajoutez d'autres propriétés de style au besoin
    // backgroundColor: 'rgba(31,90,146, 0.4)'
    // fontFamily: "EB Garamond",
  };

  const formatDate = (data) => {
    const timestamp = typeof data === "string" ? parseInt(data, 10) : data;

    const date = new Date(timestamp);

    if (isNaN(date.getTime())) {
      console.error("Invalid Date:", timestamp);
      return "Date invalide";
    }

    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();

    return formattedDate;
  };
  return (
    <div className="container_tab_details">
      <h3 style={titreStyle}>FICHE DE PLAINTE</h3>

      <div className="container row p-3">
        <div className="col-7">
          <span style={soustitreStyle}> Numéro de suivi :</span>
          <span style={{fontSize : 18, color: "#000"}}> {mycomplaint.num_suivi_plt}</span>
        
        </div>
        <div className="col-4 text-right" >
        <span style={soustitreStyle}>Date de soumission :</span> 
        <span style={{fontSize : 18, color: "#000"}}>{" "}{formatDate(mycomplaint.date_plt)}</span>
        </div>
      </div>

      <div className="container row">
        <div className="col-lg-12 col-md-12">
          <h6 className="" style={soustitreStyle}>
            PREALABLES SUR LA PLAINTE
          </h6>

          <table class="table table-bordered border-primary">
            <tr>
              <td class="border col-4 " style={libelleStyle}>
                Référence de la plainte chez l’opérateur :
              </td>
              <td class="border col-7" style={dataStyle}>
                {mycomplaint?.ref_plt}
              </td>
            </tr>
            <tr>
              <td class="border col-4  " style={libelleStyle}>
                Réponse de l’opérateur :
              </td>
              <td class="border col-7" style={dataStyle}>
                
                {mycomplaint?.repondu == 1 ? "Oui mais pas satisfait(e)" : "Non"}
              </td>
            </tr>
            <tr>
              <td class="border col-4" style={libelleStyle}>
                Catégorie de plainte :
              </td>
              <td class="border col-7" style={dataStyle}>
                {" "}
                {mycomplaint?.Type_plainte.lib_type_plt}
              </td>
            </tr>
            <tr>
              <td class="border col-4" style={libelleStyle}>
                Opérateur concerné :
              </td>
              <td class="border col-7" style={dataStyle}>
                {mycomplaint?.Operateur?.nom_operateur}
              </td>
            </tr>
            <tr>
              <td class="border col-4" style={libelleStyle}>
                Service concerné :
              </td>
              <td class="border col-7" style={dataStyle}>
              {mycomplaint?.id_service && service.find(item => {
  return item.id_service === mycomplaint?.id_service;
})?.lib_service}
              </td>
            </tr>
            <tr>
              <td class="border col-4" style={libelleStyle}>
                Canal de réception :
              </td>
              <td class="border col-7" style={dataStyle}>
              {mycomplaint?.Canal_plainte?.lib_canal}
              </td>
            </tr>
            {/* <tr>
              <td class="border col-4" style={libelleStyle}>
                Motif de la plainte :
              </td>
              <td class="border col-7" style={dataStyle}>
                {mycomplaint?.desc_plt != null
                  ? mycomplaint?.desc_plt
                  : "NEANT"}
              </td>
            </tr> */}
          </table>
        </div>

        <div className="col-lg-12 col-md-12">
          <h6 style={soustitreStyle}>INFORMATIONS SUR LE PLAIGNANT</h6>

          <span style={libelleStyle}>
            {mycomplaint?.type_personne === 0
              ? "Personne physique"
              : "Personne morale"}
          </span>

          <table class="table table-bordered border-primary">
            <tr>
              <td class="border col-4" style={libelleStyle}>
                Raison sociale
              </td>

              <td class="border col-7" style={dataStyle}>
                {mycomplaint?.raison != null ? mycomplaint.raison : "-"}
              </td>
            </tr>
            <tr>
              <td class="border col-4" style={libelleStyle}>
                Nom du plaignant :
              </td>
              <td class="border col-7" style={dataStyle}>
                {mycomplaint?.nom_plt}
              </td>
            </tr>
            <tr>
              <td class="border col-4" style={libelleStyle}>
                Prénoms du plaignant :
              </td>
              <td class="border col-7" style={dataStyle}>
                {mycomplaint?.prenom_plt}
              </td>
            </tr>
            <tr>
              <td class="border col-4" style={libelleStyle}>
                Téléphone :
              </td>
              <td class="border col-7" style={dataStyle}>
                {mycomplaint?.tel_plt}
              </td>
            </tr>
            <tr>
              <td class="border col-4" style={libelleStyle}>
                Email :
              </td>
              <td class="border col-7" style={dataStyle}>
            
                {mycomplaint?.email_plt == null ? "-" : mycomplaint?.email_plt}
              </td>
            </tr>
            <tr>
              <td class="border col-4" style={libelleStyle}>
                Adresse de résidence :
              </td>
              <td class="border col-7" style={dataStyle}>
                {mycomplaint?.adresse_plt}
              </td>
            </tr>
            <tr>
              <td class="border col-4" style={libelleStyle}>
              Profession :
              </td>
              <td class="border col-7" style={dataStyle}>
                {mycomplaint?.profession}
              </td>
            </tr>
            <tr>
              <td class="border col-4" style={libelleStyle}>
                Nationalité :
              </td>
              <td class="border col-7" style={dataStyle}>
                {mycomplaint?.nationalite}
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div className="container row">
        <div className="col-lg-12 col-md-12">
          <h6 style={soustitreStyle}>DETAILS DE LA PLAINTE</h6>

          <table class="table table-bordered border-primary">
            <tr>
              <td class="border col-4" style={libelleStyle}>
                Résumé de la plainte :
              </td>
              <td class="border col-7" style={dataStyle}>
                {mycomplaint?.desc_plt}
              </td>
            </tr>
            <tr>
              <td class="border col-4" style={libelleStyle}>
                Date du premier constat :
              </td>
              <td class="border col-7" style={dataStyle}>
                {moment(mycomplaint.date_constat).format("DD/MM/YYYY")}
              </td>
            </tr>
            <tr>
              <td class="border col-4" style={libelleStyle}>
                Canal de retour souhaité :
              </td>
              <td class="border col-7" style={dataStyle}>
                {mycomplaint?.canal_retour === "email" ? "E-mail" : "Téléphone"}
              </td>
            </tr>
          </table>
        </div>

        {(mycomplaint?.pieces_plaintes && mycomplaint.pieces_plaintes.length > 0) && 
          <div className="col-lg-12 col-md-12">
            <h6 style={soustitreStyle}>PIECES JOINTES</h6>

            <Stack direction="row" spacing={5}>
              {mycomplaint.pieces_plaintes.map((piece, index) => (
                <a key={index} target="_blank" href={`https://apipigru.leavesmartconsulting.com/file/${piece.nom_piece}`}>
                  Pièce Jointes {++index}
                </a>
              ))}
            </Stack>
          </div>
        }

      </div>
    </div>
  );
}

export default TableDetails;
