import React from "react";
import { Button, Spinner } from "react-bootstrap";
import moment from "moment";
import { useGetHistoriquePlainteQuery } from "../../../redux/apiSlice/plainteApiSlice";

const Historique = (props) => {
  const { plainte } = props;

  const { data: historiques = [], isLoading } = useGetHistoriquePlainteQuery(
    plainte.num_suivi_plt
  );

  const soustitreStyle = {
    fontSize: "1.4em", // Taille de la police
    fontWeight: "bolder", // Gras
    color: "#1f5a92", // Couleur du texte (par exemple, bleu Bootstrap)
    // Ajoutez d'autres propriétés de style au besoin
  };

  const tabletitreStyle = {
    fontSize: "1.0em", // Taille de la police
    fontWeight: "bolder", // Gras
    color: "#1f5a92", // Couleur du texte (par exemple, bleu Bootstrap)
    // Ajoutez d'autres propriétés de style au besoin
  };

  const contenuStyle = {
    fontSize: "1.0em", // Taille de la police
    fontWeight: "normal", // Gras
    color: "#000", // Couleur du texte (par exemple, bleu Bootstrap)
    // Ajoutez d'autres propriétés de style au besoin
  };

  if (isLoading) {
    return (
      <div class="card">
        <Button variant="primary" disabled style={{ height: 200 }}>
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          Loading...
        </Button>
      </div>
    );
  }

  return (
    plainte.id_stat_plt > 1 && (
    <>
      <h4 style={soustitreStyle}>&nbsp; Historique de plaintes</h4>
      <div class="card ml-5">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table mb-0 table-striped table-hover">
              <thead>
                <tr>
                  <th style={tabletitreStyle} scope="col-1">#</th>
                  <th style={tabletitreStyle} scope="col-6">Statut</th>
                  <th style={tabletitreStyle} scope="col-5">Date de notification</th>
                </tr>
              </thead>

              <tbody>
                {historiques.map((historie, key) => (
                  <tr key={key}>
                    <td  class=" col-1" style={contenuStyle} scope="row">{++key}</td>
                    <td class=" col-6"  className="text-left" style={contenuStyle}>{historie.Statut_plainte.libelle}</td>
                    <td  class=" col-5" style={contenuStyle}>{moment(historie.createdAt).format("DD/MM/YYYY")}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
    )
  );
};

export default Historique;
