import React,  { useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import '../styleNumerotation.css'
import 'jspdf-autotable';

export default function TableStructurePNM() {

   const data = [
      {
        tranches : "Tranches A=0",
        services : "Préfixes d'accès"
      },
      {
        tranches : "00",
        services : "Préfixes d'accès à l'international"
      },
      {
        tranches : "0X ou 0XY",
        services : "Préfixes de sélection de transporteurs"
      },
      {
        tranches : "Tranches A=1 (1XY ou 1XYZ)",
        services : "Services d'urgences, d'assistance ou d'intérêt général"
      },
      {
        tranches : "Tranches A=1 (1XY ou 1XYZ)",
        services : "Services d'urgences et d'assistance ou d'intérêt général"
      },
      {
        tranches : "10Y ou 10YZ",
        services : "Services d'urgences et d'assistance"
      },
      {
        tranches : "11Y",
        services : "Services de protection civile de sécurité"
      },
      {
        tranches : "12Y ou 12YZ",
        services : "Services d'urgences et d'assistance"
      },
      {
        tranches : "13Y",
        services : "Services de renseignements et d'assistance"
      },
      {
        tranches : "14Y à 15Y ou 14YZ à 15YZ",
        services : "Services d'urgences et d'assistance"
      },
      {
        tranches : "16Y",
        services : "Services sociaux"
      },
      {
        tranches : "17Y à 19Y ou 17YZ à 19YZ",
        services : "Services d'urgences et d'assistance"
      },
      {
        tranches : "Tranche A=2", 
        services : "Numéros de communications interpersonnelles destinés au services géographique(réseau fixes)"
      },
      {
        tranches : "Tranche A=3",
        services : "Codes de routage de routage de la portabilté des numéros"
      },
      {
        tranches : "Tranche A=4",
        services : "Numéros de communications interpersonnelles destinés au services géographique(réseau fixes)"
      },
      {
        tranches : "Tranche A=5",
        services : "Numéros de communications interpersonnelles destinés au services géographique(réseau fixes)"
      },
      {
        tranches : "Tranche A=6",
        services : "Numéros de communications interpersonnelles destinés au services géographique(réseau fixes)"
      },
      {
        tranches : "Tranche A=7 (7XY ou 7XYZ)",
        services : "Numéros courts d'accès aux services à valeur ajoutée)"
      },
      {
        tranches : "Tranche A=8",
        services : "Numéros long d'accès aux services à valeur ajoutée et prestataires de services"
      },
      {
        tranches : "Tranche A=9",
        services : "Numéros de communications interpersonnelles destinés au services géographique(réseau fixes)"
      },
    ]
  

  const [globalFilter, setGlobalFilter] = useState([]);

    const dt = useRef(null);
 
    const cols = [
        { field: 'tranches', header: 'Tranches', sortable: true },

        { field: 'services', header: "Services", sortable: true },
        
    ];
   

    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

   
    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

  

    const exportPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);

                doc.autoTable(exportColumns, data);
                doc.save('Ressources.pdf');
            });
        });
    };

    

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(data);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'Ressources');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const header = (
        <div className="container_header_data_table">
            <div className="flex align-items-center">
                
                <div className="p-input-icon-left ml-3">
                    <i className="pi pi-search" />
                    <InputText value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher" />
                </div>
            </div>
            <div className='container_export'>
                <Button type="button" icon="pi pi-file-pdf" rounded onClick={exportPdf} className="" data-pr-tooltip="PDF" style={{borderRadius : 30}} severity="secondary" size="small"/>
                <Button type="button" icon="pi pi-file-excel" className=""severity="success" rounded onClick={exportExcel} data-pr-tooltip="Excel" style={{borderRadius : 30}} size="small"/>
                <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} className="" data-pr-tooltip="CSV" style={{borderRadius : 30}} size="small"/>
            </div>
        </div>
    );


  return(
        
        <div className="card" style={{width: '100%'}}>
            <Tooltip target=".export-buttons>button" position="bottom" />
            <style>{`
    .table-bordered {
        border-collapse: collapse; // Fusionner les bordures adjacentes
    }

    .table-bordered th,
    .table-bordered td {
        border: 1px solid black; // Bordures pleines pour les cellules
        padding: 8px; // Espacement interne pour les cellules
        font-size: 15px; // Taille de la police pour toutes les cellules
    }

    .table-row td {
        border-bottom: 1px solid black; // Bordure inférieure pleine pour les cellules de la ligne
    }
`}</style>



<DataTable
    ref={dt}
    value={data}
    header={header}
    tableStyle={{ minWidth: '100%', fontSize: 12 }}
    globalFilter={globalFilter}
    emptyMessage="Pas de résultat."
    paginator
    rows={10}
    tableClassName="table-bordered" // Ajout de la classe pour les bordures des colonnes
    rowClassName="table-row" // Ajout de la classe pour les bordures des lignes
>
    {cols.map((col, index) => (
        <Column
            key={index}
            field={col.field}
            header={col.header}
            sortable={col.sortable}
        />
    ))}
</DataTable>


        </div>
    )

    ;
}
