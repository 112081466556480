import React, {useEffect} from "react";
import { Button, Spinner } from "react-bootstrap";
import moment from "moment";
import { useGetAvisConseilPlainteQuery } from "../../../redux/apiSlice/plainteApiSlice";

const AvisConseil = (props) => {
  const { plainte } = props;

  const { data: avis = [], isLoading } = useGetAvisConseilPlainteQuery(
    {id_plt : plainte.id_plt}
  );
// console.log('plainte.id_pltavis', plainte.id_plt)
// console.log('avis', avis)
  const soustitreStyle = {
    fontSize: '1.4em', // Taille de la police
    fontWeight: 'bolder', // Gras
    color: '#1f5a92', // Couleur du texte (par exemple, bleu Bootstrap)
    // Ajoutez d'autres propriétés de style au besoin
  };

  const tabletitreStyle = {
    fontSize: '1.0em', // Taille de la police
    fontWeight: 'bolder', // Gras
    color: '#1f5a92', // Couleur du texte (par exemple, bleu Bootstrap)
    // Ajoutez d'autres propriétés de style au besoin
  };


  const contenuStyle = {
    fontSize: '1.0em', // Taille de la police
    fontWeight: 'normal', // Gras
    color: '#000', // Couleur du texte (par exemple, bleu Bootstrap)
    // Ajoutez d'autres propriétés de style au besoin
  };

  const formateDatePlainte = (laDate) => {
    const dateObject = new Date(laDate);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = dateObject.toLocaleDateString("fr-FR", options);
    return formattedDate;
  };

  useEffect(() => {
   
    
  }, [avis]);

  if (isLoading) {
    return (
      <div class="card">
        <Button variant="primary" disabled style={{ height: 200 }}>
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          Loading...
        </Button>
      </div>
    );
  }
 
  return (
    plainte.id_stat_plt == 10  && (
      <>
        <h4 style={soustitreStyle}>&nbsp; Avis du conseil</h4>
        <div class="card ml-5">
          <div class="card-body">
            <div class="table-responsive ">
              <table class="table mb-0 table-striped table-hover">
                <thead>
                  <tr>
                    <th style={tabletitreStyle} scope="col-1">#</th>
                    <th style={tabletitreStyle} scope="col-6">Avis</th>
                    <th style={tabletitreStyle} scope="col-5">Date</th>
                
                  </tr>
                </thead>

                <tbody>
                  {avis.map((avis, key) => (
                    <tr key={key}>
                      <td class=" col-1" style={contenuStyle} scope="row">{++key}</td>
                      <td class=" col-4" style={contenuStyle}>{avis.content}</td>
                      {/* <td class=" col-4" style={contenuStyle}>{formateDatePlainte(avis.date_avis)}</td> */}
                      <td class=" col-4" style={contenuStyle}>{moment(avis.createdAt).format("DD/MM/YYYY")}</td>
                      
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default AvisConseil;
