import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";
import Spinner from "react-bootstrap/Spinner";
import { Button } from "primereact/button";
import { Button as Btn, Form, Table, Modal, Badge } from "react-bootstrap";
import "../styleNumerotation.css";
import Select from "react-select";
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import Swal from "sweetalert2";
import { format } from "date-fns";
import "jspdf-autotable";
import moment from "moment";
import {
  useGetMncQuery,
  useUpdateMncMutation,
  useCreateAttrMncMutation,
  useUpdateAttrMncMutation,
  useDeleteAttrMncMutation,
  useGetAttrMncByUSSDQuery,
  useGetClientsQuery,
  useGetStatutNumQuery,

  useGetAttrMncQuery,

  useGetServiceTrancheQuery
} from "../../../redux/apiSlice/numerotationApiSlice";

import { useGetOperateursQuery } from "../../../redux/apiSlice/operateurApiSlice";
import AjoutClientModal from "../AjoutClientModal";

export default function TableEtatMnc(props) {

  const [mnc, setMnc] = useState([]);
  const [id_mnc_attr, setId_mnc_attr] = useState(0);
  const [donne, setDonne] = useState(0);

  const { data : listeMnc =[], isLoading } = useGetMncQuery();

  const [updateMnc] = useUpdateMncMutation();

  const [createAttrMnc] = useCreateAttrMncMutation();
  const [updateAttrMnc] = useUpdateAttrMncMutation();
  const [deleteAttrUss] = useDeleteAttrMncMutation();
  const { data: oneAttrUSSd = [] } = useGetAttrMncByUSSDQuery(id_mnc_attr);

  const { data: services = [] } = useGetServiceTrancheQuery(id_mnc_attr);

  const { data: getAttr = [] } = useGetAttrMncQuery();
  const { data: statutNum = [], isLoading: loadStatutNum } =
    useGetStatutNumQuery();
  const { data: operateur = [] } = useGetOperateursQuery();
  const { data: clients = [] } = useGetClientsQuery();

  const [globalFilter, setGlobalFilter] = useState([]);
  const [ctrl, setCtrl] = useState(0);
  const [infos, setInfos] = useState();

  const [expState, setExpState] = useState([]);

  const init = {
    id_occupant: 0,
    id_mnc: 0,
    num_decision: "",
    service_implemente: "",
    commentaire: "",
    date_attr: "",
    type_occupant: 0,
    id_service: 0,
  };

  const [form, setForm] = useState({ ...init });
  const [statut_num, setStatut_num] = useState(0);
  const [objetForm, setObjetForm] = useState({});

  let monTab =[]

  // modal
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setForm(init)
    setShow(false)
  };

  const handleShow = (rowData) => {
  
    setCtrl(rowData.id_stat_num);
 const retour = getAttr.find((item)=>(item.id_mnc  == rowData.id_mnc))
       setForm({...form, id_mnc : rowData.id_mnc})
      if (retour) {
        setForm(retour)
      } 
    setShow(true);
  };

  const [ajoutClient, setAjoutClient] = useState(false);
  const handleAjoutClient = () => setAjoutClient(!ajoutClient);

  // modal
  const [showInfOcup, setShowInfOcup] = useState(false);
  const handleInfOcup = () => setShowInfOcup(!showInfOcup);

  // function formaterDate(dateStr) {
  //   const date = new Date(dateStr);
  //   return format(date, "dd-MM-yyyy");
  // }

  const save = async () => { 
    handleClose();

    if (statut_num == 0) {

      setStatut_num(0)
      Swal.fire({
        title: "ERREUR",
        text: "Veuillez choisir un statut",
        icon: "error",
      });

    } else {
      Swal.fire({
        title: "Gestion des  MNC",
        text: "Voulez-vous continuer ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
      }).then(async (result) => {
        if (result.isConfirmed === true) {
  
          if (form?.id_attr_mnc) {
  
            await updateAttrMnc({...form, id_stat_num : statut_num})
              .unwrap()
              .then((payload) => {
                Swal.fire({
                  title: "SUCCES",
                  text: "réussie",
                  icon: "success",
                });
  
                window.location.reload();
              })
              .catch((error) => {
                console.log('error1', error)
                Swal.fire({
                  title: "ERREUR",
                  text: "Une erreur s'est produite , veuillez réessayer",
                  icon: "error",
                });
              });
          } else {
        
          if ((statut_num == 3 && form.id_occupant == "") || (statut_num == 4 && form.id_occupant == "")) {
            Swal.fire({
              title: "ERREUR",
              text: "Veuillez choisir un opérateur ou un client",
              icon: "error",
            });
          } else {
            createAttrMnc({...form, id_stat_num : statut_num})
            .unwrap()
            .then((rsul) => {
              Swal.fire({
                title: "SUCCES",
                text: "réussie",
                icon: "success",
              });

              window.location.reload();
            })
            .catch((error) => {
              console.log('error3', error)
              Swal.fire({
                title: "ERREUR",
                text: "Une erreur s'est produite , veuillez réessayer",
                icon: "error",
              });
            });
          }
              
          }
        }
      });
    }
    
  };

  const handleChangeAtt = (value) => {
    setForm({ ...form, id_occupant: parseInt(value.value) });
  };

  const infOccupant = (rowData) => {

    setId_mnc_attr(rowData.id_mnc);
    const resul = getAttr.find((item)=>(item.id_mnc == rowData.id_mnc))
    setInfos(resul)
    handleInfOcup();
  };

  const giveInfOp = (data, operateur, clients) => {
    if (parseInt(data?.type_occupant) === 0) {
      const result = operateur.find((item) => item.id_operateur === data?.id_occupant);
      return result?.nom_operateur;
    } else {
      const result = clients.find((item) => item.id_clt === data?.id_occupant);
      return result?.nom_clt;
    }
  };

  const expoData = (data) => {
   
    let cmpt = 0
    monTab=[]
     data.map((item) => {
      cmpt++
      const rsul = getAttr.find((oc) => oc.id_mnc === item.id_mnc);
    
      if (rsul) {
        monTab.push( {
          mnc: item.lib_mnc,
          etat: stat(parseInt(item.id_stat_num)),
          operateur: giveInfOp(rsul, operateur, clients),
          date_attr: rsul?.date_attr == null ? "" : rsul?.date_attr == "" ? "" : moment(rsul?.date_attr).format("DD/MM/YYYY"),
          commentaire: rsul.commentaire || "-"
        });
      } else {
        monTab.push( {
          mnc: item.lib_mnc,
          etat: stat(parseInt(item.id_stat_num)),
          operateur: "-",
          date_attr: "-",
          commentaire: "-"
        });
      }

      if (cmpt == data.length) {
        console.log('monTab', monTab)
        setExpState(monTab)
     
      }
    });
  
    
  };

  const chargeMnc = (value)=>{

    if (value == 0) {
      setDonne(0)
      expoData(listeMnc) 
    } else {
      setDonne(1)
      const rsul = listeMnc.filter((item)=> item.id_stat_num == value) 

      setMnc(rsul)
      expoData(rsul) 
    }
  
     
    }

  useEffect(() => {
    setObjetForm(oneAttrUSSd[0]);
  }, [oneAttrUSSd]);

  const formatDate = (data) => {
    const timestamp = typeof data === "string" ? parseInt(data, 10) : data;
    const date = new Date(timestamp);
    if (isNaN(date.getTime())) {
      return "Date invalide";
    }

    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();

    return formattedDate;
  };

  const formatVu = (timestamp) => {
    // Convertir le timestamp en objet Date

    const time = parseInt(timestamp);
    const date = new Date(time);

    // Formater la date au format ISO 8601 (YYYY-MM-DD)
    const dateString = date.toISOString().split("T")[0];

    return dateString;
  };

  const dt = useRef(null);

  const cols = [
    { field: "num", header: "N°", sortable: true },
    { field: "lib_mnc", header: "Mnc", sortable: true },
    { field: "op", header: "Opérateur", sortable: true },
    { field: "dt", header: "Date d'attribution", sortable: true },
    { field: "num_dec", header: "Numéro de décision", sortable: true },
    { field: "cmt", header: "Commentaire", sortable: true },
    { field: "statut", header: "Statut ", sortable: true },
    { field: "actions", header: "Actions", sortable: false },
  ];

  const colsExpo = [
  { field: "mnc", header: "MNC", sortable: true },
    { field: "etat", header: "Etat", sortable: true },
    { field: "operateur", header: "Operateur ", sortable: true },
    { field: "date_attr", header: "Date d'attribution", sortable: false },
    { field: "commentaire", header: "Commentaire", sortable: false },
    
  ];

  const exportColumns = colsExpo.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const badge = (value) => {
    switch (value) {
      case 1:
        return "dark";
      case 2:
        return "secondary";
      case 3:
        return "warning";
      case 4:
        return "success";
      case 5:
        return "danger";
      case 6:
        return "danger";
      default:
        break;
    }
  };
  const stat = (value) => {
    switch (value) {
      case 1:
        return "Libre";
      case 2:
        return "En instance";
      case 3:
        return "Réservée";
      case 4:
        return "Attribuée";
      case 5:
        return "Bloquée";
      case 6:
        return "Inutilisable";
      default:
        break;
    }
  };



  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);

        doc.autoTable(exportColumns, expState);
        doc.save("Ressources mnc.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(expState);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // saveAsExcelFile(excelBuffer, "Ressources");
    });
  };

  // const saveAsExcelFile = (buffer, fileName) => {
  //   import("file-saver").then((module) => {
  //     if (module && module.default) {
  //       let EXCEL_TYPE =
  //         "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  //       let EXCEL_EXTENSION = ".xlsx";
  //       const data = new Blob([buffer], {
  //         type: EXCEL_TYPE,
  //       });

  //       module.default.saveAs(
  //         data,
  //         fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
  //       );
  //     }
  //   });
  // };

  const header = (
    <div className="container_header_data_table">
      <div className="flex align-items-center">
        <div className="p-input-icon-left ml-3">
          <i className="pi pi-search" />
          <InputText
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
            placeholder="Rechercher"
          />
        </div>
      </div>
      <div className="container_export">
        <Button
          type="button"
          icon="pi pi-file-pdf"
          rounded
          onClick={exportPdf}
          className=""
          data-pr-tooltip="PDF"
          style={{ borderRadius: 30 }}
          severity="secondary"
          size="small"
        />
        <Button
          type="button"
          icon="pi pi-file-excel"
          className=""
          severity="success"
          rounded
          onClick={exportExcel}
          data-pr-tooltip="Excel"
          style={{ borderRadius: 30 }}
          size="small"
        />
        {/* <Button
          type="button"
          icon="pi pi-file"
          rounded
          onClick={() => exportCSV(false)}
          className=""
          data-pr-tooltip="CSV"
          style={{ borderRadius: 30 }}
          size="small"
        /> */}
      </div>
    </div>
  );

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center">
        {rowData.id_stat_num == 2 && (
          <a
            onClick={() => infOccupant(rowData)}
            className="btn btn-success p-2 mr-1"
            style={{ cursor: "pointer" }}
          >
            {/* <i className="ri-eye-line ri-2x" style={{ fontSize: 13 }}></i> */}
            Détails
            <span></span>
          </a>
        )}
        {rowData.id_stat_num == 3 && (
          <a
            onClick={() => infOccupant(rowData)}
            className="btn btn-success p-2 mr-1"
            style={{ cursor: "pointer" }}
          >
           Détails
            <span></span>
          </a>
        )}
        {rowData.id_stat_num == 4 && (
          <a
            onClick={() => infOccupant(rowData)}
            className="btn btn-success p-2 mr-1"
            style={{ cursor: "pointer" }}
          >
            Détails
            <span></span>
          </a>
        )}
       
        {
          <a
            onClick={() => handleShow(rowData)}
            className="btn btn-success p-2 mr-1"
            style={{ cursor: "pointer" }}
          >
            {/* <i className="ri-eye-line ri-2x" style={{fontSize :12}}></i> */}
            Actions
            <span></span>
          </a>
        }
      </div>
    );
  };

  const etatComp = (rowData) => {
    return (
      <div className="flex align-items-center">
        <Badge bg={badge(parseInt(rowData.id_stat_num))}>
          {stat(parseInt(rowData.id_stat_num))}
        </Badge>
      </div>
    );
  };

  const operateurAttr = (rowData) => {
    let op 
    
    if(rowData.id_stat_num == 1){
    op = "-"
    }else{
      
      const retour = getAttr.find((item)=>(item.id_mnc  == rowData.id_mnc))
    
    if (retour) {
      
      if (retour?.type_occupant == 0) {
        
    const rsul = operateur.find((item)=>(item.id_operateur == retour?.id_occupant))
        op = rsul?.nom_operateur
    
      } else {
        const rsul = clients.find((item)=>(item.id_clt == retour?.id_occupant))
        op = rsul?.nom_clt
      }
    
    
    } 
    
    
    }
    
            return (
                <div className="flex align-items-center">
            {op}
                   
                </div>
            );
        };
    
        const dateAttr = (rowData) => {
    let dt 
    
    if(rowData.id_stat_num == 1){
    dt = "-"
    }else{
       const retour = getAttr.find((item)=>(item.id_mnc  == rowData.id_mnc))
    
    if (retour) {
      
    //  dt = formatDate(retour?.date_attr)
  
     dt = retour?.date_attr == null ? "" : moment(retour?.date_attr).format("DD/MM/YYYY")
    
    
    } 
    
    
    }
    
            return (
                <div className="flex align-items-center">
            {dt}
                   
                </div>
            );
        };
        const numDecision = (rowData) => {
    let dt 
    
    if(rowData.id_stat_num == 1){
    dt = "-"
    }else{
       const retour = getAttr.find((item)=>(item.id_mnc  == rowData.id_mnc))
    
    if (retour) {
      
     dt = retour?.num_decision
  
    
    
    } 
    
    
    }
    
            return (
                <div className="flex align-items-center">
            {dt}
                   
                </div>
            );
        };
        const commentaireAttr = (rowData) => {
    let dt 
    
    if(rowData.id_stat_num == 1){
    dt = "-"
    }else{
       const retour = getAttr.find((item)=>(item.id_mnc  == rowData.id_mnc))
    
    if (retour) {
      
     dt = retour?.commentaire
   
    
    
    } 
    
    
    }
    
            return (
                <div className="flex align-items-center">
            {dt}
                   
                </div>
            );
        };

  const rowClassName = (rowData, index) => {
    return index !== mnc.length - 1 ? "table-row" : "";
  };

  const showEtat = (ctrl) => {
    switch (ctrl) {
        case 1:
            const retour = statutNum.filter((item) => (item.id_stat_num != 1))
            return retour;

        default:
            return statutNum;
    }
}


  return (
    <div className="card" style={{ width: "100%" }}>
      <Tooltip target=".export-buttons>button" position="bottom" />
      <style>{`
            .table-row td {
                border-bottom: 1px solid #dee2e6; // Bordure inférieure pour les cellules de la ligne
            }
        `}</style>

<div className="container row">
          
          <div className="col">
    
    <Form.Label>Statut</Form.Label>
    <Form.Select 
    onChange={(e) => chargeMnc(e.target.value)}
    >
  <option value={0}>Tout</option>
  {[
                { label: "Libre", value: 1 },
                { label: "En instance", value: 2 },
                { label: "Réservée", value: 3 },  
                { label: "Attribuée", value: 4 },
                { label: "Bloquée", value: 5 },
                { label: "Inutilisable", value: 6 },
              ]
    .map((type, i) => (
      <option key={i} value={type.value}>
        {type.label}
      </option>
    ))}
  </Form.Select>
  
    
  </div>
            
          </div>

      <>
        {isLoading ? (
          <div class="card">
            <Btn variant="primary" disabled style={{ height: 200 }}>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Loading...
            </Btn>
          </div>
        ) : (
          <DataTable
            ref={dt}
            value={donne == 0 ? listeMnc : mnc}
            header={header}
            tableStyle={{ minWidth: "100%", fontSize: 12 }}
            globalFilter={globalFilter}
            emptyMessage="Pas de résultat."
            paginator
            rows={10}
            rowClassName={rowClassName}
          >
            {cols.map((col, index) => (
              <Column
                key={index}
                field={col.field}
                header={col.header}
                sortable={col.sortable}
                body={
                  col.field === "actions"
                    ? actionBodyTemplate
                    : col.field === "statut"
                    ? etatComp
                    : col.field === "op"
                    ? operateurAttr
                    : col.field === "dt"
                    ? dateAttr
                    : col.field === "num_dec"
                    ? numDecision
                    : col.field === "cmt"
                    ? commentaireAttr
                    : col.field === "num"
                    ? (data, options) => options.rowIndex + 1
                    : null
                }
              />
            ))}
          </DataTable>
        )}
      </>

      {/* ----------------Modal commentaire gestion obligations----------- */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Attribution de USSD</Modal.Title>
        </Modal.Header>
        <Modal.Body>

<div className="container row ">

              <div className=" col mt-3">
                <Form.Label>Statut</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => setStatut_num(e.target.value)}
                >
                   <option value={""}>
                      choisir
                    </option>
                  {
                  showEtat(ctrl).map((item, index) => (
                    <option key={index} value={item.id_stat_num}>
                      {item.libelle}
                    </option>
                  ))}
                </Form.Select>
              </div>
       
</div>
            <div className="container  row">              
              <div className="col">
                <Form.Check
                  type="radio"
                  label="Opérateur"
                  value={0}
                  checked={form.type_occupant === 0}
                  onChange={() =>
                    setForm({ ...form, type_occupant: 0, id_operateur: "" })
                  }
                />
              </div>
              <div className="col">
                <Form.Check
                  type="radio"
                  label="Client"
                  value={1}
                  checked={form.type_occupant === 1}
                  onChange={() =>
                    setForm({ ...form, type_occupant: 1, id_operateur: "" })
                  }
                />
              </div>
              {form.type_occupant === 1 && (
                <div className="col">
                  <AddOutlineIcon
                    size={30}
                    style={{ cursor: "pointer", fontSize: "25px" }}
                    onClick={() => {
                      handleAjoutClient();
                    }}
                  />
                </div>
              )}
              <div style={{ width: "100%" }}>
                {form.type_occupant === 0 && (
                  <Select
                    options={operateur.map((item) => {
                      return {
                        label: item.nom_operateur,
                        value: item.id_operateur,
                      };
                    })}
                    isSearchable={true}
                    onChange={handleChangeAtt}
                  />
                )}

                {form.type_occupant === 1 && (
                  <Select
                    options={clients.map((item) => {
                      return {
                        label: item.nom_clt,
                        value: item.id_clt,
                      };
                    })}
                    isSearchable={true}
                    onChange={handleChangeAtt}
                  />
                )}
              </div>
            </div>
     
          <div className="row container">
          
            <div className="mt-3">
              <Form.Label>Date d'attribution</Form.Label>
              <Form.Control
                type="date"
                // value={
                //   ctrl !== 1 &&

                //   moment(oneAttrUSSd[0]?.date_attr).format('DD-MM-YYYY')
                // }
                onChange={(e) =>
                  setForm({ ...form, date_attr: e.target.value })
                }
              />
            </div>
            <div className="mt-3">
              <Form.Label>No de décision</Form.Label>
              <Form.Control
                type="text"
                placeholder="numéro de décision"
                value={form.num_decision}
                onChange={(e) => setForm({ ...form, num_decision: e.target.value })}
              />
            </div>
            <div className="mt-3">
              <Form.Label>Commentaire</Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                placeholder="commentaire..."
                onChange={(e) =>setForm({ ...form, commentaire: e.target.value })}
                value={form.commentaire}
              ></Form.Control>
            </div>
         
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Btn variant="primary" onClick={handleClose}>
            Annuler
          </Btn>
          <Btn variant="success" onClick={save}>
            Enregistrer
          </Btn>
        </Modal.Footer>
      </Modal>

      {/* ----------------Modal ajout client ----------- */}
      <Modal
        show={ajoutClient}
        onHide={handleAjoutClient}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Ajout Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AjoutClientModal handleAjoutClient={handleAjoutClient} />
        </Modal.Body>
      </Modal>

      {/* ----------------Modal infos occupant----------- */}
      <Modal
        show={showInfOcup}
        onHide={() => handleInfOcup()}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Détails</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="fluid-container">
            <table class="table table-bordered border-primary">
              

                     <tr>
                      <th class="border col-6">Numéro de decision</th>
                      <td class="border col-6">{infos?.num_decision}</td>
                      </tr>
                  
                    <tr>
                    <th class="border col-6">Commentaire</th>
                    <td class="border col-6">{infos?.commentaire}</td>
                      </tr>
                    <tr>
                    <th class="border col-6">Date d'attribution</th>
                    <td class="border col-6">{ infos?.date_attr == null ? "" : moment(infos?.date_attr).format("DD/MM/YYYY")}</td>
                      </tr> 
                    <tr>
                    <th class="border col-6">Opérateur</th>
                    <td class="border col-6">{giveInfOp(infos, operateur, clients)}</td>
                      </tr> 
                     
                       
                  
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleInfOcup}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
