import React, { useEffect, useState } from "react";
import { Form, Card, Col, Nav, ProgressBar, Row } from "react-bootstrap";

import { Link } from "react-router-dom";
import Footer from "../../../layouts/Footer.js";
import Header from "../../../layouts/Header.js";
import Swal from "sweetalert2";
import Sidebar from "../../../layouts/Sidebar.js";
import { useSelector } from "react-redux";

import { getUserInfo } from "../../../redux/slice/userSlice.js";
import {
  useGetServiceTrancheQuery, //liste des services
  useGetSousTrancheQuery, //liste sous tranche
  useGetTrancheQuery, //liste des tranche
  useGetRessourceListeTrancheQuery, //recuperation de ressources par tranche
  useGetRessourceListeSousTrancheQuery, //recuperation de ressources par sous tranche
  useGetRessourceAllServiceQuery, //recuperation de ressources par service
  useGetAllRessourcesQuery,
  useGetAttrCompLotQuery,
  useGetClientsQuery,
  useGetOccupantsQuery,
  useGetTotalNbreUseNbreActifQuery,
} from "../../../redux/apiSlice/numerotationApiSlice";

import { useGetOperateursQuery } from "../../../redux/apiSlice/operateurApiSlice";
import TableInfosAb from "./dataTables/TableInfosAb.js";
import { G } from "@react-pdf/renderer";

export default function ListePlainte() {
  const { data: tranches = [] } = useGetTrancheQuery();
  const { data: lesOps } = useGetOperateursQuery();
  const { data: lesClients } = useGetClientsQuery();
  const { data: lesAbOcc = [] } = useGetOccupantsQuery();
  // ****
  const [dataAb, setDataAb] = useState([]);
  const [id_tranche, setId_tranche] = useState("");
  const [id_ab, setId_ab] = useState("");
  const [elt_filtre, setElt_filtre] = useState("");
  const [id_operateur, setI_operateur] = useState("");
  const [id_clt, setId_clt] = useState("");
  const [id_status, setId_status] = useState("");

  const { data: listeTranche = [] } =
    useGetRessourceListeTrancheQuery(id_tranche);
  const { data: totalUseActifParTranche = [] } =
    useGetTotalNbreUseNbreActifQuery(id_tranche);
  const { data: lesAttributions = [] } = useGetAttrCompLotQuery();

  useEffect(() => {
    setDataAb(() => {
      const table_id1 = tranches
        .filter((tranch) => [2].includes(parseInt(tranch.lib_tranche)))
        .map((tranch) => tranch.id_tranche);

      if (table_id1.includes(id_tranche)) {
        return totalUseActifParTranche.map((j) => ({
          lib_lot: "-",
          debut_fin_lot: "-",
          nb_actif: j?.total_nb_actif,
          taux_actif: `${(j?.total_nb_actif * 100) / 10000}%`,
          nb_use: j?.total_nb_use,
          taux_nb_use: `${(j?.total_nb_use * 100) / 10000}%`,
        }));
      }
      return totalUseActifParTranche.map((j) => ({
        lib_lot: "-",
        debut_fin_lot: "-",
        nb_actif: j?.total_nb_actif,
        taux_actif: `${(j?.total_nb_actif * 100) / 100000}%`,
        nb_use: j?.total_nb_use,
        taux_nb_use: `${(j?.total_nb_use * 100) / 100000}%`,
      }));
    });
  }, [totalUseActifParTranche.length, id_tranche]);
  // ---****---
  const handleChange = (lib, value) => {
    switch (lib) {
      case "tranche":
        setId_tranche(value);
        break;
      case "ab":
        setDataAb(() => {
          let filteredData = [];

          filteredData = lesAttributions.filter(
            (attr) => attr.id_comp === value
          );

          return filteredData.map((j) => ({
            lib_lot: j?.Lot?.lib_lot,
            debut_fin_lot: `${j?.Lot?.debut_lot}-${j?.Lot?.fin_lot}`,
            nb_actif: j?.nb_actif,
            taux_actif: `${(j?.nb_actif * 100) / j?.Lot?.nb_lot}%`,
            nb_use: j?.nb_use,
            taux_nb_use: `${(j?.nb_use * 100) / j?.Lot?.nb_lot}%`,
          }));
        });

        setId_ab(value);
        break;
      case "id_status":
        setDataAb(() => {
          let filteredData = [];

          filteredData = lesAttributions.filter(
            (attr) => attr?.Composer?.id_stat_num === value
          );

          return filteredData.map((j) => ({
            lib_lot: j?.Lot?.lib_lot,
            debut_fin_lot: `${j?.Lot?.debut_lot}-${j?.Lot?.fin_lot}`,
            nb_actif: j?.nb_actif,
            taux_actif: `${(j?.nb_actif * 100) / j?.Lot?.nb_lot}%`,
            nb_use: j?.nb_use,
            taux_nb_use: `${(j?.nb_use * 100) / j?.Lot?.nb_lot}%`,
          }));
        });

        break;
      case "elt_filtre":
        setDataAb(() => {
          let filteredData = [];
          if (value === 0) {
            filteredData = lesAttributions.filter((attr) =>
              lesAbOcc.some(
                (occ) =>
                  occ.type_occupant === 0 &&
                  occ.id_comp === attr?.Composer?.id_comp
              )
            );
          } else if (value === 1) {
            filteredData = lesAttributions.filter((attr) =>
              lesAbOcc.some(
                (occ) =>
                  occ.type_occupant === 1 &&
                  occ.id_comp === attr?.Composer?.id_comp
              )
            );
          }

          return filteredData.map((j) => ({
            lib_lot: j?.Lot?.lib_lot,
            debut_fin_lot: `${j?.Lot?.debut_lot}-${j?.Lot?.fin_lot}`,
            nb_actif: j?.nb_actif,
            taux_actif: `${(j?.nb_actif * 100) / j?.Lot?.nb_lot}%`,
            nb_use: j?.nb_use,
            taux_nb_use: `${(j?.nb_use * 100) / j?.Lot?.nb_lot}%`,
          }));
        });
        setElt_filtre(value);
        break;
      case "id_operateur":
        setDataAb(() => {
          let filteredData = [];

          filteredData = lesAttributions.filter((attr) =>
            lesAbOcc.some(
              (occ) =>
                occ.type_occupant === elt_filtre &&
                occ.id_occupant === value &&
                occ.id_comp === attr.id_comp
            )
          );

          return filteredData.map((j) => ({
            lib_lot: j?.Lot?.lib_lot,
            debut_fin_lot: `${j?.Lot?.debut_lot}-${j?.Lot?.fin_lot}`,
            nb_actif: j?.nb_actif,
            taux_actif: `${(j?.nb_actif * 100) / j?.Lot?.nb_lot}%`,
            nb_use: j?.nb_use,
            taux_nb_use: `${(j?.nb_use * 100) / j?.Lot?.nb_lot}%`,
          }));
        });
        setI_operateur(value);
        break;
      case "id_clt":
        setDataAb(() => {
          let filteredData = [];

          filteredData = lesAttributions.filter((attr) =>
            lesAbOcc.some(
              (occ) =>
                occ.type_occupant === elt_filtre &&
                occ.id_occupant === value &&
                occ.id_comp === attr.id_comp
            )
          );

          return filteredData.map((j) => ({
            lib_lot: j?.Lot?.lib_lot,
            debut_fin_lot: `${j?.Lot?.debut_lot}-${j?.Lot?.fin_lot}`,
            nb_actif: j?.nb_actif,
            taux_actif: `${(j?.nb_actif * 100) / j?.Lot?.nb_lot}%`,
            nb_use: j?.nb_use,
            taux_nb_use: `${(j?.nb_use * 100) / j?.Lot?.nb_lot}%`,
          }));
        });
        setId_clt(value);
        break;
      default:
        break;
    }
  };

  // useEffect(() => {
  //   if (id_status !== "") {
  //     setDataAb(() => {
  //       const resultFilterStatus = lesAttributions.filter(
  //         (attr, key) => attr?.Composer?.id_stat_num === id_status
  //       );
  //       return resultFilterStatus.map((j) => {
  //         return {
  //           lib_lot: j?.Lot?.lib_lot,
  //           debut_fin_lot: j?.Lot?.debut_lot + "-" + j?.Lot?.fin_lot,
  //           nb_actif: j?.nb_actif,
  //           taux_actif: (j?.nb_actif * 100) / j?.Lot?.nb_lot + "%",
  //           nb_use: j?.nb_use,
  //           taux_nb_use: (j?.nb_use * 100) / j?.Lot?.nb_lot + "%",
  //         };
  //       });
  //     });
  //   }
  //   switch (elt_filtre) {
  //     case 0:
  //       const resultEltOp = lesAbOcc?.filter(
  //         (occ) => occ.type_occupant === elt_filtre
  //       );
  //       const tableAttrOp = resultEltOp?.map((tab) => {
  //         return lesAttributions?.find(
  //           (dataAttr) => dataAttr.id_comp === tab.id_comp
  //         );
  //       });
  //       setDataAb(() => {
  //         return tableAttrOp.map((j) => {
  //           return {
  //             lib_lot: j?.Lot?.lib_lot,
  //             debut_fin_lot: j?.Lot?.debut_lot + "-" + j?.Lot?.fin_lot,
  //             nb_actif: j?.nb_actif,
  //             taux_actif: (j?.nb_actif * 100) / j?.Lot?.nb_lot + "%",
  //             nb_use: j?.nb_use,
  //             taux_nb_use: (j?.nb_use * 100) / j?.Lot?.nb_lot + "%",
  //           };
  //         });
  //       });
  //       break;
  //     case 1:
  //       const resultEltClt = lesAbOcc?.filter(
  //         (occ) => occ.type_occupant === elt_filtre
  //       );
  //       const tableAttrClt = resultEltClt?.map((tab) => {
  //         return lesAttributions?.find(
  //           (dataAttr) => dataAttr.id_comp === tab.id_comp
  //         );
  //       });
  //       setDataAb(() => {
  //         return tableAttrClt.map((j) => {
  //           return {
  //             lib_lot: j?.Lot?.lib_lot,
  //             debut_fin_lot: j?.Lot?.debut_lot + "-" + j?.Lot?.fin_lot,
  //             nb_actif: j?.nb_actif,
  //             taux_actif: (j?.nb_actif * 100) / j?.Lot?.nb_lot + "%",
  //             nb_use: j?.nb_use,
  //             taux_nb_use: (j?.nb_use * 100) / j?.Lot?.nb_lot + "%",
  //           };
  //         });
  //       });
  //       break;
  //     default:
  //       setDataAb([]);
  //       break;
  //   }
  //   if (id_operateur !== "") {
  //     const resultOp = lesAbOcc?.filter(
  //       (occ) => occ.type_occupant === elt_filtre
  //     );
  //     const filtreOp = resultOp?.filter(
  //       (op) => op.id_occupant === id_operateur
  //     );
  //     const tableOp = filtreOp?.map((tab) => {
  //       return lesAttributions?.find(
  //         (dataAttr) => dataAttr.id_comp === tab.id_comp
  //       );
  //     });
  //     setDataAb(() => {
  //       return tableOp.map((j) => {
  //         return {
  //           lib_lot: j?.Lot?.lib_lot,
  //           debut_fin_lot: j?.Lot?.debut_lot + "-" + j?.Lot?.fin_lot,
  //           nb_actif: j?.nb_actif,
  //           taux_actif: (j?.nb_actif * 100) / j?.Lot?.nb_lot + "%",
  //           nb_use: j?.nb_use,
  //           taux_nb_use: (j?.nb_use * 100) / j?.Lot?.nb_lot + "%",
  //         };
  //       });
  //     });
  //   }
  //   if (id_clt !== "") {
  //     const resultClt = lesAbOcc?.filter(
  //       (occ) => occ.type_occupant === elt_filtre
  //     );
  //     const filtreClt = resultClt?.filter((clt) => clt.id_occupant === id_clt);
  //     const tableClt = filtreClt?.map((tab) => {
  //       return lesAttributions?.find(
  //         (dataAttr) => dataAttr.id_comp === tab.id_comp
  //       );
  //     });
  //     setDataAb(() => {
  //       return tableClt.map((j) => {
  //         return {
  //           lib_lot: j?.Lot?.lib_lot,
  //           debut_fin_lot: j?.Lot?.debut_lot + "-" + j?.Lot?.fin_lot,
  //           nb_actif: j?.nb_actif,
  //           taux_actif: (j?.nb_actif * 100) / j?.Lot?.nb_lot + "%",
  //           nb_use: j?.nb_use,
  //           taux_nb_use: (j?.nb_use * 100) / j?.Lot?.nb_lot + "%",
  //         };
  //       });
  //     });
  //   }
  //   if (id_ab !== "") {
  //     setDataAb(() => {
  //       const resultFilter = lesAttributions.filter(
  //         (attr, key) => attr.id_comp === id_ab
  //       );
  //       return resultFilter.map((j) => {
  //         return {
  //           lib_lot: j?.Lot?.lib_lot,
  //           debut_fin_lot: j?.Lot?.debut_lot + "-" + j?.Lot?.fin_lot,
  //           nb_actif: j?.nb_actif,
  //           taux_actif: (j?.nb_actif * 100) / j?.Lot?.nb_lot + "%",
  //           nb_use: j?.nb_use,
  //           taux_nb_use: (j?.nb_use * 100) / j?.Lot?.nb_lot + "%",
  //         };
  //       });
  //     });
  //   }
  // }, [id_tranche, id_ab, id_operateur, id_status, elt_filtre]);

  useEffect(() => {}, [
    id_tranche,
    id_ab,
    id_operateur,
    id_status,
    elt_filtre,
    id_clt,
  ]);

  const user = useSelector(getUserInfo);

  //   useEffect(() => {
  //     if (user?.id) {
  //       localStorage.setItem("user_id", user?.id);
  //     }
  //     if (
  //       localStorage.getItem("user_id") === "" ||
  //       !localStorage.getItem("user_id")
  //     ) {
  //       window.location.href = "/";
  //     }
  //   }, []);

  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Numerotation</Link>
              </li>
              {/* <li className="breadcrumb-item active" aria-current="page">Finance Monitoring</li> */}
            </ol>
            <h4 className="main-title mb-0">Statistique ressource</h4>
          </div>
        </div>

        <Row className="g-3">
          <Col sm="12" xl="4">
            <Card className="card-one" style={{ height: "120px" }}>
              <Card.Body className="overflow-hidden px-0">
                <div className="finance-info p-3 p-xl-4 pb-4">
                  <Row>
                    <Col
                      sm="12"
                      xl="12"
                      className="d-flex flex-column justify-content-center align-items-center"
                    >
                      <label className="fs-sm fw-medium mb-2 mb-xl-1">
                        Nombre de ressources
                      </label>

                      <h4 className="finance-value">
                        {dataAb[0]?.lib_lot === "-" ? 0 : dataAb?.length}
                      </h4>
                    </Col>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xl="12" sm="12" className="d-flex flex-column gap-2">
            <Row className="g-2">
              <Col sm="12" xl="4" className="d-flex flex-column">
                <Form.Label htmlFor="tranche">Tranche:</Form.Label>

                <Form.Select
                  id="tranche"
                  onChange={(e) =>
                    handleChange("tranche", parseInt(e.target.value))
                  }
                >
                  <option value="">Choisir</option>
                  {tranches
                    .filter(
                      (tranch) =>
                        ![1, 3, 7, 8].includes(parseInt(tranch.lib_tranche))
                    )
                    .map((tranch, key) => (
                      <option key={key} value={tranch.id_tranche}>
                        {tranch.lib_tranche}
                      </option>
                    ))}
                </Form.Select>
              </Col>

              <Col sm="12" xl="4" className="d-flex flex-column">
                <Form.Label htmlFor="soustranche">Ab:</Form.Label>

                <Form.Select
                  id="soustranche"
                  onChange={(e) => handleChange("ab", parseInt(e.target.value))}
                >
                  <option value="">Choisir</option>

                  {listeTranche.length > 0 &&
                    listeTranche
                      .slice()
                      .sort((a, b) => a.lib_comp - b.lib_comp)
                      .map((ab, key) => (
                        <option key={key} value={ab.id_comp}>
                          {ab.lib_comp}
                        </option>
                      ))}
                </Form.Select>
              </Col>
              <Col sm="12" xl="4">
                <Form.Label htmlFor="statut">Statuts :</Form.Label>

                <Form.Select
                  id="statut"
                  // value={lesChamp.id_status}
                  onChange={(e) =>
                    handleChange("id_status", parseInt(e.target.value))
                  }
                >
                  <option value="">Choisir</option>
                  <option value="3">Réservée</option>
                  <option value="1">Libre</option>
                  <option value="2">En instance</option>
                  <option value="4">Attribuée</option>
                  <option value="6">Inutilisable</option>
                  <option value="5">Bloquée</option>
                </Form.Select>
              </Col>
              <Col sm="12" xl="6">
                <Form.Label htmlFor="elt_filtre">
                  Elément de filtre :
                </Form.Label>

                <Form.Select
                  id="elt_filtre"
                  // value={lesChamp.elt_filtre}
                  onChange={(e) =>
                    handleChange("elt_filtre", parseInt(e.target.value))
                  }
                >
                  <option value="">Choisir</option>
                  <option value="1">Clients</option>
                  <option value="0">Opérateurs</option>
                </Form.Select>
              </Col>
              {elt_filtre === 0 && (
                <Col sm="12" xl="6">
                  <Form.Label htmlFor="op">Opérateur :</Form.Label>

                  <Form.Select
                    id="op"
                    // value={lesChamp.id_operateur}
                    onChange={(e) =>
                      handleChange("id_operateur", parseInt(e.target.value))
                    }
                  >
                    <option value="">Choisir</option>
                    {lesOps?.map((ope, key) => (
                      <option key={key} value={ope.id_operateur}>
                        {ope.nom_operateur}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              )}

              {elt_filtre === 1 && (
                <Col sm="12" xl="6">
                  <Form.Label htmlFor="lesclients">Clients :</Form.Label>

                  <Form.Select
                    id="lesclients"
                    // value={lesChamp.id_clt}
                    onChange={(e) =>
                      handleChange("id_clt", parseInt(e.target.value))
                    }
                  >
                    <option value="">Choisir</option>
                    {lesClients?.map((client, key) => (
                      <option key={key} value={client.id_clt}>
                        {client.nom_clt}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              )}
            </Row>
          </Col>
          <Col xl="12" sm="12">
            <Row className="g-2">
              <Col sm="12" xl="12">
                <p className="w-50 fs-sm mb-2 mb-xl-4 d-none d-sm-block">
                  Liste des ressources
                </p>
                <TableInfosAb ab={dataAb} />
              </Col>
            </Row>
          </Col>
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  );
}
