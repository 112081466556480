import React from 'react'
import { Form } from "react-bootstrap";

export default function VisualisationPlainte(props) {
    const {dummyDatas, plainteData} = props

    const formateDatePlainte = (laDate) => {
        const dateObject = new Date(laDate);
        const options = { day: "2-digit", month: "short", year: "numeric" };
        const formattedDate = dateObject.toLocaleDateString("fr-FR", options);
        return formattedDate;
      };
  
  return (
    <div className='container mt-5'>

  <div className='row'>

           <h6>
            Avez-vous obtenu une réponse de la part de votre opérateur ? *
          </h6>
          <Form.Control
              type="text"
              id="exampleFormControlInput1"
              placeholder="Référence"
              value={plainteData.reponse == "0" ? "Non" : "Oui mais pas satisfait"}
              disabled
            />
      
  </div>
  <div className='row'>
<div className='col'>
     <Form.Label htmlFor="exampleFormControlInput1">
              Référence de la plainte chez l'opérateur :
            </Form.Label>
            <Form.Control
              type="text"
              value={plainteData.ref_plt != "" ? plainteData.ref_plt : ""}
              disabled
            />
</div>
<div className='col'>
<Form.Label htmlFor="exampleFormControlInput1">
Opérateur concerné *:
            </Form.Label>
            <Form.Control
              type="text"
              id="exampleFormControlInput1"
              placeholder="Référence"
              value={dummyDatas?.nom_operateur}
              disabled
            />
</div>
<div className='col'>
<Form.Label htmlFor="exampleFormControlInput1">
Problématique :
            </Form.Label>
            <Form.Control
              type="text"
              value={plainteData.motif_plt}
              disabled
            />
</div>
  
  </div>
  
  <div className='row d-flex flex-row'>

  <Form.Label htmlFor="exampleFormControlInput1">
  Nature du plaignant    
            </Form.Label>
            {plainteData.type_personne == "0" ?
            <Form.Check
            type="radio"
            label="Personne physique"
            checked
           
          />
          :
          <Form.Check
          type="radio"
          label="Personne moral"
          checked
         
        />
            }
          
        
          {
             plainteData.type_personne == "1" &&
            <Form.Control
              type="text"
              value={plainteData.raison} 
              disabled
            />
          }
  
  </div>
  <div className='row'>
<div className='col'>
         <Form.Label htmlFor="exampleFormControlInput1">
         Nom du plaignant *
            </Form.Label>
            <Form.Control
              type="text"
              id="exampleFormControlInput1"
              placeholder="Référence"
              value={plainteData.nom_plt}
              disabled
            />
</div>
<div className='col'>
<Form.Label htmlFor="exampleFormControlInput1">
Prénoms du plaignant *
            </Form.Label>
            <Form.Control
              type="text"
              value={plainteData.prenom_plt}
              disabled
            />
</div>
<div className='col'>
<Form.Label htmlFor="exampleFormControlInput1">
Sexe 
            </Form.Label>
            <Form.Control
              type="text"
              value={plainteData.sexe == "M" ? "Masculin": "Féminin"}
              disabled
            />
</div>
  
  </div>
  <div className='row'>
<div className='col'>
<Form.Label htmlFor="exampleFormControlInput1">
Téléphone
            </Form.Label>
            <Form.Control
              type="number"
              value={plainteData.tel_plt}
              disabled
            />
</div>
<div className='col'>
<Form.Label htmlFor="exampleFormControlInput1">
Email
            </Form.Label>
            <Form.Control
              type="text"
              value={plainteData.email_plt != "" ? plainteData.email_plt : ""}
              disabled
            />
</div>
<div className='col'>
<Form.Label htmlFor="exampleFormControlInput1">
Adresse de résidence
            </Form.Label>
            <Form.Control
              type="text"
              value={plainteData.adresse_plt}
              disabled
            />
</div>
  
  </div>
  <div className='row'>
<div className='col'>
<Form.Label htmlFor="exampleFormControlInput1">
Profession *
            </Form.Label>
            <Form.Control
              type="text"
              value={plainteData.profe}
              disabled
            />
</div>
<div className='col'>
<Form.Label htmlFor="exampleFormControlInput1">
Nationalité 
            </Form.Label>
            <Form.Control
              type="text"
              value={plainteData.nationalite}
              disabled
            />
</div>

  
  </div>
  <div className='row'>
<div className='col'>
<Form.Label htmlFor="exampleFormControlInput1">
Résumé de la plainte
            </Form.Label>
            <Form.Control
            as="textarea"
            value={plainteData.desc_plt}
            id="exampleFormControlTextarea1"
            rows="3"
            disabled
          ></Form.Control>
</div>
<div className='col'>
<Form.Label htmlFor="exampleFormControlInput1">
Canal de retour souhaité 
            </Form.Label>
            <Form.Control
              type="text"
              value={plainteData.canal_retour}
              disabled
            />
</div>
<div className='col'>
<Form.Label htmlFor="exampleFormControlInput1">
Date du premier constat *
            </Form.Label>
            <Form.Control
              type="text"
              value={ plainteData?.date_constat == null ? "" : formateDatePlainte(plainteData?.date_constat)}
             disabled
            />
</div>

  
  </div>
        
    </div>
  )
}
