import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Form, Modal } from "react-bootstrap";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Sidebar from "../../layouts/Sidebar";
import { NavLink } from "react-router-dom";
import TableParamSMTP from "./dataTable/TableParamSMTP";
import Swal from "sweetalert2";
import {
  useGetParamSMTPQuery,
  useUpdateParamSMTPMutation,
} from "../../redux/slice/paramSMTPApiSlice";


export default function ParamSMTP() {
  // modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const { data: paramSMTP } = useGetParamSMTPQuery();

  const init = {
    url: "",
    port: "",
    email: "",
    password: "",
  };
  const [form, setForm] = useState({ ...init });

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  // const { data: paramSMTP = [], isLoading: loadParamSMTP } =
  //   useGetParamSMTPQuery();

  const [updateData, { isLoading: loadUpdate }] =
    useUpdateParamSMTPMutation();


  const changeForm = (field, value) => setForm({ ...form, [field]: value });

  const save = async () => {
    try {
      if (form?.id) {
        await updateData(form).unwrap();
        setForm({ ...init });
        Swal.fire({
          title: "Paramètrage SMTP!",
          text: "Modification réussie ",
          icon: "success",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.data.errors[0].message,
        footer: '<a href="#">essyer une autre?</a>',
      });
    }
  };

  
  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <NavLink to="/dashboard-obligation-reglmt">
                  Administration
                </NavLink>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Paramètre SMTP
              </li>
            </ol>
            <h4 className="main-title mb-0">Gestion du Paramètrage</h4>
          </div>
        </div>

        <div className="container_liste_form">
          <div className="first_container">
            <span className="titre_cat">
                Paramètre SMTP
            </span>

            <hr style={{ width: "100%" }} />

            <TableParamSMTP
              paramSMTP={paramSMTP}
              setForm={setForm}
            />
          </div>

          <div className="second_container">
            <span className="titre_cat">Modifier SMTP Paramètre</span>

            <hr style={{ width: "100%" }} />

            <div className="container_form">
              <div className="container_border">

                <div className="sous_container_form">
                  <div className="container_label">
                    <Form.Label htmlFor="exampleFormControlInput1">
                      Url Serveur :
                    </Form.Label>
                  </div>
                  <div style={{ width: "70%" }}>
                    <Form.Control
                      type="text"
                      id="exampleFormControlInput1"
                      placeholder="Url"
                      onChange={(e) => changeForm("url", e.target.value)}
                      value={form.url}
                    />
                  </div>
                </div>
                <br></br>
               
                <div className="sous_container_form">
                  <div className="container_label">
                    <Form.Label htmlFor="exampleFormControlInput1">
                      Port :
                    </Form.Label>
                  </div>
                  <div style={{ width: "70%" }}>
                    <Form.Control
                      type="number"
                      id="exampleFormControlInput1"
                      placeholder="Port Com"
                      onChange={(e) => changeForm("port", e.target.value)}
                      value={form.port}
                    />
                  </div>
                </div>

                <br></br>
               
               <div className="sous_container_form">
                 <div className="container_label">
                   <Form.Label htmlFor="exampleFormControlInput1">
                     Email :
                   </Form.Label>
                 </div>
                 <div style={{ width: "70%" }}>
                   <Form.Control
                     type="text"
                     id="exampleFormControlInput1"
                     placeholder="Email"
                     onChange={(e) => changeForm("email", e.target.value)}
                     value={form.email}
                   />
                 </div>
               </div>

               <br></br>
               
               <div className="sous_container_form">
                 <div className="container_label">
                   <Form.Label htmlFor="exampleFormControlInput1">
                     Mot de Passe :
                   </Form.Label>
                 </div>
                 <div style={{ width: "70%" }}>
                   <Form.Control
                     type="text"
                     id="exampleFormControlInput1"
                     placeholder="Mot de passe"
                     onChange={(e) => changeForm("password", e.target.value)}
                     value={form.password}
                   />
                 </div>
               </div>

                <div className="container_btn">
                  <Button
                    variant="primary"
                    onClick={() => setForm({ ...init })}
                  >
                    Annuler
                  </Button>

                  <Button variant="success" onClick={() => save()}>
                    Modifier
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </React.Fragment>
  );
}
