import { apiSlice } from "../api/apiSlice"

export const pwdApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        // getProfil: builder.query({
        //     query: () => ({
        //         url: '/profil',
        //         method: "GET",
        //     }),
        //     providesTags: (result) => {
        //         return result ? [
        //             { type: 'Profil', id: 'LIST' },
        //             ...result.map(item => ({ type: 'Profil', id: item.id_profil }))
        //         ] : ['Profil']
        //     }
        // }),

       checkMail: builder.mutation({
            query: body => ({
                url: '/login/forgot/checkmail',
                method: 'POST',
                body
            }),
        }),

        checkCode: builder.mutation({
            query: body => ({
                url: '/login/forgot/verify_2fa',
                method: 'POST',
                body
            }),
        }),
 
       changePass: builder.mutation({
            query: body => ({
                url: '/login/forgot/change',
                method: 'POST',
                body
            }),
        }),
 
        // updateEcheance: builder.mutation({
        //     query: body => ({
        //         url: `/obligation/echeance/update/${body.id_echeance}`,
        //         method: 'PUT',
        //         body
        //     }),
        //     invalidatesTags: ['Echeances']
        // }),

        // deleteProfil: builder.mutation({
           
        //     query: (id) => ({
        //       url: `/profil/del/${id}`,
        //       method: 'DELETE',
        //     }),
        //     invalidatesTags: ['Profil'],
        //   }),
    })
})

export const {
    useCheckMailMutation,
    useCheckCodeMutation,
    useChangePassMutation,
    // useGetProfilQuery,
    // useUpdateEcheanceMutation,
    // useDeleteProfilMutation,
    
} = pwdApiSlice