import React, { useEffect, useState } from "react";
import { Form, Card, Col, Nav, ProgressBar, Row } from "react-bootstrap";

import { Link } from "react-router-dom";
import Footer from "../../../layouts/Footer.js";
import Header from "../../../layouts/Header.js";
import Swal from "sweetalert2";
import Sidebar from "../../../layouts/Sidebar.js";
import { useSelector } from "react-redux";

import { getUserInfo } from "../../../redux/slice/userSlice.js";
import {
  useGetServiceTrancheQuery, //liste des services
  useGetSousTrancheQuery, //liste sous tranche
  useGetTrancheQuery, //liste des tranche
  useGetRessourceListeTrancheQuery, //recuperation de ressources par tranche
  useGetRessourceListeSousTrancheQuery, //recuperation de ressources par sous tranche
  useGetRessourceAllServiceQuery, //recuperation de ressources par service
  useGetAllRessourcesQuery,
  useGetAttrCompLotQuery,
  useGetClientsQuery,
  useGetOccupantsQuery,
} from "../../../redux/apiSlice/numerotationApiSlice.js";
import { useGetOperateursQuery } from "../../../redux/apiSlice/operateurApiSlice.js";
import TableSansAb from "./dataTables/TableSansAb.js";
import { G } from "@react-pdf/renderer";

export default function ListePlainte() {
  const { data: tranches = [] } = useGetTrancheQuery();
  const { data: sousTranches = [] } = useGetSousTrancheQuery();
  const { data: lesOps } = useGetOperateursQuery();
  const { data: lesClients } = useGetClientsQuery();
  const { data: lesOccupants = [] } = useGetOccupantsQuery();
  // ****
  const [dataTrancheSansAb, setDataTrancheSansAb] = useState([]);
  const [id_tranche, setId_tranche] = useState("");
  const [id_sous_tranche, setId_sous_tranche] = useState("");
  const [id_ab, setId_ab] = useState("");
  const [elt_filtre, setElt_filtre] = useState("");
  const [id_operateur, setI_operateur] = useState("");
  const [id_clt, setId_clt] = useState("");
  const [id_status, setId_status] = useState("");

  const { data: listeTranche = [] } =
    useGetRessourceListeTrancheQuery(id_tranche);
  var new_sous_tranche = sousTranches.filter(
    (elt) => elt.id_tranche == id_tranche
  );

  const { data: listeSousTranche = [] } =
    useGetRessourceListeSousTrancheQuery(id_sous_tranche);
  const { data: lesAttributions = [] } = useGetAttrCompLotQuery();

  // ---****---
  const stat = (value) => {
    switch (value) {
      case 1:
        return "Libre";
      case 2:
        return "En instance";
      case 3:
        return "Réservée";
      case 4:
        return "Attribuée";
      case 5:
        return "Bloquée";
      case 6:
        return "Inutilisable";
      default:
        break;
    }
  };

  const handleChange = (lib, value) => {
    const table_id1 = tranches
      .filter((tranch) => [1].includes(parseInt(tranch.lib_tranche)))
      .map((tranch) => tranch.id_tranche);

    const table_id2 = tranches
      .filter((tranch) => [3, 7, 8].includes(parseInt(tranch.lib_tranche)))
      .map((tranch) => tranch.id_tranche);
    switch (lib) {
      case "tranche":
        setId_tranche(value);

        break;
      case "sous_tranche":
        setId_sous_tranche(value);

        break;
      case "id_status":
        if (table_id2.includes(id_tranche))
          setDataTrancheSansAb(() => {
            let filteredData = [];
            filteredData = listeTranche.filter(
              (tranch) => parseInt(value) === tranch?.id_stat_num
            );

            return filteredData.map((j) => ({
              id_comp: j?.id_comp,
              lib_tranche: j?.tranche?.lib_tranche,
              lib_comp: j?.lib_comp,
              id_stat_num: stat(j?.id_stat_num),
            }));
          });
        else
          setDataTrancheSansAb(() => {
            let filteredData = [];
            filteredData = listeSousTranche.filter(
              (tranch) => parseInt(value) === tranch?.id_stat_num
            );

            return filteredData.map((j) => ({
              id_comp: j?.id_comp,
              lib_tranche: j?.tranche?.lib_tranche,
              lib_comp: j?.lib_comp,
              id_stat_num: stat(j?.id_stat_num),
            }));
          });
        setId_status(value);
        break;
      case "elt_filtre":
        setDataTrancheSansAb(() => {
          let filteredData = [];
          if (table_id2.includes(id_tranche) && value === 0) {
            filteredData = listeTranche.filter((tr) =>
              lesOccupants.some(
                (occ) =>
                  occ.type_occupant === 0 &&
                  occ.id_comp === tr.id_comp &&
                  id_tranche === tr?.tranche?.id_tranche
              )
            );
          } else if (table_id1.includes(id_tranche) && value === 0) {
            filteredData = listeSousTranche.filter((tr) =>
              lesOccupants.some(
                (occ) =>
                  occ.type_occupant === 0 &&
                  occ.id_comp === tr?.id_comp &&
                  tr?.tranche.id_tranche === id_tranche
              )
            );
          } else if (table_id2.includes(id_tranche) && value === 1) {
            filteredData = listeTranche.filter((tr) =>
              lesOccupants.some(
                (occ) =>
                  occ.type_occupant === 1 &&
                  occ.id_comp === tr.id_comp &&
                  id_tranche === tr?.tranche?.id_tranche
              )
            );
          } else if (table_id1.includes(id_tranche) && value === 1) {
            filteredData = listeSousTranche.filter((tr) =>
              lesOccupants.some(
                (occ) =>
                  occ.type_occupant === 1 &&
                  occ.id_comp === tr?.id_comp &&
                  tr?.tranche.id_tranche === id_tranche
              )
            );
          }

          return filteredData.map((j) => ({
            id_comp: j?.id_comp,
            lib_tranche: j?.tranche?.lib_tranche,
            lib_comp: j?.lib_comp,
            id_stat_num: stat(j?.id_stat_num),
          }));
        });
        setElt_filtre(value);
        break;
      case "id_operateur":
        setDataTrancheSansAb(() => {
          let filteredData = [];
          if (table_id1.includes(id_tranche) && elt_filtre === 0)
            filteredData = listeSousTranche.filter((attr) =>
              lesOccupants.some(
                (occ) =>
                  occ.type_occupant === elt_filtre &&
                  occ.id_occupant === value &&
                  occ.id_comp === attr.id_comp
              )
            );
          else
            filteredData = listeTranche.filter((attr) =>
              lesOccupants.some(
                (occ) =>
                  occ.type_occupant === elt_filtre &&
                  occ.id_occupant === value &&
                  occ.id_comp === attr.id_comp
              )
            );
          return filteredData.map((j) => ({
            id_comp: j?.id_comp,
            lib_tranche: j?.tranche?.lib_tranche,
            lib_comp: j?.lib_comp,
            id_stat_num: stat(j?.id_stat_num),
          }));
        });
        setI_operateur(value);
        break;
      case "id_clt":
        setDataTrancheSansAb(() => {
          let filteredData = [];
          if (table_id1.includes(id_tranche) && elt_filtre === 1)
            filteredData = listeSousTranche.filter((attr) =>
              lesOccupants.some(
                (occ) =>
                  occ.type_occupant === elt_filtre &&
                  occ.id_occupant === value &&
                  occ.id_comp === attr.id_comp
              )
            );
          else
            filteredData = listeTranche.filter((attr) =>
              lesOccupants.some(
                (occ) =>
                  occ.type_occupant === elt_filtre &&
                  occ.id_occupant === value &&
                  occ.id_comp === attr.id_comp
              )
            );
          return filteredData.map((j) => ({
            id_comp: j?.id_comp,
            lib_tranche: j?.tranche?.lib_tranche,
            lib_comp: j?.lib_comp,
            id_stat_num: stat(j?.id_stat_num),
          }));
        });
        setId_clt(value);
        break;
      default:
        break;
    }
  };

  // useEffect(() => {}, [
  //   id_tranche,
  //   id_ab,
  //   id_operateur,
  //   id_status,
  //   elt_filtre,
  //   id_clt,
  // ]);

  useEffect(() => {
    setDataTrancheSansAb(() => {
      return listeTranche.map((j) => ({
        id_comp: j?.id_comp,
        lib_tranche: j?.tranche?.lib_tranche,
        lib_comp: j?.lib_comp,
        id_stat_num: stat(j?.id_stat_num),
      }));
    });
  }, [listeTranche.length,id_tranche]);

  useEffect(() => {
    setDataTrancheSansAb(() => {
      return listeSousTranche.map((j) => ({
        id_comp: j?.id_comp,
        lib_tranche: j?.tranche?.lib_tranche,
        lib_comp: j?.lib_comp,
        id_stat_num: stat(j?.id_stat_num),
      }));
    });
  }, [listeSousTranche.length,id_sous_tranche]);

  const user = useSelector(getUserInfo);

  //   useEffect(() => {
  //     if (user?.id) {
  //       localStorage.setItem("user_id", user?.id);
  //     }
  //     if (
  //       localStorage.getItem("user_id") === "" ||
  //       !localStorage.getItem("user_id")
  //     ) {
  //       window.location.href = "/";
  //     }
  //   }, []);

  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Numerotation</Link>
              </li>
              {/* <li className="breadcrumb-item active" aria-current="page">Finance Monitoring</li> */}
            </ol>
            <h4 className="main-title mb-0">Statistique ressource</h4>
          </div>
        </div>

        <Row className="g-3">
          <Col sm="12" xl="4">
            <Card className="card-one" style={{ height: "120px" }}>
              <Card.Body className="overflow-hidden px-0">
                <div className="finance-info p-3 p-xl-4 pb-4">
                  <Row>
                    <Col
                      sm="12"
                      xl="12"
                      className="d-flex flex-column justify-content-center align-items-center"
                    >
                      <label className="fs-sm fw-medium mb-2 mb-xl-1">
                        Nombre de ressources
                      </label>

                      <h4 className="finance-value">
                        {dataTrancheSansAb?.length}
                      </h4>
                    </Col>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xl="12" sm="12" className="d-flex flex-column gap-2">
            <Row className="g-2">
              <Col sm="12" xl="4" className="d-flex flex-column">
                <Form.Label htmlFor="tranche">Tranche:</Form.Label>

                <Form.Select
                  id="tranche"
                  onChange={(e) =>
                    handleChange("tranche", parseInt(e.target.value))
                  }
                >
                  <option value="">Choisir</option>
                  {tranches
                    .filter((tranch) =>
                      [1, 3, 7, 8].includes(parseInt(tranch.lib_tranche))
                    )
                    .map((tranch, key) => (
                      <option key={key} value={tranch.id_tranche}>
                        {tranch.lib_tranche}
                      </option>
                    ))}
                </Form.Select>
              </Col>

              <Col sm="12" xl="4" className="d-flex flex-column">
                <Form.Label htmlFor="soustranche">Sous tranche:</Form.Label>

                <Form.Select
                  id="soustranche"
                  onChange={(e) =>
                    handleChange("sous_tranche", parseInt(e.target.value))
                  }
                >
                  <option value="">Choisir</option>

                  {/* {new_sous_tranche.length > 0 &&
                    new_sous_tranche
                      .slice()
                      .sort((a, b) => a.lib_comp - b.lib_comp)
                      .map((ab, key) => (
                        <option key={key} value={ab.id_comp}>
                          {ab.lib_comp}
                        </option>
                      ))} */}
                  {new_sous_tranche?.map((soustranch, key) => (
                    <option key={key} value={soustranch.id_s_tran}>
                      {soustranch.lib_s_tran}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col sm="12" xl="4">
                <Form.Label htmlFor="statut">Statuts :</Form.Label>

                <Form.Select
                  id="statut"
                  // value={lesChamp.id_status}
                  onChange={(e) =>
                    handleChange("id_status", parseInt(e.target.value))
                  }
                >
                  <option value="">Choisir</option>
                  <option value="3">Réservée</option>
                  <option value="1">Libre</option>
                  <option value="2">En instance</option>
                  <option value="4">Attribuée</option>
                  <option value="6">Inutilisable</option>
                  <option value="5">Bloquée</option>
                </Form.Select>
              </Col>
              <Col sm="12" xl="6">
                <Form.Label htmlFor="elt_filtre">
                  Elément de filtre :
                </Form.Label>

                <Form.Select
                  id="elt_filtre"
                  // value={lesChamp.elt_filtre}
                  onChange={(e) =>
                    handleChange("elt_filtre", parseInt(e.target.value))
                  }
                >
                  <option value="">Choisir</option>
                  <option value="1">Clients</option>
                  <option value="0">Opérateurs</option>
                </Form.Select>
              </Col>
              {elt_filtre === 0 && (
                <Col sm="12" xl="6">
                  <Form.Label htmlFor="op">Opérateur :</Form.Label>

                  <Form.Select
                    id="op"
                    // value={lesChamp.id_operateur}
                    onChange={(e) =>
                      handleChange("id_operateur", parseInt(e.target.value))
                    }
                  >
                    <option value="">Choisir</option>
                    {lesOps?.map((ope, key) => (
                      <option key={key} value={ope.id_operateur}>
                        {ope.nom_operateur}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              )}

              {elt_filtre === 1 && (
                <Col sm="12" xl="6">
                  <Form.Label htmlFor="lesclients">Clients :</Form.Label>

                  <Form.Select
                    id="lesclients"
                    // value={lesChamp.id_clt}
                    onChange={(e) =>
                      handleChange("id_clt", parseInt(e.target.value))
                    }
                  >
                    <option value="">Choisir</option>
                    {lesClients?.map((client, key) => (
                      <option key={key} value={client.id_clt}>
                        {client.nom_clt}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              )}
            </Row>
          </Col>
          <Col xl="12" sm="12">
            <Row className="g-2">
              <Col sm="12" xl="12">
                <p className="w-50 fs-sm mb-2 mb-xl-4 d-none d-sm-block">
                  Liste des ressources
                </p>
                <TableSansAb ab={dataTrancheSansAb} />
              </Col>
            </Row>
          </Col>
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  );
}
