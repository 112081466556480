import React, { Component, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";

// import userAvatar from "../assets/img/img1.jpg";
import { Image } from "react-bootstrap";
import Logo from "../assets/img/logo.jpg";
import {
  // dashboardMenu,
  // applicationsMenu,
  pagesMenu,
  // uiElementsMenu,
  administration,
} from "../data/Menu";

import { useGetProfilQuery } from "../redux/apiSlice/profileApiSlice";
import { useGetModulesQuery } from "../redux/apiSlice/moduleApiSlice";
import { useGetFonctionnaliteQuery } from "../redux/apiSlice/fonctionnalitésApiSlice";
import { useGetUserQuery } from "../redux/apiSlice/userApiSlice";
import { useSelector } from "react-redux";
import { getUserInfo } from "../redux/slice/userSlice";
import Spinner from "react-bootstrap/Spinner";

// export default function Sidebar()  {

//  const toggleFooterMenu = (e) => {
//         e.preventDefault();

//         let parent = e.target.closest(".sidebar");
//         parent.classList.toggle("footer-menu-show");
//     }

//         const { lui } = this.props;

//         console.log("lui", lui)

//         return (
//             <div className="sidebar">
//                 <div className="sidebar-header">
//                     <Image src={Logo} alt="" width={120} height={50} />
//                 </div>
//                 <PerfectScrollbar className="sidebar-body" ref={ref => this._scrollBarRef = ref}>
//                     <SidebarMenu onUpdateSize={() => this._scrollBarRef.updateScroll()} />
//                 </PerfectScrollbar>

//             </div>
//         )

// }

export default function Sidebar() {
  const toggleFooterMenu = (e) => {
    e.preventDefault();

    let parent = e.target.closest(".sidebar");
    parent.classList.toggle("footer-menu-show");
  };
  const { data: user, isLoading: loadUser } = useGetUserQuery();

  const { data: modules = [], isLoading: loadModules } = useGetModulesQuery();
  const { data: fonctionnalite = [], isLoading: loadFonc } =
    useGetFonctionnaliteQuery();
  const { data: profiles = [], isLoading: loadProfile } = useGetProfilQuery();
// console.log("modules", modules)
// console.log("fonctionnalite", fonctionnalite)
// console.log("profiles", profiles)
  const icons = [
    {
      id: 1,
      icon: "ri-pie-chart-2-line",
      etat: true,
      fieldkey: false,
    },
    {
      id: 2,
      icon: "ri-calendar-todo-line",
      etat: false,
      fieldkey: true,
    },
    {
      id: 3,
      icon: "ri-mail-send-line",
      etat: false,
      fieldkey: false,
    },
    {
      id: 4,
      icon: "ri-shopping-bag-3-line",
      etat: false,
      fieldkey: false,
    },
    {
      id: 5,
      icon: "ri-shopping-bag-3-line",
      etat: true,
      fieldkey: false,
    },
    {
      id: 6,
      icon: "ri-checkbox-multiple-line",
      etat: true,
      fieldkey: false,
    },
    {
      id: 7,
      icon: "ri-checkbox-multiple-line",
      etat: false,
      fieldkey: false,
    },
    {
      id: 8,
      icon: "ri-shopping-bag-3-line",
      etat: false,
      fieldkey: false,
    },
    {
      id: 9,
      icon: "ri-hard-drive-2-line",
      etat: false,
      fieldkey: false,
    },
    {
      id: 10,
      icon: "ri-hard-drive-2-line",
      etat: false,
      fieldkey: false,
    },
  ];

  const [prol, setProl] = useState([]);
// console.log("prol", prol)
  // const id_us = useSelector(getUserInfo)

  useEffect(() => {
    let mm;
    const storedData = localStorage.getItem("mon_user");
    if (storedData) {
      mm = JSON.parse(storedData);
    } else {
      console.log("Aucune donnée utilisateur trouvée dans le localStorage");
    }

    if (user) {

      const cnet = user.find((item) => item.id_user == mm.id);
  
      const rtr = profiles.find((item) => item.id_profil == cnet?.id_profil);

      if (rtr) {
        setProl(rtr?.mod_insert);
      }
    }
  }, [modules, fonctionnalite, profiles, user]);

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <Image src={Logo} alt="" width={120} height={50} />
      </div>
      <PerfectScrollbar className="sidebar-body">
       { 
    
       <SidebarMenu
          onUpdateSize={() => {}}
          modules={modules}
          fonctionnalite={fonctionnalite}
          profiles={prol}
          icons={icons}
        />}
      </PerfectScrollbar>
    </div>
  );
}

class SidebarMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItems: {
        liste: [],
      },
    };

    this.state = {
      vv: [], // Initialisez vv dans l'état du composant
    };
  }

  componentDidMount() {
    // Retrieve data from localStorage
    const storedData = localStorage.getItem("plaintes");

    if (storedData) {
      // Parse the JSON data if available
      const { liste: selectedItems } = JSON.parse(storedData);

      // Additional logic or state updates using selectedItems
      // For example, you might want to update the state with the retrieved data
      this.setState({ selectedItems });
    }
  }

//   toggleSubMenu = (e) => {
//     e.preventDefault();

//     let parent = e.target.closest('.nav-item');
//     let node = parent.parentNode.firstChild;

//     while (node) {
//         if (node !== parent && node.nodeType === Node.ELEMENT_NODE)
//             node.classList.remove('show');
//         node = node.nextElementSibling || node.nextSibling;
//     }

//     parent.classList.toggle('show');

//     this.props.onUpdateSize();
// }



toggleSubMenu = (e) => {

  
  // let parent = e.target.closest('.nav-group');
  // parent.classList.toggle('show');

  let parent = e.target.closest('.nav-item');

  parent.classList.toggle('show');

  let childElement = document.querySelector('.active');

  // console.log("element", childElement)
  let parentElement = childElement.closest('.nav-item');
  parentElement.classList.add('show');

  // console.log("parentElement", parentElement)
  // Vérifier si le clic a été effectué sur un lien interne dans le sous-menu
  // if (e.target.closest('.nav-submenu')) {
      // Garder le sous-menu ouvert lors de la redirection
      // return;
  // }

  // Sinon, traiter le clic normalement
  // e.preventDefault();

  // let node = parent.parentNode.firstChild;

  // while (node) {
  //     if (node !== parent && node.nodeType === Node.ELEMENT_NODE)
  //         node.classList.remove('show');
  //     node = node.nextElementSibling || node.nextSibling;
  // }

  // parent.classList.toggle('show');

  // this.props.onUpdateSize();
}


  toggleMenu = (e) => {
    e.preventDefault();

    let parent = e.target.closest('.nav-group');
    parent.classList.toggle('show');

    this.props.onUpdateSize();
}

  populateMenu = (m) => {
    const { selectedItems } = this.state;
    let calculatedVariables = {
      accuser_reception: [],
      plainte_receptionee: [],
      plainte_classée: [],
      completude_dinformations: [],
      instruction_de_plainte: [],
      investigation_de_plainte: [],
      avis_de_reglement: [],
      toutes_les_plaintes: [],
      toutes_les_requetes: [],
      qualification: [],
    };

    calculatedVariables.accuser_reception = selectedItems?.liste.filter(
      (item) => item.id_stat_plt === 1 && item.id_type_plt != null && item.id_service != null
    );
    calculatedVariables.plainte_receptionee = selectedItems?.liste.filter(
      (item) => item.id_stat_plt === 2 && item.id_type_plt != null && item.id_service != null
    );
    calculatedVariables.plainte_classée = selectedItems?.liste.filter(
      (item) => item.id_stat_plt === 3 && item.id_type_plt != null && item.id_service != null
    );
    calculatedVariables.completude_dinformations = selectedItems?.liste.filter(
      (item) => item.id_stat_plt === 4 && item.id_type_plt != null && item.id_service != null
    );
    calculatedVariables.instruction_de_plainte = selectedItems?.liste.filter(
      (item) => item.id_stat_plt === 5 && item.id_type_plt != null && item.id_service != null
    );
    calculatedVariables.investigation_de_plainte = selectedItems?.liste.filter(
      (item) => item.id_stat_plt === 6 || item.id_stat_plt === 7 && item.id_type_plt != null && item.id_service != null
    );
    calculatedVariables.avis_de_reglement = selectedItems?.liste.filter(
      (item) => item.id_stat_plt === 10 && item.id_type_plt != null && item.id_service != null
    );
    calculatedVariables.toutes_les_plaintes = selectedItems?.liste.filter((item)=>( item.id_type_plt != null && item.id_service != null));
    calculatedVariables.qualification = selectedItems?.liste.filter((item)=>( item.id_type_req == null ));
    calculatedVariables.toutes_les_requetes = selectedItems?.liste.filter((item)=>( item.id_type_req != null &&  item.id_type_req != 2 && item.id_type_plt == null && item.id_service == null));
  
    const menu = m.map((m, key) => {
      let sm;
      const circleBadgeStyle = {
        display: "inline-block",
        width: "20px",
        height: "15px",
        lineHeight: "15px",
        borderRadius: "15px",
        fontSize: "10px",
        textAlign: "center",
        backgroundColor: "#4f84c4",
        color: "#fff",
      };
      if (m.submenu) {
        sm = m.submenu.map((sm, key) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                width: "98%",
                marginBottom: "5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  width: "95%",
                }}
              >
                <NavLink to={sm.link} className="nav-sub-link" key={key}>
                  {sm.label} {""}
                </NavLink>
              </div>
              {calculatedVariables[sm.fieldkey] && (
                <span style={circleBadgeStyle}>
                  {calculatedVariables[sm.fieldkey]
                    ? calculatedVariables[sm.fieldkey].length
                    : 0}
                </span>
              )}
            </div>
          );
        });
      }
      return (
        <li key={key} className={!sm ? "nav-item" : "nav-item show"}>
          {!sm ? (
            <NavLink to={m.link} className="nav-link">
              <i className={m.icon}></i> <span>{m.label} </span>
            </NavLink>
          ) : (
            <div 

            onClick={this.toggleSubMenu} 

            className="nav-link has-sub">
              <i className={m.icon}></i> <span>{m.label} </span>
            </div>
          )}
          {m.submenu && <nav className="nav nav-sub">{sm}</nav>}
        </li>
      );
    });

    return <ul className="nav nav-sidebar">{menu}</ul>;
  };

//   toggleSubMenu = (e) => {
//     e.preventDefault();

//     let parent = e.target.closest('.nav-item');
//     let node = parent.parentNode.firstChild;

//     while (node) {
//         if (node !== parent && node.nodeType === Node.ELEMENT_NODE)
//             node.classList.remove('show');
//         node = node.nextElementSibling || node.nextSibling;
//     }

//     parent.classList.toggle('show');

//     this.props.onUpdateSize();
// }

  // Toggle menu group
  // toggleMenu = (e) => {
  //   e.preventDefault();

  //   let parent = e.target.closest(".nav-group");
  //   parent.classList.toggle("show");
  //   this.props.onUpdateSize();
  //   window.location.reload();
  // };

  // Toggle submenu while closing siblings' submenu
  // toggleSubMenu = (e) => {
  //   e.preventDefault();

  //   // let parent = e.target.closest('.nav-item');
  //   let parent = e.target.closest(".nav-item");
  //   let node = parent.parentNode.firstChild;
  // alert(parent)
  // alert(node)
  //   console.log("parent",parent)
  //   console.log("node",node)
  

  //   while (node) {
  //     if (node !== parent && node.nodeType === Node.ELEMENT_NODE)
  //       node.classList.remove("show");
  //     node = node.nextElementSibling || node.nextSibling;
  //   }

  //   parent.classList.toggle("show");

  //   this.props.onUpdateSize();
  // };

  // toggleSubMenu = (e) => {
  //     e.preventDefault();

  //     // Trouver le parent le plus proche avec la classe 'nav-item'
  //     let parent = e.target.closest('.nav-item');

  //     // Vérifier si le parent est déjà ouvert
  //     const isOpen = parent.classList.contains('show');

  //     console.log("isOpen", isOpen)

  //     // Fermer tous les autres sous-menus ouverts
  //     let node = parent.parentNode.firstChild;
  //     while (node) {
  //         if (node !== parent && node.nodeType === Node.ELEMENT_NODE) {
  //             // node.classList.add('show');
  //             node.classList.remove('show');
  //         }
  //         node = node.nextElementSibling || node.nextSibling;
  //     }

  //     // Si le parent n'était pas ouvert, l'ouvrir; sinon, le laisser ouvert
  //     if (!isOpen) {
  //         parent.classList.add('show');
  //     }

  //     // Appeler onUpdateSize uniquement si le parent est ouvert
  //     if (!isOpen) {
  //         this.props.onUpdateSize();
  //     }

  //     // Arrêter la propagation du click depuis le lien
  //     e.stopPropagation();
  // }


  componentDidUpdate(prevProps, prevState) {
    if (this.props.profiles !== prevProps.profiles) {
      // Le tableau prof a été mis à jour, vous pouvez effectuer des actions ici
      const { modules, fonctionnalite, icons } = this.props;

      const pag = this.props.profiles.map((item) => {

        if (item.fonc.length > 0) {
          const ic = icons.find((ig) => ig.id == item.module);
          const modu = modules.find((md) => md.id_mod == item.module);

          if (ic && modu) {
            if (ic.etat == true) {
              const menu = item.fonc.map((sb) => {
                const dc = fonctionnalite.find((tl) => tl.id_fonc == sb);

                if (dc) {
                  return {
                    label: dc.lib_fonc,
                    link: `/${dc?.link}`,
                  };
                }
              });

              return {
                label: modu?.lib_mod,
                icon: ic?.icon,
                link: menu[0]?.link,
              };
            } else if (ic.fieldkey == true) {
              const menu = item.fonc.map((sb) => {
                const dc = fonctionnalite.find((tl) => tl.id_fonc == sb);

                if (dc) {
                  return {
                    label: dc.lib_fonc,
                    link: `/${dc.link}`,
                    fieldkey: dc.link,
                  };
                }
              });

              return {
                label: modu?.lib_mod,
                icon: ic?.icon,
                submenu: menu,
              };
            } else {
              const menu = item.fonc.map((sb) => {
                const dc = fonctionnalite.find((tl) => tl.id_fonc == sb);

                if (dc) {
                  return {
                    label: dc.lib_fonc,
                    link: `/${dc.link}`,
                  };
                }
              });

              return {
                label: modu?.lib_mod,
                icon: ic?.icon,
                submenu: menu,
              };
            }
          }
        } else {
          return null;
        }
      });

      const vv = pag.filter((uu) => uu != null);
      // console.log("pag", pag)
      // console.log("vv", vv)
      this.setState({ vv });
    }
  }

  render() {
    //     const { modules, fonctionnalite, profiles, icons } = this.props;

    //     const prof = profiles[2]?.mod_insert

    //  const pag = prof.map((item)=>{

    //         if (item.fonc.length > 0) {
    // const ic = icons.find((ig)=>(ig.id == item.module))
    // const modu = modules.find((md)=>(md.id_mod == item.module))

    // if (ic && modu) {

    //     const menu = item.fonc.map((sb)=>{
    //         const dc = fonctionnalite.find((tl)=>tl.id_fonc == sb)

    //            if (dc) {
    //             return {
    //                 label : dc.lib_fonc,
    //                 link : '/toutes_les_plaintes'
    //             }
    //            }

    //     })

    //     return {
    //         label : modu?.lib_mod,
    //         icon : ic?.icon,
    //         submenu : menu
    //     }
    // }

    //         }else{
    //             return null
    //         }
    //     })
    return (
      <React.Fragment>
        <div className="nav-group show">
          {/* <div className="nav-label"  onClick={this.toggleMenu}>
            PIGRU
          </div> */}
          {
            this.state.vv.length > 0 ?
          
          this.populateMenu(this.state.vv)
        
        :
        <div className="d-flex justify-content-center">
  <Spinner animation="border" />
</div>
        }

          
          {/* {this.populateMenu(pagesMenu)} */}
        </div>
        {/* <div className="nav-group show">
                    <div className="nav-label" onClick={this.toggleMenu}>Administration</div>
                    {this.populateMenu(administration)}
                </div> */}
      </React.Fragment>
    );
  }
}

// window.addEventListener("click", function (e) {
//   // Close sidebar footer menu when clicked outside of it
//   let tar = e.target;
//   let sidebar = document.querySelector(".sidebar");
//   if (!tar.closest(".sidebar-footer") && sidebar) {
//     sidebar.classList.remove("footer-menu-show");
//   }

//   // Hide sidebar offset when clicked outside of sidebar
//   if (!tar.closest(".sidebar") && !tar.closest(".menu-link")) {
//     document.querySelector("body").classList.remove("sidebar-show");
//   }
// });

// window.addEventListener("load", function () {
//   let skinMode = localStorage.getItem("sidebar-skin");
//   let HTMLTag = document.querySelector("html");

//   if (skinMode) {
//     HTMLTag.setAttribute("data-sidebar", skinMode);
//   }
// });
