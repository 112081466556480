import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Header from "../../../layouts/Header.js";
import Footer from "../../../layouts/Footer.js";
import Sidebar from "../../../layouts/Sidebar.js";
import { NavLink } from "react-router-dom";
import TableListePlaintes from "../dataTables/TableListePlaintes.js";
import { useSelector } from "react-redux";
import StepsOfComplain from '../StepsOfComplain.js'
import { getUserInfo } from "../../../redux/slice/userSlice.js";
import Spinner from "react-bootstrap/Spinner";
import ComplementInformation from '../ComplementInformation.js'
import ResultatDesInvestigations from '../ResultatDesInvestigations.js'
import RapportViewer from '../RapportViewer.js'
import TableDetails from "../TableDetails.js";

import {
    useCreateTypePlainteMutation,
    useGetPlaintesQuery,
    useGetStatutPlaintesQuery,
    useGetTypePlaintesQuery,
    useCreateObsPlainteMutation,
    useUpdatePlainteMutation,
} from "../../../redux/apiSlice/plainteApiSlice.js";
import { useGetOperateursQuery } from "../../../redux/apiSlice/operateurApiSlice.js";
import Historique from "../Historique/Historique.js";
import Observation from "../Observation/Observation.js";

export default function AccuseReception() {
    const [updatePlainte, { isLoading: loadUpdatePlainte }] =
        useUpdatePlainteMutation();

    const [shouldIDisplay, setshouldIDisplay] = useState(false)
    const [shouldIDisplay2, setshouldIDisplay2] = useState(false)
    const [shouldIDisplay3, setshouldIDisplay3] = useState(false)


    const [resultatDesInvestigations, setresultatDesInvestigations] = useState(false)
    const {
        data: plaintes,
        refetch: refetchPlaintes,
        isLoading: loadPlaintes,
        isSuccess: isSuccessPlaintes,
    } = useGetPlaintesQuery();
    const { data: typePlaintes = [], isLoading: loadTypePlaintes } =
        useGetTypePlaintesQuery();
    const { data: statutPlaintes, isLoading: loadStatutPlainte } =
        useGetStatutPlaintesQuery();
    const { data: operateurs = [], isLoading: loadOperateur } =
        useGetOperateursQuery();
    const [createTypePlainte, { isLoading: loadCreateTypePlainte }] =
        useCreateTypePlainteMutation();

    // modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [mycomplaint, setComplaint] = useState();
    const handleShow = (complaint) => {
       
        setComplaint(complaint);
        setShow(true);
    };

    const [accusé, setAccuse] = useState({ liste: [] });

    useEffect(() => {
        if (isSuccessPlaintes) {
            // Data is available, and you can proceed to use it
            console.log(plaintes);
            const selectedItems = plaintes.liste.filter(item => item.id_stat_plt === 3 && item.id_type_plt != null && item.id_service != null)
            // Additional logic or state updates
            setAccuse({ liste: selectedItems })
        }
    }, [isSuccessPlaintes, plaintes]);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const user = useSelector(getUserInfo);

    ///// Skin Switch /////
    const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
    const [skin, setSkin] = useState(currentSkin);

    const switchSkin = (skin) => {
        if (skin === "dark") {
            const btnWhite = document.getElementsByClassName("btn-white");

            for (const btn of btnWhite) {
                btn.classList.add("btn-outline-primary");
                btn.classList.remove("btn-white");
            }
        } else {
            const btnOutlinePrimary = document.getElementsByClassName(
                "btn-outline-primary"
            );

            for (const btn of btnOutlinePrimary) {
                btn.classList.remove("btn-outline-primary");
                btn.classList.add("btn-white");
            }
        }
    };

    useEffect(() => {
        refetchPlaintes();
        console.log(localStorage.getItem("token"));
        if (localStorage.getItem("token") === "") {
            window.location.href = "/";
        }
        // console.log(retour_plainte);
    }, []);

    switchSkin(skin);

    useEffect(() => {
        switchSkin(skin);
    }, [skin]);

    if (loadPlaintes) {
        return <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh", // 100% of the viewport height
            }}
        >
            <Spinner
                as="span"
                animation="border"
                size="md"
                role="status"
                aria-hidden="true"
            />
        </div>;
    }

    if (!isSuccessPlaintes) {
        return <div>Error fetching data</div>;
    }

    return (
        <React.Fragment>
            <Sidebar />
            <Header onSkin={setSkin} />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item">
                                <NavLink to="/dashboard">Dashboard</NavLink>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Classée
                            </li>
                        </ol>
                        <h4 className="main-title mb-0">Plainte classée</h4>
                    </div>
                </div>

                <div>
                    <TableListePlaintes
                        handleclose={handleClose}
                        handleshow={handleShow}
                        plaintes={accusé}
                    />
                </div>
                {/* ----------------Modal de détails----------- */}
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    size="xl"
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="w-100 text-center" style={{fontSize : 30, color: "#000",}}>Détails Plainte</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <div className="container">
                            <div className="row">
                                <div className="col">
                                    <div className="mb-2">
                                        <h5>Type de plainte</h5>
                                        <Form.Label>
                                            {mycomplaint?.Type_plainte.lib_type_plt}
                                        </Form.Label>
                                    </div>
                                    <div className="mb-2">
                                        <h5>Opérateur Concerné</h5>
                                        <Form.Label>
                                            {mycomplaint?.Operateur.nom_operateur}
                                        </Form.Label>
                                    </div>
                                    <div className="mb-2">
                                        <h5>Service concerné</h5>
                                        <Form.Label>{mycomplaint?.service}</Form.Label>
                                    </div>
                                    <div className="mb-2">
                                        <h5>Type de personne</h5>
                                        <Form.Check
                                            checked={true}
                                            type="radio"
                                            label={
                                                mycomplaint?.type_personne === 0
                                                    ? "Personne physique"
                                                    : "Personne morale"
                                            }
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <h5>Email</h5>
                                        <Form.Label>{mycomplaint?.email_plt}</Form.Label>
                                    </div>
                                    <div className="mb-2">
                                        <h5>Téléphone</h5>
                                        <Form.Label>{mycomplaint?.tel_plt}</Form.Label>
                                    </div>
                                    <div className="mb-2">
                                        <h5>Adresse géographique</h5>
                                        <Form.Label>{mycomplaint?.adresse_plt}</Form.Label>
                                    </div>
                                    <div className="mb-2">
                                        <h5>
                                            Avez-vous fait un recours à l’opérateur au sujet de votre
                                            plainte depuis au moins un mois ?
                                        </h5>
                                        <Form.Check checked={true}
                                            type="radio"
                                            label='OUI' />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="mb-2">
                                        <h5>
                                            Avez-vous obtenu une réponse de la part de l’opérateur ?
                                        </h5>
                                        <Form.Check checked={true} type="radio" label="Oui mais pas satisfait(e)" />
                                    </div>
                                    <div className="mb-2">
                                        <h5>
                                            Référence de la plainte déposée auprès de l’opérateur
                                        </h5>
                                        <Form.Label>{mycomplaint?.ref_plt}</Form.Label>
                                    </div>
                                    <div className="mb-2">
                                        <h5>Résumé de la plainte</h5>
                                        <Form.Label>{mycomplaint?.desc_plt}</Form.Label>
                                    </div>
                                    <div className="mb-2">
                                        <h5>Date du premier constat</h5>
                                        <Form.Label>{mycomplaint?.date_constat}</Form.Label>
                                    </div>
                                    <div className="mb-2">
                                        <h5>Canal de retour souhaité</h5>
                                        <Form.Check checked={true} type="radio" label={
                                            mycomplaint?.canal_retour === 'email'
                                                ? "E-mail"
                                                : "Téléphone"
                                        } />
                                    </div>
                                </div>
                            </div>
                            <Historique plainte={mycomplaint} />
                        </div> */}
                        <TableDetails mycomplaint={mycomplaint}/>
                        <Observation plainte={mycomplaint} />
              
                        <Historique plainte={mycomplaint} />
                    </Modal.Body>
                    <Modal.Footer>
                        <StepsOfComplain Genererrapport={() => { setshouldIDisplay3(true); handleClose() }} setTheshouldIDisplay={() => { setshouldIDisplay(true); handleClose() }} complainStatus={mycomplaint?.id_stat_plt} complainId={mycomplaint?.id_plt} resultatDesInvestigations={() => { setshouldIDisplay2(true); handleClose() }} />
                    </Modal.Footer>
                </Modal>

                {shouldIDisplay && <ComplementInformation display={shouldIDisplay} handleClose={() => { setshouldIDisplay(false) }} id_plt={mycomplaint?.id_plt} />}
                {shouldIDisplay2 && <ResultatDesInvestigations display2={shouldIDisplay2} handleClose2={() => { setshouldIDisplay2(false) }} id_plt={mycomplaint?.id_plt} />}
                {shouldIDisplay3 && <RapportViewer display3={shouldIDisplay3} handleClose3={() => { setshouldIDisplay3(false) }} mycomplaint={mycomplaint} />}

                {/* ----------------modal de modification---------- */}
                <Modal
                    show={show1}
                    onHide={handleClose1}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Modification</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose1}>
                            Fermer
                        </Button>
                        <Button variant="primary" onClick={handleClose1}>
                            Valider
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Footer />
            </div>
        </React.Fragment>
    );
}
