import React, { useEffect, useState } from "react";
import { Button, Form,  Card  } from "react-bootstrap";
import Select from "react-select";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Link } from "react-router-dom";
import Sidebar from "../../layouts/Sidebar";
import Stepper from 'react-stepper-horizontal';
import { NavLink } from "react-router-dom";
import { useGetTypeObligationsQuery } from "../../redux/slice/typeObligationApiSlice";
import { useGetSecteurObligationsQuery } from "../../redux/apiSlice/secteurObligationApiSlice";
import { useGetCategorieObligationsQuery } from "../../redux/slice/categorieObligationApiSlice";
import { useGetTypeOperateursQuery } from "../../redux/slice/typeOperateurApiSlice";
import { useGetSourceVerificationsQuery } from "../../redux/slice/verificationApiSlice";
import { useGetPeriodicitiesQuery } from "../../redux/slice/periodiciteApiSlice";
import { useGetEcheancesQuery } from "../../redux/slice/echeanceApiSlice";
import { useGetDirectionsQuery } from "../../redux/slice/directionApiSlice";
import { useCreateObligationMutation } from "../../redux/slice/obligationApiSlice";
import { useUpdateObligationMutation } from "../../redux/slice/obligationApiSlice";
import { useGetElementObligationsQuery } from "../../redux/slice/elementControleApiSlice";
import Swal from "sweetalert2";
import { setWith } from "lodash";
import { useNavigate } from "react-router-dom";

export default function AjoutOR() {
 
  const navigate = useNavigate()
  const currentDate = new Date().toISOString().split('T')[0];
   const [startDate, setStartDate] = useState(new Date());
   const [selectedOption, setSelectedOption] = useState([]);
   const [notifChamp, setNotifChamp] = useState(false);
   const [valueType, setValueType] = useState();
   const [valueSecteur, setValueSecteur] = useState();
   const [valueCat, setValueCat] = useState();
   const [valueOp, setValueOp] = useState();
   const [valueSource, setValueSource] = useState();
   const [valuePeriode, setValuePeriode] = useState();
   const [valueEcheance, setValueEcheance] = useState();
   const [valueElement, setValueElement] = useState();
  
   const init ={ 
  
   lib_obligation_reg : "",
   textes_ref : "",
   articles : "",
   id_cat_obligation_reg : "",
   id_secteur_obligation : "",
   id_type_ob :"",
   id_type_op :"",
   id_source :"",
   id_periode :"",
   id_echeance :"",
   id_elt :"" ,
 
  }

  const [form, setForm] = useState({...init});



  ///// Skin Switch /////
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  }

  // api tranches 
  const {data: typeObligations = [], isLodaing: loadTypeObligation} = useGetTypeObligationsQuery()

  const {data: secteurObligations = [], isLodaing: loadSecteurObligation} = useGetSecteurObligationsQuery()

  const {data: categorieObligations = [], isLoading: loadCategorieObligation} = useGetCategorieObligationsQuery()

  
  const {data: typeOperateur = [], isLoading: loadTypeObligations} = useGetTypeOperateursQuery()


  const {data: typeSource = [], isLoading: loadTypeSource} = useGetSourceVerificationsQuery()

  const {data: elements = [], isLoading: loadElements} = useGetElementObligationsQuery()

  const {data: periode = [], isLoading: loadPeriode} = useGetPeriodicitiesQuery()
// console.log("periode", periode)
  const {data: echeance = [], isLoading: loadTypeEcheance} = useGetEcheancesQuery()

  const {data: direction = [], isLoading: loadDirection} = useGetDirectionsQuery()

  const [createData, {isLoading: loadCreate}] = useCreateObligationMutation()

  const [updateData, {isLoading: loadUpdate}] = useUpdateObligationMutation()


  

  const changeForm = (field, value) => {

    switch (field) {
      case "id_type_ob":
        setForm({...form, [field]: value})
        const retour =typeObligations.filter((item)=>item.id_type_ob == value)
        setValueType(retour[0].lib_type_ob)
        break;
    
      case "id_cat_obligation_reg":
        setForm({...form, [field]: value})
        const retour2 =categorieObligations.filter((item)=>item.id_cat_obligation_reg == value)
        setValueCat(retour2[0].lib_cat_obligation_reg)
        break;

      case "id_secteur_obligation":
        setForm({...form, [field]: value})
        const retour3 =secteurObligations.filter((item)=>item.id_secteur_obligation == value)
        setValueSecteur(retour3[0].lib_secteur_obligation)
        break;
    
      case "id_type_op":
        setForm({...form, [field]: value})
        const retour4 =typeOperateur.filter((item)=>item.id_type_op == value)
        setValueOp(retour3[0].lib_type_op)
        break;
    
      case "id_source":
        setForm({...form, [field]: value})
        const retour5 =typeSource.filter((item)=>item.id_source == value)
        setValueSource(retour4[0].lib_source)
        break;
    
      case "id_periode":
        setForm({...form, [field]: value})
        const retour6 =periode.filter((item)=>item.id_periode == value)
        setValuePeriode(retour5[0].lib_periode)
        break;
      case "id_echeance":
        setForm({...form, [field]: value})
        const retour7 =echeance.filter((item)=>item.id_echeance == value)
        setValueEcheance(retour6[0].lib_echeance)
        break;

      case "id_elt":
        setForm({...form, [field]: value})
        const retour8 =elements.filter((item)=>item.id_elt == value)
        setValueElement(retour7[0].lib_elt)
        break;
    
      default:
        setForm({...form, [field]: value})
        break;
    }
  
  
  }

  switchSkin(skin);

  const [currentStep, setCurrentStep] = useState(0);
 


  const steps = [
   { title: 'Première phase' },
    { title: 'Deuxième phase' },
  ];

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleChange = (e) => {
 
 };

 const controleNextStep =()=>{

  const vid = form.lib_obligation_reg !="" &&
    form.textes_ref !="" &&
    form.articles !="" &&
    form.id_cat_obligation_reg != ""&&
    form.id_secteur_obligation != ""&&
    form.id_type_ob !=""

 
    if (!vid) {
      setNotifChamp(true)
    } else{
      setNotifChamp(false)
      handleNext()
    }
 }
 const validateForm =()=>{

  const retour = selectedOption.map(item => item.value);
  

  const data ={
  lib_obligation_reg :form.lib_obligation_reg,
  textes_ref :form.textes_ref,
  articles :form.articles,
  is_deleted:1,
  id_cat_obligation_reg :form.id_cat_obligation_reg,
  id_secteur_obligation :form.id_secteur_obligation,
  id_type_ob :form.id_type_ob,
  id_type_op :form.id_type_op,
  id_source :form.id_source,
  id_periode :form.id_periode,
  id_user :4,
  id_echeance :form.id_echeance,
  id_elt :1 ,
  list_direction : retour
  }


  const vid = data.id_type_op !=""&&
              data.id_source !="" &&
              data.id_periode !=""&&
              data.id_user !=""&&
              data.id_echeance !=""&&
              data.id_elt !="" &&
              data.list_direction.length > 0

    if (!vid) {

      setNotifChamp(true)
    } else{
      setNotifChamp(false)
      save(data)

    }
 }

 const save = async(form) => {

  try {
console.log("form",form)
      if (form?.id_obligation_reg) {

          await updateData(form).unwrap()
          setForm({...init})
          Swal.fire({
            title: "SUCCES!",
            text: "Action réussie",
            icon: "success"
          });
          navigate("/nouvelle-obligation-reglmt") ;
      } else {
   
          await createData(form).unwrap()
         
          setForm({...init})
          Swal.fire({
            title: "SUCCES!",
            text: "Action réussie",
            icon: "success"
          });
      }
      navigate("/nouvelle-obligation-reglmt") ;
      
  } catch (error) {

// console.log("error", error)
      Swal.fire({
        title: "Erreur!",
        text: "Une erreur s'est produite",
        icon: "error"
      });

    
  }
}

  useEffect(() => {
    switchSkin(skin);

   
  }, [skin]);

  return (
    <React.Fragment>
      <Sidebar/>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><NavLink to="/dashboard-obligation-reglmt" >Obligations Règlementaires</NavLink></li>
              <li className="breadcrumb-item active" aria-current="page">Nouvelle obligation</li>
            </ol>
            <h4 className="main-title mb-0">Ajout d'obligation</h4>
          </div>
        
        </div>

        <div className="container ">
        <Stepper steps={steps} activeStep={currentStep} />

        <div>
        {currentStep === 0 && (
       <div className='container p-4'>
       <h6 className='p-2'>Identification obligations réglementaires</h6>

       <div className="row">
          
          <div className="col">
            
          <Form.Label htmlFor="exampleFormControlInput1">Secteur d’obligation :</Form.Label>
             <Form.Select aria-label="Default select example"
              onChange={e => changeForm('id_secteur_obligation', e.target.value)}>

                <option value="">{valueSecteur ? valueSecteur : "Choisir"}</option>
               
            {
              secteurObligations.map((item)=>(
                <option value={item.id_secteur_obligation}>{item.lib_secteur_obligation}</option> 
              ))
            }

            </Form.Select>
            <p style={{color: 'red'}}>{
             notifChamp  && form.id_secteur_obligation == "" ? "Remplissez ce champ" : ""
              }</p>
          
          </div>
          <div className="col">
            
          <Form.Label htmlFor="exampleFormControlInput1">Type d’obligation :</Form.Label>
             <Form.Select aria-label="Default select example"
              onChange={e => changeForm('id_type_ob', e.target.value)}>

                <option value="">{valueType ? valueType : "Choisir"}</option>
               
            {
              typeObligations.map((item)=>(
                <option value={item.id_type_ob}>{item.lib_type_ob}</option> 
              ))
            }

            </Form.Select>
            <p style={{color: 'red'}}>{
             notifChamp  && form.id_type_ob == "" ? "Remplissez ce champ" : ""
              }</p>
          
          </div>
          <div className="col">
          <Form.Label htmlFor="exampleFormControlInput1">Catégorie de l’obligation :</Form.Label>
             <Form.Select aria-label="Default select example"
               onChange={e => changeForm('id_cat_obligation_reg', e.target.value)}
             
             >
              <option value="">{valueCat ? valueCat : "Choisir"}</option>
             
             {
              categorieObligations.map((item)=>(
                <option value={item.id_cat_obligation_reg}>{item.lib_cat_obligation_reg}</option> 
              ))
            }
            </Form.Select>
            <p style={{color: 'red'}}>{
             notifChamp  && form.id_cat_obligation_reg == "" ? "Remplissez ce champ" : ""
              }</p>
  
          </div>
       </div>
       <div className="row mt-4">
       <div className="col">
          <Form.Label htmlFor="exampleFormControlInput1">Libellé :</Form.Label>
          <Form.Control as="textarea" id="exampleFormControlTextarea1" rows="3" placeholder="Entrer votre texte de référence..."
            onChange={e => changeForm('lib_obligation_reg', e.target.value)}
            value={form.lib_obligation_reg}
          ></Form.Control>
   <p style={{color: 'red'}}>{
             notifChamp  && form.lib_obligation_reg == "" ? "Remplissez ce champ" : ""
              }</p>
          </div>
          <div className="col">
          <Form.Label htmlFor="exampleFormControlTextarea1">Textes de références</Form.Label>
  <Form.Control as="textarea" id="exampleFormControlTextarea1" rows="3" placeholder="Entrer votre texte de référence..." 
   onChange={e => changeForm('textes_ref', e.target.value)}
   value={form.textes_ref}
  ></Form.Control>
           <p style={{color: 'red'}}>{
             notifChamp  && form.textes_ref == "" ? "Remplissez ce champ" : ""
              }</p>
          </div>

          <div className="col">
          <Form.Label htmlFor="exampleFormControlTextarea1">Articles</Form.Label>
  <Form.Control as="textarea" id="exampleFormControlTextarea1" rows="3" placeholder="Entrer l'article..."
   onChange={e => changeForm('articles', e.target.value)}
   value={form.articles}
  ></Form.Control>
          <p style={{color: 'red'}}>{
             notifChamp  && form.articles == "" ? "Remplissez ce champ" : ""
              }</p>
          </div>
       </div>

     </div>

      )}
      {currentStep === 1 && (
         <div className='container  p-4'>
         <h6 className='mb-3'>Deuxieme phase</h6>

         <div className="row">
            <div className="col">
            <Form.Label htmlFor="exampleFormControlInput1">Type d'opérateur  :</Form.Label>
             <Form.Select aria-label="Default select example"
               onChange={e => changeForm('id_type_op', e.target.value)}
             >
           <option value="">{valueOp ? valueOp : "Choisir"}</option>
             {
              typeOperateur.map((item)=>(
                <option value={item.id_type_op}>{item.lib_type_op}</option>
              ))
            }
            </Form.Select>
            <p style={{color: 'red'}}>{
             notifChamp  && form.id_type_op == "" ? "Remplissez ce champ" : ""
              }</p>
            </div>
            <div className="col">

            <Form.Label htmlFor="exampleFormControlInput1">Élément de contrôle:</Form.Label>
            <Form.Select aria-label="Default select example"
             onChange={e => changeForm('id_elt', e.target.value)}
            >
             <option value="">{valueElement ? valueElement : "Choisir"}</option>
              {
              elements.map((item)=>(
                <option value={item?.id_ele}>{item?.lib_elt}</option>
              ))
            }
          
            </Form.Select>
            <p style={{color: 'red'}}>{
             notifChamp  && form.id_elt == "" ? "Remplissez ce champ" : ""
              }</p>
            </div>
            <div className="col">
            
            <Form.Label htmlFor="exampleFormControlInput1">Source de vérification :</Form.Label>
             <Form.Select aria-label="Default select example"
               onChange={e => changeForm('id_source', e.target.value)}
             >
              <option value="">{valueSource ? valueSource : "Choisir"}</option>
             {
              typeSource.map((item)=>(
                <option value={item?.id_source}>{item?.lib_source}</option>
              ))
            }
            </Form.Select>
            <p style={{color: 'red'}}>{
             notifChamp  && form.id_source == "" ? "Remplissez ce champ" : ""
              }</p>
            </div>
         </div>
         <div className="row mt-5">
            <div className="col">

            <Form.Label htmlFor="exampleFormControlInput1">Périodicité (en jours) :</Form.Label>
             <Form.Select aria-label="Default select example"
              onChange={e => changeForm('id_periode', e.target.value)}
             >
            <option value="">{valuePeriode ? valuePeriode : "Choisir"}</option>
             {
              periode.map((item)=>(
                <option value={item.lib_periode}>{item.lib_periode}</option>
              ))
            }
            </Form.Select>
            <p style={{color: 'red'}}>{
             notifChamp  && form.id_periode == "" ? "Remplissez ce champ" : ""
              }</p>
            </div>
            <div className="col">
            <Form.Label htmlFor="exampleFormControlInput1">Échéance règlementaire de la procédure :</Form.Label>
             <Form.Select aria-label="Default select example"
               onChange={e => changeForm('id_echeance', e.target.value)}
             >
            <option value="">{valueEcheance ? valueEcheance : "Choisir"}</option>
             {
              echeance.map((item)=>(
                <option value={item.id_echeance}>{item.lib_echeance}</option>
              ))
            }
            </Form.Select>
            <p style={{color: 'red'}}>{
             notifChamp  && form.id_echeance == "" ? "Remplissez ce champ" : ""
              }</p>
            </div>
            <div className="col">
            <Form.Label htmlFor="exampleFormControlTextarea1">Direction en charge</Form.Label>
             <Select 
              options={direction.map((item)=>{
                return{
                  value : item.id_direction,
                  label : item.nom_direction
                }
              })} 
              isSearchable={true} isMulti 
              onChange={setSelectedOption}
              /> 
              <p style={{color: 'red'}}>{
             notifChamp  && selectedOption.length == 0 ? "Remplissez ce champ" : ""
              }</p>
            </div>
           
         </div>
 
       </div>
      )}
      
        </div>


        </div>
        <div className="container">
         <div className="row">
            <div className="col d-flex justify-content-center align-items-center">
            {currentStep > 0 && 
        <Button onClick={handlePrev} variant="primary">Précédent</Button>
        }
            </div>
            <div className="col"></div>

            <div className="col d-flex justify-content-center align-items-center">
            {currentStep < steps.length - 1 && 

        <Button onClick={()=>controleNextStep()} variant="success">Suivant</Button>
        }
            {currentStep == 1 && 
        <Button  variant="success" onClick={()=>validateForm()}>Enrégistrer</Button>
        }
            </div>
         </div>
        </div>

        
        <Footer />
      </div>
    </React.Fragment>
  )
}