import React, {useEffect} from "react";
import { Button, Spinner } from "react-bootstrap";
import moment from "moment";
import { useGetVerdicteParPlainteQuery } from "../../../redux/apiSlice/plainteApiSlice";

const Verdicts = (props) => {
  const { plainte } = props;

  const { data: verdiscts = [], isLoading } = useGetVerdicteParPlainteQuery(
    {id_plt : plainte.id_plt}
  );
// console.log('plainte.id_plt', plainte.id_plt)
// console.log('verdiscts', verdiscts)
  const soustitreStyle = {
    fontSize: '1.4em', // Taille de la police
    fontWeight: 'bolder', // Gras
    color: '#1f5a92', // Couleur du texte (par exemple, bleu Bootstrap)
    // Ajoutez d'autres propriétés de style au besoin
  };

  const tabletitreStyle = {
    fontSize: '1.0em', // Taille de la police
    fontWeight: 'bolder', // Gras
    color: '#1f5a92', // Couleur du texte (par exemple, bleu Bootstrap)
    // Ajoutez d'autres propriétés de style au besoin
  };


  const contenuStyle = {
    fontSize: '1.0em', // Taille de la police
    fontWeight: 'normal', // Gras
    color: '#000', // Couleur du texte (par exemple, bleu Bootstrap)
    // Ajoutez d'autres propriétés de style au besoin
  };
  useEffect(() => {
   
    
  }, [verdiscts]);

  if (isLoading) {
    return (
      <div class="card">
        <Button variant="primary" disabled style={{ height: 200 }}>
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          Loading...
        </Button>
      </div>
    );
  }
 
  return (
    plainte.id_stat_plt > 7 && (
      <>
        <h4 style={soustitreStyle}>&nbsp; Décisions de la plainte</h4>
        <div class="card ml-5">
          <div class="card-body">
            <div class="table-responsive ">
              <table class="table mb-0 table-striped table-hover">
                <thead>
                  <tr>
                    <th style={tabletitreStyle} scope="col-1">#</th>
                    <th style={tabletitreStyle} scope="col-4">Décision</th>
                    <th style={tabletitreStyle} scope="col-4">Commentaire</th>
                    <th  style={tabletitreStyle} scope="col-3">Date de décision</th>
                  </tr>
                </thead>

                <tbody>
                  {verdiscts.map((verdict, key) => (
                    <tr key={key}>
                      <td class=" col-1" style={contenuStyle} scope="row">{++key}</td>
                      <td class=" col-4" style={contenuStyle}>{verdict.Decision_plainte?.lib_dec}</td>
                      <td class=" col-4" style={contenuStyle}>{verdict.commentaire == null ? "-": verdict.commentaire}</td>
                      <td class=" col-3" style={contenuStyle}>{moment(verdict.createdAt).format("DD/MM/YYYY")}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default Verdicts;
