import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import Footer from "../../../layouts/Footer.js";
import Header from "../../../layouts/Header.js";
import Swal from "sweetalert2";
import Sidebar from "../../../layouts/Sidebar.js";
import { useSelector } from "react-redux";
//Operateur et clients
import { useGetOperateursQuery } from "../../../redux/apiSlice/operateurApiSlice.js";
import {
  useGetAttrIspcQuery,
  useGetAttrMncQuery,
  useGetAttrUssdQuery,
  useGetClientsQuery,
} from "../../../redux/apiSlice/numerotationApiSlice.js";

import {
  useGetListeIspcQuery,
  useGetListeMncQuery,
  useGetUssdQuery,
} from "../../../redux/apiSlice/statistiquePlainteApiSlice.js";
import { getUserInfo } from "../../../redux/slice/userSlice.js";

import TableNumerotationCat from "./dataTables/TableNumerotationCat.js";

export default function ListePlainte() {
  const { data: lesOps } = useGetOperateursQuery();
  const { data: lesClients } = useGetClientsQuery();
  const { data: attrMnc } = useGetAttrMncQuery();
  const { data: attrIspc } = useGetAttrIspcQuery();
  const { data: attrUssd } = useGetAttrUssdQuery();
  const { data: ussd = [] } = useGetUssdQuery();
  const { data: liste_ussd = [] } = useGetUssdQuery();
  const { data: liste_ispc = [] } = useGetListeIspcQuery();
  const { data: liste_mnc = [] } = useGetListeMncQuery();
  const initialisateur = {
    id_operateur: "",
    id_clt: "",
    id_status: "",
    cat_ressource: "",
    elt_filtre: "",
  };
  const [lesChamp, setLesChamp] = useState({ ...initialisateur });
  const [dataAttr, setDataAttr] = useState([]);

  const stat = (value) => {
    switch (value) {
      case 1:
        return "Libre";
      case 2:
        return "En instance";
      case 3:
        return "Réservée";
      case 4:
        return "Attribuée";
      case 5:
        return "Bloquée";
      case 6:
        return "Inutilisable";
      default:
        break;
    }
  };

  const cat_ressource_selectionnee = (val) => {
    const categories = ["mnc", "ispc", "ussd"];
    if (categories.includes(val)) {
      return val;
    } else {
      return categories[val];
    }
  };

  const liste_cat_ress = (typ) => {
    switch (typ) {
      case "mnc":
        setDataAttr(() => {
          const tabFormater = liste_mnc?.map((us) => {
            return {
              lib: us?.lib_mnc,
              statut: stat(us?.id_stat_num),
            };
          });

          return tabFormater;
        });
        break;
      case "ispc":
        setDataAttr(() => {
          const tabFormater = liste_ispc?.map((us) => {
            return {
              lib: us?.lib_ispc,
              statut: stat(us?.id_stat_num),
            };
          });
          return tabFormater;
        });
        break;
      case "ussd":
        setDataAttr(() => {
          const tabFormater = liste_ussd?.map((us) => {
            return {
              lib: us?.Ussd_list?.libelle,
              statut: stat(us?.id_stat_num),
            };
          });
          if (tabFormater && tabFormater.length > 0)
            return tabFormater
              .slice()
              .sort((a, b) => a.lib.localeCompare(b.lib));
          return [];
        });
        break;
      default:
        setDataAttr([]);
        break;
    }
  };

  const liste_ress_par_statut = (statut, cat) => {
    switch (cat) {
      case "mnc":
        setDataAttr(() => {
          const ress_mnc = liste_mnc.filter(
            (ress) => ress.id_stat_num === parseInt(statut)
          );
          const tabFormater = ress_mnc?.map((us) => {
            return {
              lib: us?.lib_mnc,
              statut: stat(us?.id_stat_num),
            };
          });
          return tabFormater;
        });
        break;
      case "ispc":
        setDataAttr(() => {
          const ress_ispc = liste_ispc.filter(
            (ress) => ress.id_stat_num === parseInt(statut)
          );
          const tabFormater = ress_ispc?.map((us) => {
            return {
              lib: us?.lib_ispc,
              statut: stat(us?.id_stat_num),
            };
          });
          return tabFormater;
        });
        break;
      case "ussd":
        setDataAttr(() => {
          const ress_ussd = liste_ussd.filter(
            (ress) => ress.id_stat_num === parseInt(statut)
          );
          const tabFormater = ress_ussd?.map((us) => {
            return {
              lib: us?.Ussd_list?.libelle,
              statut: stat(us?.id_stat_num),
            };
          });
          if (tabFormater && tabFormater.length > 0)
            return tabFormater
              .slice()
              .sort((a, b) => a.lib.localeCompare(b.lib));
          return [];
        });
        break;
      default:
        setDataAttr([]);
        break;
    }
  };

  const recuperation_op_client = (elt_filtre, cat) => {
    switch (cat) {
      case "mnc":
        setDataAttr(() => {
          let mnc_filtrer = attrMnc.filter(
            (ress_mnc, key) => ress_mnc.type_occupant === parseInt(elt_filtre)
          );

          const tabFormater = mnc_filtrer?.map((us) => {
            return {
              lib: us?.Mnc?.lib_mnc,
              statut: stat(us?.Mnc?.id_stat_num),
            };
          });
          return tabFormater;
        });

        break;
      case "ispc":
        let ispc_filtrer = attrIspc.filter(
          (ress_ispc, key) => ress_ispc.type_occupant === parseInt(elt_filtre)
        );

        setDataAttr(() => {
          const tabFormater = ispc_filtrer?.map((us) => {
            return {
              lib: us?.Ispc?.lib_ispc,
              statut: stat(us?.Ispc?.id_stat_num),
            };
          });
          return tabFormater;
        });
        break;
      case "ussd":
        let ussd_filtrer = attrUssd.filter(
          (ress_ussd, key) => ress_ussd.type_occupant === parseInt(elt_filtre)
        );

        const lesUssdOpOuClt = ussd_filtrer.map((us) => {
          return ussd.find((dataUs) => dataUs.id_ussd === us.id_ussd);
        });
        setDataAttr(() => {
          const tabFormater = lesUssdOpOuClt?.map((us) => {
            return {
              lib: us?.Ussd_list?.libelle,
              statut: stat(us?.id_stat_num),
            };
          });
          if (tabFormater && tabFormater.length > 0)
            return tabFormater
              .slice()
              .sort((a, b) => a.lib.localeCompare(b.lib));
          return [];
        });
        break;
      default:
        setDataAttr([]);
        break;
    }
  };

  const ress_op_ou_client = (val, cat) => {
    switch (cat) {
      case "ussd":
        let ussd_filtrer = attrUssd.filter(
          (ress_ussd, key) =>
            ress_ussd.type_occupant === parseInt(lesChamp.elt_filtre)
        );
        // let opOuClt = val == "0" ? lesChamp.id_operateur : lesChamp.id_clt;
        let ussdOpOuClt = ussd_filtrer.filter(
          (ussd, key) => ussd.id_occupant === parseInt(val)
        );
        const lesUssdOpOuClt = ussdOpOuClt.map((us) => {
          return ussd.find((dataUs) => dataUs.id_ussd === us.id_ussd);
        });
        setDataAttr(() => {
          const tabFormater = lesUssdOpOuClt?.map((us) => {
            return {
              lib: us?.Ussd_list?.libelle,
              statut: stat(us?.id_stat_num),
            };
          });
          if (tabFormater && tabFormater.length > 0)
            return tabFormater
              .slice()
              .sort((a, b) => a.lib.localeCompare(b.lib));
          return [];
        });
        break;
      case "ispc":
        let ispc_filtrer = attrIspc.filter(
          (ress_ispc, key) =>
            ress_ispc.type_occupant === parseInt(lesChamp.elt_filtre)
        );
        // let opOuCltIspc =
        //   lesChamp.elt_filtre == "0" ? lesChamp.id_operateur : lesChamp.id_clt;
        let ispcOpOuClt = ispc_filtrer.filter(
          (ispc, key) => ispc.id_occupant === parseInt(val)
        );
        setDataAttr(() => {
          const tabFormater = ispcOpOuClt?.map((us) => {
            return {
              lib: us?.Ispc?.lib_ispc,
              statut: stat(us?.Ispc?.id_stat_num),
            };
          });
          return tabFormater;
        });
        break;
      case "mnc":
        let mnc_filtrer = attrMnc.filter(
          (ress_mnc, key) =>
            ress_mnc.type_occupant === parseInt(lesChamp.elt_filtre)
        );
        // let opOuCltMnc =
        //   lesChamp.elt_filtre == "0" ? lesChamp.id_operateur : lesChamp.id_clt;
        let mncOpOuClt = mnc_filtrer.filter(
          (mnc, key) => mnc.id_occupant === parseInt(val)
        );
        setDataAttr(() => {
          const tabFormater = mncOpOuClt?.map((us) => {
            return {
              lib: us?.Mnc?.lib_mnc,
              statut: stat(us?.Mnc?.id_stat_num),
            };
          });
          return tabFormater;
        });
        break;
      default:
        break;
    }
  };

  const ressourcesAttribueeOpClt = (champ, value) => {
    if (value === "") {
      setLesChamp(initialisateur);
      setDataAttr([]);
      return;
    }
    setLesChamp({ ...lesChamp, [champ]: value });

    switch (champ) {
      case "cat_ressource":
        liste_cat_ress(value);
        break;
      case "id_status":
        if (lesChamp.cat_ressource !== "") {
          const categorie_r = cat_ressource_selectionnee(
            lesChamp.cat_ressource
          );
          liste_ress_par_statut(value, categorie_r);
        } else {
          setDataAttr([]);
        }

        break;
      case "elt_filtre":
        if (lesChamp.cat_ressource !== "") {
          const categorie_r = cat_ressource_selectionnee(
            lesChamp.cat_ressource
          );
          recuperation_op_client(value, categorie_r);
        } else {
          setDataAttr([]);
        }
        break;
      case "id_operateur":
        if (lesChamp.cat_ressource !== "") {
          const categorie_r = cat_ressource_selectionnee(
            lesChamp.cat_ressource
          );
          ress_op_ou_client(value, categorie_r);
        } else {
          setDataAttr([]);
        }
        break;
      case "id_clt":
        if (lesChamp.cat_ressource !== "") {
          const categorie_r = cat_ressource_selectionnee(
            lesChamp.cat_ressource
          );
          ress_op_ou_client(value, categorie_r);
        } else {
          setDataAttr([]);
        }
        break;
      default:
        break;
    }
  };

  const user = useSelector(getUserInfo);

  //   useEffect(() => {
  //     if (user?.id) {
  //       localStorage.setItem("user_id", user?.id);
  //     }
  //     if (
  //       localStorage.getItem("user_id") === "" ||
  //       !localStorage.getItem("user_id")
  //     ) {
  //       window.location.href = "/";
  //     }
  //   }, []);

  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Numerotation</Link>
              </li>
              {/* <li className="breadcrumb-item active" aria-current="page">Finance Monitoring</li> */}
            </ol>
            <h4 className="main-title mb-0">Statistique attribution</h4>
          </div>
        </div>

        <Row className="g-3">
          <Col xl="12">
            <Row className="g-2">
              <Col sm="12" xl="4">
                <Card className="card-one" style={{ height: "120px" }}>
                  <Card.Body className="overflow-hidden px-0">
                    <div className="finance-info p-3 p-xl-4 pb-4">
                      <Row>
                        <Col sm="12" xl="12" className="d-flex flex-column justify-content-center align-items-center">
                          <label className="fs-sm fw-medium mb-2 mb-xl-1">
                            Nombre de ressources
                          </label>

                          <h4 className="finance-value">{dataAttr?.length}</h4>
                        </Col>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="12" xl="12">
                <Row>
                  <Col sm="12" xl="2">
                    <Form.Label htmlFor="categorie_ressources">
                      Catégorie ressources :
                    </Form.Label>

                    <Form.Select
                      id="categorie_ressources"
                      value={lesChamp.cat_ressource}
                      onChange={(e) =>
                        ressourcesAttribueeOpClt(
                          "cat_ressource",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Choisir</option>
                      <option value="ussd">Ussd</option>
                      <option value="ispc">Ispc</option>
                      <option value="mnc">Mnc</option>
                    </Form.Select>
                  </Col>
                  <Col sm="12" xl="2">
                    <Form.Label htmlFor="statut">Statuts :</Form.Label>

                    <Form.Select
                      id="statut"
                      value={lesChamp.id_status}
                      onChange={(e) =>
                        ressourcesAttribueeOpClt("id_status", e.target.value)
                      }
                    >
                      <option value="">Choisir</option>
                      <option value="3">Réservée</option>
                      <option value="1">Libre</option>
                      <option value="2">En instance</option>
                      <option value="4">Attribuée</option>
                      <option value="6">Inutilisable</option>
                      <option value="5">Bloquée</option>
                    </Form.Select>
                  </Col>
                  <Col sm="12" xl="4">
                    <Form.Label htmlFor="elt_filtre">
                      Elément de filtre :
                    </Form.Label>

                    <Form.Select
                      id="elt_filtre"
                      value={lesChamp.elt_filtre}
                      onChange={(e) =>
                        ressourcesAttribueeOpClt("elt_filtre", e.target.value)
                      }
                    >
                      <option value="">Choisir</option>
                      <option value="1">Clients</option>
                      <option value="0">Opérateurs</option>
                    </Form.Select>
                  </Col>
                  {lesChamp.elt_filtre === "0" && (
                    <Col sm="12" xl="4">
                      <Form.Label htmlFor="op">Opérateur :</Form.Label>

                      <Form.Select
                        id="op"
                        value={lesChamp.id_operateur}
                        onChange={(e) =>
                          ressourcesAttribueeOpClt(
                            "id_operateur",
                            e.target.value
                          )
                        }
                      >
                        <option value="">Choisir</option>
                        {lesOps?.map((ope, key) => (
                          <option key={key} value={ope.id_operateur}>
                            {ope.nom_operateur}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  )}

                  {lesChamp.elt_filtre === "1" && (
                    <Col sm="12" xl="4">
                      <Form.Label htmlFor="lesclients">Clients :</Form.Label>

                      <Form.Select
                        id="lesclients"
                        value={lesChamp.id_clt}
                        onChange={(e) =>
                          ressourcesAttribueeOpClt("id_clt", e.target.value)
                        }
                      >
                        <option value="">Choisir</option>
                        {lesClients?.map((client, key) => (
                          <option key={key} value={client.id_clt}>
                            {client.nom_clt}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  )}
                </Row>
              </Col>
              <Col sm="12" xl="12">
                <p className="w-50 fs-sm mb-2 mb-xl-4 d-none d-sm-block">
                  Liste des ressources
                </p>
                <TableNumerotationCat cat_ressource={dataAttr} />
              </Col>
            </Row>
          </Col>
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  );
}
