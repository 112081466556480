import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  Card,
  Col,
  Nav,
  ProgressBar,
  Row,
} from "react-bootstrap";
import { PDFViewer } from "@react-pdf/renderer";
import {
  useUpdatePlainteStatutMutation,
  useGetObsPlaintesQuery,
  useGetResultatObsQuery,
  useGetDecisionPlainteQuery,
  useCreateVerdicteMutation,
  useGetVerdicteParPlainteQuery,
  useUpdateVerdicteMutation,
} from "../../redux/apiSlice/plainteApiSlice.js";

import Swal from "sweetalert2";

import Rapport from "./Rapport";

const RapportViewer = ({ mycomplaint, display3, handleClose3 }) => {

 

  const [pdfContent, setPdfContent] = useState(null);

  const {
    data: lesDecision,
    refetch: refecthDecision,
    isLoading: loadDecision,
  } = useGetDecisionPlainteQuery();

  const [createVerdicte] = useCreateVerdicteMutation();

  const { data: repererVerdictePlainte, refetch: refecthVerdictPlainte } =
    useGetVerdicteParPlainteQuery({ id_plt: mycomplaint?.id_plt });
    // console.log("repererVerdictePlainte", repererVerdictePlainte)
  const [updateVerdicte] = useUpdateVerdicteMutation();

 
  const [deci, setDeci] = useState([]);
  const [deciReserv, setDeciReserv] = useState([]);
  console.log("deciReserv", deciReserv)
  const [comtaire, setComtaire] = useState("");
  const [objSuivi, setObjSuivi] = useState(false);
  const [objRedac, setObjRedac] = useState({id : "", text :""});
  const [avis, setAvis] = useState(null);
// console.log("deci", deci)
  const [uneAction, setUneAction] = useState(0);

  const [showComment, setShowComment] = useState(false);

  const handleShowComment =(id)=>{
    setObjRedac({...objRedac, id_dec : parseInt(id) })
    setShowComment(true)
  }

  const handleCloseComment =()=>setShowComment(false)

  const {
    data: obsPlainte,
    refetch: refecthObs,
    isLoading: loadObs,
  } = useGetObsPlaintesQuery(mycomplaint?.id_plt);
  const [updatePlainteStatut, { isLoading: loadUpdateState }] =
    useUpdatePlainteStatutMutation();
  const {
    data: resultObs,
    refetch: refecthresultObs,
    isLoading: loadresultObs,
  } = useGetResultatObsQuery(mycomplaint?.id_plt);

  useEffect(() => {
    refecthObs();
    refecthresultObs();
    const generatedPdfContent = (
      <Rapport
        obsPlainte={obsPlainte}
        resultObs={resultObs}
        mycomplaint={mycomplaint}
      />
    );

    // Convert the PDF content to a string
    const pdfContentString = generatedPdfContent.toString();

    // Set the PDF content in the state
    setPdfContent(pdfContentString);
  }, [mycomplaint?.id_plt]);

  const uploaad = () => {
    handleClose3()
    try {
      Swal.fire({
        title: "Action irréversible !",
        text: "Cette action est irréversible !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui", cancelButtonText: "Non",
      }).then(async (result) => {
        if (result.isConfirmed === true) {

          const select = deci.filter((item)=>(item.statut == true))

          const data = select.map((item)=>{
            if (item.comment == "") {
              return{
                id_dec : item.id_dec,
                commentaire : null
              }
            } else {
              return{
                id_dec : item.id_dec,
                commentaire : item?.comment
         
              }
            }
           
          })

// console.log("data", data)
          if (repererVerdictePlainte.length == 0) {
         
            // console.log("mycomplaint?.id_plt", mycomplaint?.id_plt)
            // console.log("objSuivi", objSuivi)
         
            await createVerdicte({
              id_plt: mycomplaint?.id_plt,
              suivi : objSuivi,
              dec: data,
            });

           
          } else {
       
            // console.log("mycomplaint?.id_plt", mycomplaint?.id_plt)
            // console.log("avis", avis)
            // console.log("objSuivi", objSuivi)
         
           
            const rr = await updateVerdicte({
              id: repererVerdictePlainte[0]?.id,
              id_plt: mycomplaint?.id_plt,
              suivi : objSuivi,
               avis,
              dec: data,
            });
        
            
          }

          const retour_plainte2 = await updatePlainteStatut({
            id_stat_plt: mycomplaint?.id_stat_plt === 9 ? 10 : 9,
            id_plt: mycomplaint?.id_plt,
          }).unwrap();

          if (retour_plainte2) {
          
            console.log("retour_plainte2", retour_plainte2)
            window.location.href = "/toutes_les_plaintes";
          }
        }
      });

      // refetchPlaintes();
    } catch (error) {
     
      console.error("Error creating obs state:", error);
    }
  };

  const checkAction =(id, value)=>{

    
if (value == true || value == false) {
  const objet = deciReserv.map((item)=>{

    if (item.id_dec == id) {
      return{...item, statut : value}
    } else {
      return item
    }
  })

setDeci(objet)
setDeciReserv(objet)
} else {

  const objet = deciReserv.map((item)=>{

    if (item.id_dec == id) {
      return{...item, comment : value}
    } else {
      return item
    }
  })

setDeci(objet)
setDeciReserv(objet)
handleCloseComment()
setObjRedac({id : "", text :""})
}

  }


  useEffect(() => {
    if (lesDecision) {
    const devF = lesDecision.map((item)=>{
     return {
       ...item, 
       statut : false,
       comment : ""
     }
    })
 
    setDeci(devF)
    setDeciReserv(devF)
    }
    
   }, [lesDecision]);
  return (
    <>
    <Modal show={display3} onHide={handleClose3} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Sélectionner une action</Modal.Title>
      </Modal.Header>
      <Modal.Body>

     
      { deci.map((item)=>{

                  return(
        <Row className="g-2 mb-5" key={item.id_dec}>

<Col sm="1" xl="1">
  
           </Col>
                
          <Col sm="5" xl="5">
 
<Form.Check type="checkbox" 
checked={item.statut} 
label={item.lib_dec} 
onChange={(e)=>{checkAction(item.id_dec, e.target.checked)}}

/>
           
          </Col>

          <Col sm="6" xl="6">   
           {
            item.statut == true &&  mycomplaint?.id_stat_plt != 9 &&

            <>
            <Form.Control
           
            type="text"
            value={item.comment}
            disabled
        />
            <span style={{textDecorationLine : 'underline', cursor: 'pointer', color: 'blue'}}
            onClick={()=>handleShowComment(item.id_dec)}
            >
            Commenter
            </span>
            </>
            }
                      
          </Col>
         
        </Row>
        )
                })
              }

{
        mycomplaint?.id_stat_plt != 9 &&
        <>
        <Row className="" >
      <Form.Label>La plainte nécéssite t-elle un suivi ultérieure ?</Form.Label>
      </Row>
      <Row className="mb-5" >

       <Col sm="5" xl="5">
       
<Form.Check type="radio" 
checked={objSuivi === false } 
label="Non"
onChange={()=>setObjSuivi(false)}
/>
         
          </Col>

          <Col sm="6" xl="6">   
          <Form.Check type="radio" 
checked={objSuivi === true }
label="Oui"
onChange={()=>setObjSuivi(true)}
/>       
          </Col>
         
        </Row>
        </>
        }
 {
  mycomplaint?.id_stat_plt === 9 &&
  <Row className="mt-5" >
 <Form.Label htmlFor="exampleFormControlTextarea1">
           Avis du conseil
          </Form.Label>
          <Form.Control
            onChange={(v)=>setAvis(v.target.value)}
            as="textarea"
            value={avis}
            id="exampleFormControlTextarea1"
            rows="8"
            placeholder="Entrer votre commentaireeeee..."
          ></Form.Control>
      </Row>}
        <Row className="mt-5">
        <Col >
           
          </Col>
        <Col className="text-end" >
            <Button variant="success" onClick={uploaad}>
              { mycomplaint?.id_stat_plt === 9 ? 'Clôturer la plainte' : 'Valider'}
            </Button>
          </Col>
        </Row>

        {/* <PDFViewer width="100%" height="600px">
          <Rapport
            obsPlainte={obsPlainte}
            resultObs={resultObs}
            mycomplaint={mycomplaint}
          />
        </PDFViewer> */}
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="secondary" onClick={uploaad}>
          Passer à la soumission du rapport
        </Button>
      </Modal.Footer> */}
    </Modal>

    <Modal show={showComment} onHide={handleCloseComment} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Commentaire </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row >
            <Col sm="12" xl="12">
              
            <Form.Label htmlFor="cmt">
          
          </Form.Label>
          <Form.Control
            onChange={(v)=>setObjRedac({...objRedac, text :v.target.value })}
            as="textarea"
            value={objRedac.text}
            id="cmt"
            rows="2"
            placeholder="Entrer votre commentaire..."
          ></Form.Control>

              
            </Col>
            
          </Row>
          <Row >
            
            
          </Row>
          <Row className="mt-2">
            <Col >
              
            </Col>
            <Col className="d-flex flex-row justify-content-end gap-2">
            <Button variant="secondary" onClick={handleCloseComment}>
                Annuler
              </Button>
              <Button variant="success" onClick={()=>checkAction(objRedac.id_dec, objRedac.text)}>
                Valider
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default RapportViewer;
