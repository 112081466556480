import React,  { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import Spinner from "react-bootstrap/Spinner";
import { Button } from 'primereact/button';
import { Button as Btn , Form, Table, Modal , Badge } from "react-bootstrap";
import '../styleNumerotation.css'
import Select from "react-select";
import AddOutlineIcon from '@rsuite/icons/AddOutline'
import { useCreateOblgOperateurMutation } from '../../../redux/slice/oblgOperateurApiSlice';
import Swal from 'sweetalert2';
import { format } from 'date-fns';
import 'jspdf-autotable';
import moment from "moment";
import {
    useGetServiceTrancheQuery,
    useGetSousTrancheQuery,
    useGetTrancheQuery,
    useUpdateRessourceMutation,
    useUpdateReserveRessourceMutation,
    useUpdateLibererRessourceMutation,
  
    useGetClientsQuery,
    useGetAllRessourcesQuery,
    useGetRessourceListeTrancheQuery,
    useGetRessourceListeSousTrancheQuery,
    useGetRessourceAllServiceQuery,
    useGetOccupantsQuery,

    useGetbycompQuery,
    useGetCompQuery,

    useGetStatutNumQuery,

    useGetCompLotQuery,
    useGetAttrCompLotQuery,
    useCreateNextLotMutation,
    useUpdateUseLotMutation

  } from "../../../redux/apiSlice/numerotationApiSlice";
  import { useGetOperateursQuery } from "../../../redux/apiSlice/operateurApiSlice";
  import AjoutClientModal from "../AjoutClientModal";
  



export default function TableRessources(props) {

    const { data: ressources = [], isLoading : loadRessource } = useGetAllRessourcesQuery();

    // console.log("ressources", ressources)
  
    const { data: tranches = [] } = useGetTrancheQuery();
    const { data: sousTranches = [] } =
      useGetSousTrancheQuery();
    // console.log("tranches", tranches)
    // console.log("sousTranches", sousTranches)
    const { data: services = [] } =
      useGetServiceTrancheQuery();
    const { data: operateur = [] } =
      useGetOperateursQuery();
      const { data: statutNum = [], isLoading: loadStatutNum } =
      useGetStatutNumQuery();

  
    const { data: clients = [] } = useGetClientsQuery();
    const { data: occupants = [] } = useGetOccupantsQuery();
    const { data: listeOccuper = [] } = useGetCompQuery();
  
    // console.log("clients", clients)
  
    const [createNextLot] = useCreateNextLotMutation();
    const [updateUseLot] = useUpdateUseLotMutation();
    const [updateRessource] = useUpdateRessourceMutation();
   


    
  const [globalFilter, setGlobalFilter] = useState([]);
  const [stateRessouces, setStateRessouces] = useState([]);
  const [stateTranche, setStateTranche] = useState([]);
  const [stateSousTranche, setStateSousTranche] = useState([]);
  const [stateService, setStateService] = useState([]);
  const [stateStatut, setStatetatut] = useState([]);

  const [afftranche, setAffTranche] = useState([]);
  const [affSousTranche, setAffSousTranche] = useState([]);
  const [affService, setAffService] = useState([]);
  const [affStatut, setAffStatut] = useState([]);

 
// console.log('expoStat', expoStat)
  const [ctrl, setCtrl] = useState(0);

  const [infos, setInfos] = useState({});
  const [infosAttr, setInfosAttr] = useState([]);
  const [reni, setReni] = useState(0);
  const [load, setLoad] = useState(false);

  const [loader, setLoader] = useState(false);


 let monTab =[]

  const init = {
    id_occupant: 0,
    num_decision: "",
    services: "",
    commentaire: "",
    date_attr: "",
    type_occupant: 0,
  };
  const lot = {
    id_comp: 0,
    date_attr: "",
    id_lot: 0,
  };

  const use = {
    id_attr: 0,
    nb_use: 0,
    nb_actif: 0,
  };

  const [form, setForm] = useState({ ...init });
  const [statut_num, setStatut_num] = useState(0);
  const [nextLot, setNextLot] = useState({...lot});
  const [seeLot, setSeeLot] = useState([]);
  const [useLot, setUseLot] = useState({...use});
 
  // modal
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setForm(init)
    setShow(false)
  };

  const handleShow = (rowData) => {

    // console.log("rowData", rowData)
    setCtrl(rowData.id_stat_num);

    if (rowData.id_stat_num == 1) {
    
      setForm({ ...form, id_comp: rowData.id_comp });
    } else {
      const retour = listeOccuper.find((item)=>(item.id_comp  == rowData.id_comp))
       if (retour) {
  setForm(retour);
       }

    }

    setShow(true);
  };
 

  const [ajoutClient, setAjoutClient] = useState(false);
  const handleAjoutClient = () => setAjoutClient(!ajoutClient);

  // modal 
  const [showInfOcup, setShowInfOcup] = useState(false);
  const handleInfOcup = () => setShowInfOcup(!showInfOcup)

  // modal infos Lots
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(!show2);

  // modal set Lots
  const [setLot, setSetLot] = useState(false);
  const handleSertLot = () => setSetLot(!setLot);

  // modal set Lots
  const [showUse, setShowUse] = useState(false);
  const handleUseLot = () => setShowUse(!showUse);

  const formatDate = (data) => {
    const timestamp = typeof data === "string" ? parseInt(data, 10) : data;
    const date = new Date(timestamp);
    if (isNaN(date.getTime())) {
      return "Date invalide";
    }
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();

    return formattedDate;
  };

  const [etat, setEtat] = useState(0);
  const [id_tranche, setId_tranche] = useState(0);
  const [id_sous_tranche, setId_sous_tranche] = useState(0);
  const [id_service, setId_service] = useState(0);
  const [id_comp, setId_comp] = useState(0);
  const [id_comp_lot, setId_comp_lot] = useState();
  const [expState, setExpState] = useState([]);



  const { data: attrCompoLot = [], isLoading:loadAttrCompoLot } =
  useGetAttrCompLotQuery();

  const { data: listeCompoLot = [], isLoading:loadCompoLot } =
  useGetCompLotQuery();

  const { data: listeTranche = [], isLoading:loadTranche } =
    useGetRessourceListeTrancheQuery(id_tranche);

  const { data: listeSousTranche = [], isLoading : loadSousTranche } =
    useGetRessourceListeSousTrancheQuery(id_sous_tranche);

  const { data: parService = [], isLoading : loadService } = useGetRessourceAllServiceQuery({
    id_service,
  });

  const { data: occuper = [], isLoading : loadOccuper} = useGetbycompQuery({id_comp});

  // console.log("attrCompoLot", attrCompoLot)
  // console.log("serviceTranche", serviceTranche)
  // console.log("occuper", occuper)
  // console.log("occupeTranche", occupeTranche)
  // console.log("parService", parService)
  // console.log("etat", etat)
  // console.log("id_tranche", id_tranche)
  // console.log("id_service", id_service)

  const save = async () => {
    handleClose();
    Swal.fire({
      title: "Gestion des ressources",
      text: "Voulez-vous continuer' ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then(async (result) => {
      if (result.isConfirmed === true) {

// console.log("form", form)

if (statut_num == 0) {
  Swal.fire({
    title: "ERREUR",
    text: "Veuillez choisir un statut",
    icon: "error",
  });
} else if ((statut_num == 3 && form.id_occupant == 0) || (statut_num == 4 && form.id_occupant == 0)){ 

  Swal.fire({
    title: "ERREUR",
    text: "Veuillez choisir un occupant",
    icon: "error",
  });
} else {
  // console.log("form",form)
  await updateRessource({...form, 
    id_stat_num : parseInt(statut_num) , 
    date_attr : form.date_attr !="" ? 
    new Date(form.date_attr).getTime() 
    : null})
  .unwrap()
  .then((payload) => {
    Swal.fire({
      title: "SUCCES",
      text: "réussie",
      icon: "success",
    });

    window.location.reload();
  })
  .catch((error) => {

    console.log('error1', error)
    Swal.fire({
      title: "ERREUR",
      text: "Une erreur s'est produite , veuillez réessayer",
      icon: "error",
    });
    setForm(init)
  });

}
       
      }
    });
  };
  const saveNextLot = async () => {
    handleClose();
    Swal.fire({
      title: "Gestion des PQ",
      text: "Voulez-vous attribuer ce lot' ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then(async (result) => {
      if (result.isConfirmed === true) {

// console.log("form", form)

if (nextLot.id_lot == 0) {
  Swal.fire({
    title: "ERREUR",
    text: "Veuillez choisir un lot",
    icon: "error",
  });
} else {
  await createNextLot(
    {...nextLot, date_attr : nextLot.date_attr !="" ? new Date (nextLot.date_attr).getTime() : ""})
  .unwrap()
  .then((payload) => {
    Swal.fire({
      title: "SUCCES",
      text: "réussie",
      icon: "success",
    });

    window.location.reload();
  })
  .catch((error) => {

    console.log('error1', error)
    Swal.fire({
      title: "ERREUR",
      text: "Une erreur s'est produite , veuillez réessayer",
      icon: "error",
    });
    setNextLot(lot)
  });

}
       
      }
    });
  };
  const saveUseLot = async () => {
    handleClose();
    Swal.fire({
      title: "Gestion des lot",
      text: "Voulez-vous continuer' ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then(async (result) => {
      if (result.isConfirmed === true) {


if ( useLot.nb_use < 0 ||  useLot.nb_actif < 0) {
  Swal.fire({
    title: "ERREUR",
    text: "Veuillez un nombre supérieur à zéro",
    icon: "error",
  });
} else {
  // console.log("useLot", useLot)
  await updateUseLot(useLot)
  .unwrap()
  .then((payload) => {
    Swal.fire({
      title: "SUCCES",
      text: "réussie",
      icon: "success",
    });

    window.location.reload();
  })
  .catch((error) => {

    console.log('error1', error)
    Swal.fire({
      title: "ERREUR",
      text: "Une erreur s'est produite , veuillez réessayer",
      icon: "error",
    });
    setUseLot(use)
  });

}
       
      }
    });
  };
 
  const handleChangeAtt = (value) => {

    setForm({ ...form, id_occupant: parseInt(value.value) })
  }

  const handleChangeLot = (value) => {

    setNextLot({ ...nextLot, id_lot: parseInt(value.value) })
  }

  const showInfOccupant = (rowData)=>{
   
        const retour = listeOccuper.find((item)=>(item.id_comp  == rowData.id_comp))
       
        if (retour) {
          setInfos(retour)
          handleInfOcup()   
        } 
  }
  const showInfoCompoLot = (rowData) => {

    const retour = attrCompoLot.filter((item) => item.id_comp == rowData.id_comp);

    if (retour.length > 0) {
      setInfosAttr(retour);
      handleClose2();
    }  
    
}
  const showsetLot = (rowData) => {
    let jeu
    parseInt(rowData.tranche?.lib_tranche) === 2 ? jeu = 2 : jeu =0
    setNextLot({...nextLot, id_comp : rowData.id_comp})

    const retour = attrCompoLot.filter((item) => item.id_comp == rowData.id_comp);

// console.log("listeCompoLot", listeCompoLot)
    const lotRest = listeCompoLot.map((item)=>{
      const obj = retour.find((at)=>(at.id_lot == item.id_lot && rowData.id_comp == at.id_comp))
      if (obj) {
        return null
      } else {
        return item
      }
    })
    // console.log("lotRest", lotRest
    // )
    const final = lotRest.filter((fn)=>fn != null && fn.tranche == jeu)
    // console.log("final", final
    // )
    if (final) {
      setSeeLot(final);
      handleSertLot();
    }  
}

const setNbreUse = (id_attr)=>{
  setUseLot({...useLot, id_attr })
  handleUseLot()
}


 


  const handleChange = (lib, value) => {

    switch (lib) {
      case 'tranche':
         setEtat(1)
        const objetRes = sousTranches.filter((item) => (item.id_tranche == value.value))
        setId_tranche(value.value)
        

        if (objetRes.length > 0) {
          setReni(0)
          const sousT = objetRes.map((item) => {
            return {
              label: item.lib_s_tran,
              value: item.id_s_tran,
              id_tranche: item.id_tranche,
               
            }
          })
          setStateSousTranche(sousT)
        } else {
          setReni(1)
        }


        break;
      case 'sousTranche':
        setEtat(2)
      
        setId_sous_tranche(value.value)
        break;


      case 'service':
        setEtat(3)
        setId_service(value.value);
        break;

      case 'statut':
        setEtat(4)

        const objetResult = listeTranche.filter((item) => (
          item.id_stat_num == parseInt(value.value)
        ))
        setAffStatut(objetResult)

        break;

      default:
        break;
    }
  };


    const dt = useRef(null);
 
    const cols = [
        // { field: 'tranche.lib_tranche', header: 'Tranche', sortable: true },

        { field: 'lib_comp', header: "Ressources", sortable: true },
        { field: 'etat', header: "Etat", sortable: true },
        { field: 'op', header: "Opérateur", sortable: true },
        { field: 'dt', header: "Date d'attribution", sortable: true },
        { field: 'actions', header: "Actions", sortable: false },
    ];

    const colsExpo = [
        { field: 'ressource', header: 'Ressources', sortable: true },
        { field: 'etat', header: "Etat", sortable: true },
        { field: 'operateur', header: "Operateur", sortable: true },
        { field: 'date_attr', header: "Date d'attribution", sortable: true },
        { field: 'commentaire', header: "Commentaire", sortable: true },
    ];

    const exportColumns = colsExpo.map((col) => ({ title: col.header, dataKey: col.field }));

   
    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

    const expoData = (data) => {
      let monTab = [];
    
      data.forEach((item, index) => {
        const rsul = listeOccuper.find((oc) => oc.id_comp === item.id_comp);
    
        if (rsul) {
          monTab.push({
            ressource: item.lib_comp,
            etat: stat(parseInt(item.id_stat_num)),
            operateur: giveInfOp(rsul, operateur, clients),
            date_attr: rsul.date_attr == null || parseInt(rsul.date_attr) === 0 ? "-" : formatDate(rsul.date_attr),
            commentaire: rsul.commentaire || "-"
          });
        } else {
          monTab.push({
            ressource: item.lib_comp,
            etat: stat(parseInt(item.id_stat_num)),
            operateur: "-",
            date_attr: "-",
            commentaire: "-"
          });
        }
    
        if (index === data.length - 1) {
          console.log('monTab', monTab);
          return monTab;
        }
      });
    
      return monTab;
    };
    
    

    const docPdf = ()=>{
        switch (etat) {
            case 1:
             
                return  afftranche; 
                
            case 2:
           
                return affSousTranche
                
            case 3:
            
                return affService
                
            case 4:
        
                return affStatut
                
            default:
                break;
        }
    }

    const badge = (value) => {
      switch (value) {
        case 1:
          return "dark";
        case 2:
          return "secondary";
        case 3:
          return "warning";
        case 4:
          return "success";
        case 5:
          return "danger";
        case 6:
          return "danger";
        default:
          break;
      }
    };

    const stat = (value) => {
      switch (value) {
        case 1:
          return "Libre";
        case 2:
          return "En instance";
        case 3:
          return "Réservée";
        case 4:
          return "Attribuée";
        case 5:
          return "Bloquée";
        case 6:
          return "Inutilisable";
        default:
          break;
      }
    };

    const giveInfOp = (data, operateur, clients) => {
      if (parseInt(data?.type_occupant) === 0) {
        const result = operateur.find((item) => item.id_operateur === data?.id_occupant);
        return result?.nom_operateur;
      } else {
        const result = clients.find((item) => item.id_clt === data?.id_occupant);
        return result?.nom_clt;
      }
    };
    
    
    useEffect(() => {

      const tranch = tranches.map((item) => {
        return {
          label: item.lib_tranche,
          value: item.id_tranche
        }
      })
      setStateTranche(tranch)
     
      const serv = services
      .filter((type) => type.id_service != 15 && type.id_service != 16 && type.id_service != 17)
      .map((item) => {
        return {
          label: item.lib_service,
          value: item.id_service
        }
      })
  
      setStateService(serv)
   
      setAffTranche(listeTranche)
  
      setAffSousTranche(listeSousTranche)
  
      setAffService(parService)
  
      setAffStatut(listeTranche)
  
      // setStateRessouces(ressources)
  
    }, [
      listeTranche, 
      listeSousTranche,
      parService,
      // ressources
    ]);
    const exportPdf = (data) => {
     
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);

                doc.autoTable(exportColumns, data);
                doc.save('Ressources PNM.pdf');
            });
        });
    };

    

    const exportExcel = (data) => {
       
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(data);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            
        });
    };

    const saveAsExcelFile = (data) => {
      console.log("data", data)
      import('xlsx').then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(data);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer = xlsx.write(workbook, {
            bookType: 'xlsx',
            type: 'array'
        });

        import('file-saver').then((module) => {
          if (module && module.default) {
              let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
              let EXCEL_EXTENSION = '.xlsx';
              const data = new Blob([excelBuffer], {
                  type: EXCEL_TYPE
              });

              module.default.saveAs(data, "Ressources PNM" + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
          }
      });
    });



        
    };

    const header = (
        <div className="container_header_data_table">
            <div className="flex align-items-center">
                
                <div className="p-input-icon-left ml-3">
                    <i className="pi pi-search" />
                    <InputText value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Rechercher" />
                </div>
            </div>
            <div className='container_export'>
                <Button type="button" icon="pi pi-file-pdf" rounded 
                onClick={()=>{
                  exportPdf(etat == 1 ? expoData(afftranche) :
                  etat  == 2 ? expoData(affSousTranche) :
                  etat  == 3 ?  expoData(affService) :
                   expoData(affStatut) 
                  )
                
                }} 
                  
                  className="" data-pr-tooltip="PDF" style={{borderRadius : 30}} severity="secondary" size="small"/>
                <Button type="button" icon="pi pi-file-excel" className=""severity="success" rounded onClick={()=>{exportExcel(etat == 1 ? expoData(afftranche) :
                  etat  == 2 ? expoData(affSousTranche) :
                  etat  == 3 ?  expoData(affService) :
                   expoData(affStatut) )}} data-pr-tooltip="Excel" style={{borderRadius : 30}} size="small"/>
                <Button type="button" icon="pi pi-file" rounded onClick={() => saveAsExcelFile(etat == 1 ? expoData(afftranche) :
                  etat  == 2 ? expoData(affSousTranche) :
                  etat  == 3 ?  expoData(affService) :
                   expoData(affStatut))} className="" data-pr-tooltip="CSV" style={{borderRadius : 30}} size="small"/>
            </div>
        </div>
    );

    const actionBodyTemplate = (rowData) => {
      return (
          <div className="flex align-items-center">
              {rowData.id_stat_num !== 1 && (
                  <a
                      onClick={() => showInfOccupant(rowData)}
                      className="btn btn-secondary mr-2"
                      style={{ cursor: "pointer" }}
                  >
                      Détails
                  </a>
              )}
  
              <a
                  onClick={() => handleShow(rowData)}
                  className="btn btn-success mr-2"
                  style={{ cursor: "pointer" }}
              >
                  Actions
              </a>
  
              {/* Condition pour rendre les boutons "Détails lots" et "PQ" */}
              {parseInt(rowData?.tranche?.lib_tranche) !== 1 && 
              parseInt(rowData?.tranche?.lib_tranche) !== 7 && 
              parseInt(rowData?.tranche?.lib_tranche) !== 8 && 
              parseInt(rowData?.tranche?.lib_tranche) !== 3 && 
              parseInt(rowData?.tranche?.lib_tranche) !== 8 && 
              rowData?.id_stat_num !== 1 && (
                  <>
                      <a
                          onClick={() => showInfoCompoLot(rowData)}
                          className="btn btn-warning mr-2"
                          style={{ cursor: "pointer" }}
                      >
                          Détails lots
                      </a>
                      <a
                          onClick={() => showsetLot(rowData)}
                          className="btn btn-info"
                          style={{ cursor: "pointer" }}
                      >
                          PQ
                      </a>
                  </>
              )}
          </div>
      );
  };
  

    
const etatComp = (rowData) => {
        return (
            <div className="flex align-items-center">
        <Badge bg={badge(rowData.id_stat_num)}>
                 {stat(rowData.id_stat_num)}
                      </Badge>
               
            </div>
        );
    };
    const operateurComp = (rowData) => {
    
let op 

if(rowData.id_stat_num == 1){
op = "-"
}else{
  
  const retour = listeOccuper.find((item)=>(item.id_comp  == rowData.id_comp))
  // console.log("retour", retour)
if (retour) {
  
  if (retour?.type_occupant == 0) {
    
const rsul = operateur.find((item)=>(item.id_operateur == retour?.id_occupant))
    op = rsul?.nom_operateur

  } else {
    //  console.log("retour?.id_occupant", retour?.id_occupant)
    const rsul = clients.find((item)=>(item.id_clt == retour?.id_occupant))

    
    op = rsul?.nom_clt
  }


} 


}

        return (
            <div className="flex align-items-center">
        {op}
               
            </div>
        );
    };

    const dateComp = (rowData) => {
    
let dt 

if(rowData.id_stat_num == 1){
dt = "-"
}else{
   const retour = listeOccuper.find((item)=>(item.id_comp  == rowData.id_comp))
  
if (retour) {
  
 dt = retour?.date_attr == null ? "" : parseInt(retour?.date_attr)  == 0 ? "": formatDate(retour?.date_attr)
 
 
//  formatDate(retour?.date_attr)


} 


}

        return (
            <div className="flex align-items-center">
        {dt}
               
            </div>
        );
    };
    

    const rowClassName = (rowData, index) => {

        return index !==  docPdf().length - 1 ? 'table-row' : ''; 
       
    };
   
  //   console.log("loadRessource", loadRessource)
  // console.log("loadTranche", loadTranche)
  // console.log("loadSousTranche", loadSousTranche)
  // console.log("loadService", loadService)
  return(
        
        <div className="card" style={{width: '100%'}}>
            <Tooltip target=".export-buttons>button" position="bottom" />
            <style>{`
            .table-row td {
                border-bottom: 1px solid #dee2e6; // Bordure inférieure pour les cellules de la ligne
            }
        `}</style>

<div className="container row mb-5">


          <div className="col">
            <Form.Label>Tranches</Form.Label>
            <Select
              options={stateTranche}
              isSearchable={true}
              onChange={(v) => handleChange('tranche', v)}
            />
          </div>

          <>

            <div className="col">

              <Form.Label>Sous tranches</Form.Label>
              {reni == 0 ?

                <Select
                  options={stateSousTranche}
                  isSearchable={true}
                  onChange={(v) => handleChange('sousTranche', v)}
                />
                :
                <>
                  <br />
                  <span style={{ color: 'red' }}>PAS DE SOUS TRANCHE</span>
                </>


              }
              
            </div>


          </>
      
        
        <div className="col">
          <Form.Label>Services</Form.Label>
          <Select
            options={stateService}
            isSearchable={true}
            onChange={(v) => handleChange('service', v)}
          />
        </div>

        
          {
            listeTranche.length > 0 &&
            <div className="col">
            <Form.Label>Statut</Form.Label>
            <Select
              options={[
                { label: "Libre", value: 1 },
                { label: "En instance", value: 2 },
                { label: "Réservée", value: 3 },  
                { label: "Attribuée", value: 4 },
                { label: "Bloquée", value: 5 },
                { label: "Inutilisable", value: 6 },
              ]}
              isSearchable={true}
              onChange={(v) => handleChange('statut', v)}
            />
          </div>}

      </div>
      <>

    {  loadTranche  || loadSousTranche || loadService ?
     <div class="card">
      <Btn variant="primary" disabled style={{ height: 200 }}>
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          Loading...
        </Btn>
      </div>
    :
<DataTable
                ref={dt}
                value={docPdf()}
                header={header}
                tableStyle={{ minWidth: '100%', fontSize : 12 }}
                globalFilter={globalFilter}
                emptyMessage="Pas de résultat."
                paginator
                rows={10}
                rowClassName={rowClassName} 
            >
                {cols.map((col, index) => (
                    <Column key={index} field={col.field} header={col.header} sortable={col.sortable}
                        body={
                          
                          col.field === 'actions' ? actionBodyTemplate : col.field === 'etat' ? etatComp : col.field === 'op' ? operateurComp : col.field === 'dt' ? dateComp : null
                          
                          
                      } 
                        
                        />
                ))}
            </DataTable>
}
      </>
            
           
      {/* ----------------Modal commentaire gestion obligations----------- */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Attribution de ressources</Modal.Title>
        </Modal.Header>
        <Modal.Body>
      <div className="container  row">
      <div className="mb-3">
                <Form.Label>Statut</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => setStatut_num(e.target.value)}
                >
                  {statutNum.map((item, index) => (
                    <option key={index} value={item.id_stat_num}>
                      {item.libelle}
                    </option>
                  ))}
                </Form.Select>
              </div>
      </div>
            <div className="container  row">
              
              <div className="col">
                <Form.Check
                  type="radio"
                  label="Opérateur"
                  value={0}
                  checked={form.type_occupant === 0}
                  onChange={() =>
                    setForm({ ...form, type_occupant: 0, id_operateur: "" })
                  }
                />
              </div>
              <div className="col">
                <Form.Check
                  type="radio"
                  label="Client"
                  value={1}
                  checked={form.type_occupant === 1}
                  onChange={() =>
                    setForm({ ...form, type_occupant: 1, id_operateur: "" })
                  }
                />
              </div>
              {form.type_occupant === 1 && (
                <div className="col">
                  <AddOutlineIcon
                    size={30}
                    style={{ cursor: "pointer", fontSize: "25px" }}
                    onClick={() => {
                      handleAjoutClient();
                    }}
                  />
                </div>
              )}
              <div style={{ width: "100%" }}>
                {form.type_occupant === 0 && (
                  <Select
                    options={operateur.map((item) => {
                      return {
                        label: item.nom_operateur,
                        value: item.id_operateur,
                      };
                    })}
                    isSearchable={true}
                    onChange={handleChangeAtt}
                  />
                )}

                {form.type_occupant === 1 && (
                  <Select
                    options={clients.map((item) => {
                      return {
                        label: item.nom_clt,
                        value: item.id_clt,
                      };
                    })}
                    isSearchable={true}
                    onChange={handleChangeAtt}
                  />
                )}
              </div>
            </div>
     
          <div className="row container">
             
             
        
            <div className="mt-3">
              <Form.Label>Date d'attribution</Form.Label>
              <Form.Control

// formatDate
                type="date"
                onChange={(e) =>
                  setForm({ ...form, date_attr: e.target.value })
                }
              />
            </div>
            <div className="mt-3">
              <Form.Label>No de décision</Form.Label>
              <Form.Control
                type="text"
                placeholder="numéro de décision"
                value={form.num_decision}
                onChange={(e) =>setForm({ ...form, num_decision: e.target.value })}
              />
            </div>
            <div className="mt-3">
              <Form.Label>Services implémentés</Form.Label>
              <Form.Control
                type="text"
                placeholder="service implémenté"
                value={form.services }
                onChange={(e) => setForm({ ...form, services: e.target.value })}
              />
            </div>
            <div className="mt-3">
              <Form.Label>Commentaire</Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                placeholder="commentaire..."
                onChange={(e) =>setForm({ ...form, commentaire: e.target.value })}
                value={form.commentaire}
              ></Form.Control>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Btn variant="primary" onClick={handleClose}>
            Annuler
          </Btn>
          <Btn variant="success" onClick={save}>
            Enregistrer
          </Btn>
        </Modal.Footer>
      </Modal>
      
      {/* ----------------Modal ajout client ----------- */}
      <Modal
        show={ajoutClient}
        onHide={handleAjoutClient}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Ajout Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AjoutClientModal handleAjoutClient={handleAjoutClient} />
        </Modal.Body>

      </Modal>

      {/* ----------------Modal infos occupant----------- */}
      <Modal show={showInfOcup} onHide={() => handleInfOcup()} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{"Détails"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <table class="table table-bordered border-primary">
              <tr>
                <td class="border col-6">Date d'attribution</td>
                <td class="border col-6">{infos?.date_attr === null ? "" : parseInt( infos?.date_attr) == 0 ? "" : formatDate(infos?.date_attr) }</td>
              </tr>
              <tr>
                <td class="border col-6">
                  Commentaire

                </td>
                <td class="border col-6">{infos?.commentaire}</td>
              </tr>
              <tr>
                <td class="border col-6">Numéro de décision </td>
                <td class="border col-6">{infos?.num_decision}</td>
              </tr>
              <tr>
                <td class="border col-6">Service implémenté</td>
                <td class="border col-6">{infos?.services}</td>
              </tr>
              <tr>
                <td class="border col-6">Opérateur</td>
                <td class="border col-6">{giveInfOp(infos, operateur, clients)}</td>
              </tr>

            </table>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleInfOcup}>
            Fermer
          </Button>

        </Modal.Footer>
      </Modal>
      {/* ----------------Modal infos occupant----------- */}
      <Modal show={show2} onHide={() => handleClose2()} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{"Détails Lots"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            


{ infosAttr.length >0 && infosAttr.map((item)=>(
  <table class="table table-bordered border-primary">
<tr>
<td class="border col-6"><span className='libel_info_suivi'>Lot</span></td>
<td class="border col-6">{item?.Lot?.lib_lot}</td>
</tr>

<tr>
<td class="border col-6"><span className='libel_info_suivi'>Date d'attribution</span></td>
<td class="border col-6">
  {
    
  // moment(item?.createdAt).format("DD/MM/YYYY")
  item?.date_attr == null ? "" : parseInt(item?.date_attr)  == 0 ? "": item?.date_attr == "" ? "" :formatDate(item?.date_attr)
  }
  </td>
</tr>

<tr>
<td class="border col-6"><span className='libel_info_suivi'>Stat utilisation</span></td>
<td class="border col-6">
  <div style={{display : "flex", flexDirection: 'column'}}>
  <span>{item?.nb_use}{" "}{" utilisé "}</span>
  <span className='mb-2'> {item?.nb_actif}{" "}{" actif "}</span>
 
         <Badge bg={"success"}
         onClick={()=>setNbreUse(item.id_attr)}
         style={{cursor:"pointer"}}
         >
                 Inserer
                      </Badge>
  </div>
</td>
</tr>

<tr>
<td class="border col-6"><span className='libel_info_suivi'>Début</span></td>
<td class="border col-6">{item?.Lot?.debut_lot}</td>
</tr>

<tr>
<td class="border col-6"><span className='libel_info_suivi'>Fin</span></td>
<td class="border col-6">{item.Lot?.fin_lot}</td>
</tr>

</table>
))
             
              }
             

          

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Fermer
          </Button>

        </Modal.Footer>
      </Modal>

       {/* ----------------Modal Attribution de PQ----------- */}
       <Modal
        show={setLot}
        onHide={handleSertLot}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Attribution de PQ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
      
            <div className="container  ">
            <Form.Label>Lots</Form.Label>
                  <Select
                    options={seeLot.map((item) => {
                      return {
                        label: item.debut_lot+" - "+item.fin_lot,
                        value: item.id_lot,
                      };
                    })}
                    isSearchable={true}
                    onChange={handleChangeLot}
                  />
           
              </div>
           
     
          <div className="container mt-5">
             
        
            
              <Form.Label>Date d'attribution</Form.Label>
              <Form.Control

                type="date"
                onChange={(e) =>
                  setNextLot({ ...nextLot, date_attr: e.target.value })
                }
              />
        
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Btn variant="primary" onClick={handleSertLot}>
            Annuler
          </Btn>
          <Btn variant="success" onClick={saveNextLot}>
            Enregistrer
          </Btn>
        </Modal.Footer>
      </Modal>
       {/* ----------------Modal use lot----------- */}
       <Modal
        show={showUse}
        onHide={handleUseLot}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Statistique d'Utilisation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
      
            <div className="container  ">
            <Form.Label>Nombre Utilisé(s)</Form.Label>
                  
            <Form.Control

                type=" number"
                onChange={(e) =>
                  setUseLot({ ...useLot, nb_use: e.target.value })
                }
              />
              </div>
            <div className="container  ">
            <Form.Label>Nombre Actif(s)</Form.Label>
                  
            <Form.Control

                type=" number"
                onChange={(e) =>
                  setUseLot({ ...useLot, nb_actif: e.target.value })
                }
              />
              </div>
           
        </Modal.Body>
        <Modal.Footer>
          <Btn variant="primary" onClick={handleUseLot}>
            Annuler
          </Btn>
          <Btn variant="success" onClick={saveUseLot}>
            Enregistrer
          </Btn>
        </Modal.Footer>
      </Modal>
          
        </div>
    )

    ;
}
