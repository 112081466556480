import React, { useState } from "react";
import { Button, Card, Form, Image } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import Logo from "../assets/img/logo.jpg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useLoginMutation } from "../redux/apiSlice/loginApiSlice";
import { createCookie } from "../helpers";
import { setUser } from "../redux/slice/userSlice";
import { SET_ID_USER } from "../redux/slice/infoUserSlice";

export default function Signin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const toaster = useToaster();
  const [login, { isLoading: loadLogin }] = useLoginMutation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [id_user, setIdUser] = useState("");

  const dispash = useDispatch();

  const connexion = async () => {
    try {
      const user = await login({
        email_user: email,
        password: password,
      }).unwrap();

      setIdUser(user.id_user);
      dispash(SET_ID_USER({ userId: user.id_user }));

      const userData = {
        name: user?.name,
        organisme: user?.organisme,
        profil: user?.profil,
        id: user?.id_user,
        token: user?.token,
        email,
      };
      dispatch(setUser(userData));
      localStorage.setItem("mon_user", JSON.stringify(userData));
      createCookie("userInfo", JSON.stringify(userData));
      navigate("/dashboard");
    } catch (error) {
      toast.error("Authentification échouée.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const disabled = email === "" || password === "";

  return (
    <div className="page-sign">
      <Card className="card-sign">
        <Card.Header>
          <Link to="/">
            <Image src={Logo} alt="" />
          </Link>

          <>
            <Card.Title>Connexion</Card.Title>
            <Card.Text>
              Bienvenue sur PIGRU. Veuillez vous authentifier
            </Card.Text>
          </>
        </Card.Header>
        <Card.Body>
          <Form>
            <div className="mb-4">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                id="email"
                placeholder="admin34@gmail.com"
                value={email}
                onChange={(e) => setEmail(e.target.value.trim())}
              />
            </div>
            <div className="mb-4">
              <Form.Label className="d-flex justify-content-between">
                Password{" "}
              </Form.Label>
              <Form.Control
                // type={visible ? "text" : "password"}
                type="password"
                id="password"
                placeholder="**********"
                value={password}
                onChange={(e) => setPassword(e.target.value.trim())}
              />
            </div>
            <div className="container row mb-3">
              <div
                className="col text-right"
                onClick={() => navigate("/send_email_recuperation")}
                style={{ textDecoration: "underline", cursor: "pointer" }}
              >
                Mot de passe oublié ?
              </div>
            </div>
          </Form>

          <Button
            variant="primary"
            className="btn-sign"
            disabled={disabled || loadLogin}
            onClick={() => connexion()}
            loading={loadLogin}
          >
            {loadLogin ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <span>Se connecter</span>
            )}
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
}
