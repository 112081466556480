import React, { Fragment, useEffect, useState } from 'react'

import { Button as Btn, Form } from "react-bootstrap";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import Swal from "sweetalert2";
import Footer from '../../layouts/Footer'
import Header from '../../layouts/Header'
import Sidebar from '../../layouts/Sidebar'

import { 
    useCreateAttribuerServiceTrancheMutation, 
    useDeleteAttribuerServiceTrancheMutation, 
    useGetAttribuerServiceTrancheQuery,
    useGetServiceTrancheQuery,
    useGetSousTrancheQuery,
    useGetTrancheQuery, 
    useUpdateAttribuerServiceTrancheMutation 
} from '../../redux/apiSlice/numerotationApiSlice';

const AttributionTranche = () => {
    const {data, isLoading} = useGetAttribuerServiceTrancheQuery()
    const {data: tranches=[], isLoading: loadTranches} = useGetTrancheQuery()
    const {data: sousTranches=[], isLoading: loadSousTranches} = useGetSousTrancheQuery()
    // console.log("sousTranches", sousTranches)
    const {data: services=[], isLoading: loadServices} = useGetServiceTrancheQuery()
    const [createAttribuerServiceTranche] = useCreateAttribuerServiceTrancheMutation()
    const [updateAttribuerServiceTranche] = useUpdateAttribuerServiceTrancheMutation()
    const [deleteAttribuerServiceTranche] = useDeleteAttribuerServiceTrancheMutation()

    const fileName = 'attribuer-service-tranches'
    const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : ''
    const cols = [
        { field: 'num', header: 'N°', sortable: true },
        { field: 'service', header: 'Service', sortable: true },
        { field: 'tranche', header: 'Tranche', sortable: true },
        { field: 'sousTranche', header: 'Sous tranche', sortable: true },
        { field: 'nb_digit', header: 'Nb. Digit', sortable: true },
        { field: 'actions', header: 'Actions', sortable: false }
    ];
    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));
    const initForm = {id_principal: '', id_service: '', type_tranche: 0, nb_digit: ''}

    const [skin, setSkin] = useState(currentSkin)
    const [search, setSearch] = useState('')
    const [form, setForm] = useState({...initForm})
    const [choixTranche, setChoixTranche] = useState(null)
    const [choixSTranche, setChoixSTranche] = useState(null)
    const [stateSousTranche, setStateSousTranche] = useState([])
    // console.log("etatChoix", etatChoix)
    // console.log("stateSousTranche", stateSousTranche)
    // console.log("choixSTranche", choixSTranche)

    const switchSkin = (skin) => {
        if (skin === 'dark') {
            const btnWhite = document.getElementsByClassName('btn-white');

            for (const btn of btnWhite) {
                btn.classList.add('btn-outline-primary');
                btn.classList.remove('btn-white');
            }
        } else {
            const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

            for (const btn of btnOutlinePrimary) {
                btn.classList.remove('btn-outline-primary');
                btn.classList.add('btn-white');
            }
        }
    }

 const controle = (lib, value)=>{

    if (lib == "tranche") {
        const rsul = tranches.find((item)=>(item.id_tranche == value))
if (rsul) {
    setChoixTranche(rsul?.lib_tranche)
}
    } else {
       
    const obj = stateSousTranche.find((item)=>(item.id_s_tran ==  value))
        
if (obj) {
    setChoixSTranche(obj?.lib_s_tran)
}
    }


 }



    const disabled = form.id_tranche === '' || form.id_service === '' || form.nb_digit === ''

    const reset = () => setForm({...initForm})

    const foncSave = async (form)=>{
        if (form?.id_attr) {
            await updateAttribuerServiceTranche(form).unwrap()
        } else {
            await createAttribuerServiceTranche(form).unwrap()
        }

        Swal.fire({
            title: "Félicitations!",
            text: `Attribution de tranche ${form?.id_attr ? 'modifiée': 'crée'}`,
            icon: "success"
        });
    }

    const save = async () => {
        Swal.fire({
            title: "Gestion des tranches",
            text: "Voulez-vous continuer ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Oui",
            cancelButtonText: "Non"
        }).then(async (result) => {
            if (result.isConfirmed === true) {

                // console.log("parseInt(form.id_tranche)", parseInt(form.id_tranche))
                // console.log("form.nb_digit", typeof form.nb_digit)
                // console.log("form.nb_digit",  form.nb_digit)

     if (parseInt(choixTranche) === 1 ||
      parseInt(choixTranche) === 7 
    //    parseInt(choixTranche) === 3 
       ) {

        if (form.nb_digit == "3" || form.nb_digit == "4" || form.nb_digit == "3,4") {

            if((parseInt(choixSTranche) === 11 ||
             parseInt(choixSTranche) === 13 ||
              parseInt(choixSTranche) === 16) && 
              form.nb_digit != "3"){
                Swal.fire({
                    title: "ERREUR!",
                    text: `Les sous tranche 11, 13 et 16  sont sur 3 digits`,
                    icon: "error"
                });
                
            }   else{
                foncSave(form)
            }
                 
        
        } else {
            Swal.fire({
                title: "ERREUR!",
                text: `Vérifier le nombre de digits qui doit être : 3 ou 4 ou 3,4 pour les tranches 1 et 7`,
                icon: "error"
            });
        }

    
     } else {
     
        if ( parseInt(choixTranche) === 8) {

            if (form.nb_digit === "8" ) {
                foncSave(form)
            } else {
                Swal.fire({
                    title: "ERREUR!",
                    text: `Mettez 8 digits pour attribuer la tranches : 8`,
                    icon: "error"
                });
            }
        } else {
            if (form.nb_digit === "2" ) {
                foncSave(form)
            } else {
                Swal.fire({
                    title: "ERREUR!",
                    text: `Mettez 2 digits pour attribuer les tranches : 3, 4, 5, 6, 9`,
                    icon: "error"
                });
            }
        }

     }
     
                    
            }
        });
        reset()
    }

    const deleteData = value => {
        Swal.fire({
            title: "Gestion des tranches",
            text: "Voulez-vous vraiment supprimer cette attribution ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Oui",
            cancelButtonText: "Non"
        }).then(async (result) => {
            if (result.isConfirmed === true) {
               try {
        
                await deleteAttribuerServiceTranche(value.id_attr).unwrap()
                Swal.fire({
                    title: "Félicitations!",
                    text: `Attribution de tranche supprimée`,
                    icon: "success"
                });
                reset()
               } catch (error) {
                console.log("error", error)
   
                Swal.fire({
                    title: "ERREUR!",
                    text: `Une erreur s'est produite , veuillez réessayer`,
                    icon: "error"
                });
               }
            }
        });
    }

    const exportCSV = (selectionOnly) => {
        // dt.current.exportCSV({ selectionOnly });
    };

    const exportPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);

                doc.autoTable(exportColumns, data);
                doc.save(fileName+'.pdf');
            });
        });
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(data);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, fileName);
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const rowClassName = (rowData, index) => {
        return index !== data.length - 1 ? 'table-row' : ''; // Applique la classe 'table-row' à toutes les lignes sauf la dernière
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center">
                <Btn onClick={() => setForm(rowData)} className="badge bg-success p-2 mr-1" style={{ borderRadius: 30 }}><i className="ri-pencil-line  ri-2x"></i><span></span></Btn>

                <Btn onClick={() => deleteData(rowData)} className="badge bg-danger p-2" style={{ borderRadius: 30 }}><i className="ri-delete-bin-6-line  ri-2x"></i><span></span></Btn>
            </div>
        );
    };

    const getLibData = (value, field) => {

        // console.log("field", value)

        let libelle = ''
        if (field === 'service') {
            libelle = services.find(item => item.id_service === value.id_service)?.lib_service
        } else if (field === 'tranche') {
          
                switch (value?.type_tranche) {
                    case 0 :
                      const objt = tranches.find((item)=>(item.id_tranche == value.id_principal
                        ))

                        libelle = objt?.lib_tranche
                        break;
                
                    default:
                        const objt2 = sousTranches.find((item)=>(item.id_s_tran == value.id_principal
                            ))

                        const objt3 = tranches.find((item)=>(item.id_tranche == objt2?.id_tranche
                            ))
    
                            libelle = objt3?.lib_tranche
                        break;
                }

            // libelle = list.find(item => item[id] === value.id_principal)[lib]
        } else{
            switch (value?.type_tranche) {
                case 0 :
                  
                    libelle = "-"
                    break;
            
                default:
                    const objt2 = sousTranches.find((item)=>(item.id_s_tran == value.id_principal
                        ))

                   

                        libelle = objt2?.lib_s_tran
                    break;
            }

        }
        return libelle
    }

    const Head = () => (
        <div className="container_header_data_table">
            <div className='container_export'>
                <Button type="button" icon="pi pi-file-pdf" rounded onClick={exportPdf} className="" data-pr-tooltip="PDF" style={{ borderRadius: 30 }} severity="secondary" size="small" />
                <Button type="button" icon="pi pi-file-excel" className="" severity="success" rounded onClick={exportExcel} data-pr-tooltip="Excel" style={{ borderRadius: 30 }} size="small" />
                <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} className="" data-pr-tooltip="CSV" style={{ borderRadius: 30 }} size="small" />
            </div>
        </div>
    );
    switchSkin(skin);

    const changeChoix = (lib, value)=>{

        if(lib == "tranche"){

            controle(lib, value)

            if(form.type_tranche == 0){
                setForm({...form, id_principal: value});
            }else{
                const objetFilter = sousTranches.filter((item)=>(
                    item.id_tranche == parseInt(value)
                )) 
                setStateSousTranche(objetFilter)
            }
          
        }else{
            controle(lib, value)
            setForm({...form, id_principal: value})
        }
        
      
        
    }

    useEffect(() => {
        switchSkin(skin);
        // setStateSousTranche(sousTranches)
    }, [skin, sousTranches]);
    return (
        <Fragment>
            <Sidebar />
            <Header onSkin={setSkin} />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <h4 className="main-title mb-0"> Gestion Tranches / sous-tranches / services</h4>
                    </div>
                </div>

                <div className="container_liste_form">
                    <div className="first_container">
                        <span className="titre_cat">Liste des Tranches / sous-tranches / services
</span>
                        <hr style={{ width: '100%' }} />

                        <div className="card" style={{ width: '100%' }}>
                            <Tooltip target=".export-buttons>button" position="bottom" />
                            <style>{`.table-row td {border-bottom: 1px solid #dee2e6;}`}</style>

                            <DataTable
                                value={data}
                                header={<Head />}
                                tableStyle={{ minWidth: '100%', fontSize: 12 }}
                                globalFilter={search}
                                //globalFilterFields={['lib_s_tran']}
                                emptyMessage="Pas de résultat."
                                paginator
                                rows={10}
                                rowClassName={rowClassName}
                                loading={isLoading || loadTranches || loadSousTranches || loadServices}
                            >
                                {cols.map((col, index) => (
                                    <Column 
                                        key={index} 
                                        field={col.field} 
                                        header={col.header} 
                                        sortable={col.sortable} 
                                        body={col.field === 'actions' ? actionBodyTemplate : (col.field === 'num' ? (data, options) => options.rowIndex + 1 : (['service', 'tranche','sousTranche'].includes(col.field) ? (data, options) => getLibData(data, col.field) : null))}
                                    />
                                ))}
                            </DataTable>

                        </div>

                    </div>

                    <div className="second_container">
                        <span className="titre_cat">Affectation tranches / Sous tranches / Services
</span>
                        <hr style={{ width: '100%' }} />
                        <div className="container_form">
                            <div className="container_border">
                                <div className="sous_container_form mb-1">
                                    <Form.Check
                                        type="radio"
                                        label="Tranche"
                                        value={0}
                                        checked={form.type_tranche === 0}
                                        onChange={() => setForm({...form, type_tranche: 0, id_principal: ''})}
                                    />

                                    <Form.Check
                                        type="radio"
                                        label="Sous - Tranche"
                                        value={1}
                                        checked={form.type_tranche === 1}
                                        onChange={() => setForm({...form, type_tranche: 1, id_principal: ''})}
                                    />
                                </div>

                                <div className="sous_container_form mb-3">
                                    <div style={{ width: '100%' }}>
                                       {/* {form.type_tranche === 0 && ( <Form.Select
                                            value={form.id_principal}
                                            onChange={e => {
                                                const val = e.target.value;
                                                setForm({...form, id_principal: val});
                                                controle(val)
                                            }}
                                        >
                                            <option>Choisir</option>
                                            {tranches.map((tranche, i) => (<option key={i} value={tranche.id_tranche}>{tranche.lib_tranche}</option>))}
                                           
                                        </Form.Select>)} */}

                                       <Form.Select
                                            
                                            onChange={e => changeChoix("tranche", e.target.value)}
                                        >
                                              <option>Choisir</option>
                                            {tranches.map((tranche, i) => (<option key={i} value={tranche.id_tranche}>{tranche.lib_tranche}</option>))}
                                           
                                        </Form.Select>
                                    </div>
                                  
                                </div>

                               {form.type_tranche === 1 && ( <div className="sous_container_form mb-3">
                                    <div className="container_label">
                                        <Form.Label>Sous tranche</Form.Label>
                                    </div>
                                    <div style={{ width: '70%' }}>
                                    <Form.Select
                                            value={form.id_principal}
                                            // onChange={e => setForm({...form, id_principal: e.target.value})}
                                            onChange={e => changeChoix("sousTranche", e.target.value)}
                                        >
                                               <option>Choisir</option>
                                            { stateSousTranche.length >0 && stateSousTranche.map((sous, i) => 
                                            
                                            (
                                                
                                            <option key={i} value={sous.id_s_tran}>{sous.lib_s_tran}</option>
                                        
                                        
                                            )
                                            
                                            )




                                            }
                                        </Form.Select>
                                    </div>
                                </div>)}
                                <div className="sous_container_form mb-3">
                                    <div className="container_label">
                                        <Form.Label>Service</Form.Label>
                                    </div>
                                    <div style={{ width: '70%' }}>
                                        <Form.Select 
                                            value={form.id_service}
                                            onChange={e => setForm({...form, id_service: e.target.value})}
                                        >
                                              <option>Choisir</option>
                                            {services.map((service, i) => (<option key={i} value={service.id_service}>{service.lib_service}</option>))}
                                        </Form.Select>
                                    </div>
                                </div>

                                {!form?.id_s_tran &&
                                    <div className="sous_container_form mb-3">
                                        <div className="container_label">
                                            <Form.Label>Nb. digits numérique</Form.Label>
                                        </div>
                                        <div style={{ width: '70%' }}>
                                            <Form.Control
                                                type="text"
                                                id="lib"
                                                value={form.nb_digit}
                                                onChange={e => setForm({...form, nb_digit: e.target.value})}
                                                max={"4"}
                                                min={"2"}
                                            />
                                        </div>
                                    </div>
                                }

                                <div className="container_btn">
                                    <Btn variant="primary" onClick={() => reset()}>Annuler</Btn>
                                    <Btn variant="danger" disabled={disabled} onClick={() => save()}>Enregistrer</Btn>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

                <Footer />
            </div>
        </Fragment>
    )
}

export default AttributionTranche
